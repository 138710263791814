import * as Yup from "yup";

export const basicDetailSchema = Yup.object().shape({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    dob: Yup.string().required("DOB is required"),
    gender: Yup.string().required("Gender is required"),
    marital_status: Yup.string().required("Martial Status is required"),
    address_1: Yup.string().required("Address is required"),
    post_code: Yup.number().typeError('Pin code should be a number').max(999999,'Pin code must be 6 number').required("Pin code is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
  
});
