import React from "react";
import { SecondaryLayout } from "../../Components";
import { Button, Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RESUME_1 from '../../Images/resume/resume-1.png';
import RESUME_2 from '../../Images/resume/resume-2.png';
import RESUME_3 from '../../Images/resume/resume-3.png';
import RESUME_4 from '../../Images/resume/resume-4.png';
import RESUME_5 from '../../Images/resume/resume-5.png';
import RESUME_6 from '../../Images/resume/resume-6.png';
import RESUME_7 from '../../Images/resume/resume-7.png';
import RESUME_8 from '../../Images/resume/resume-8.png';
import RESUME_9 from '../../Images/resume/resume-9.png';
import RESUME_10 from '../../Images/resume/resume-10.png';
import RESUME_11 from '../../Images/resume/resume-11.png';
// import RESUME_12 from '../../Images/resume/resume-4.png';
// import RESUME_13 from '../../Images/resume/resume-4.png';
// import RESUME_14 from '../../Images/resume/resume-4.png';
// import RESUME_15 from '../../Images/resume/resume-4.png';
// import RESUME_16 from '../../Images/resume/resume-4.png';
import { RESUME_PREVIEW } from "../../RouteConstant";

const Resume = () => {
  const navigate = useNavigate();
  const resumes = [RESUME_1, RESUME_2, RESUME_3, RESUME_4,RESUME_5,RESUME_6,RESUME_7,RESUME_8,RESUME_9,RESUME_10,RESUME_11,
    // RESUME_12,RESUME_13,RESUME_14,RESUME_15,RESUME_16
  ];
  return (
    <SecondaryLayout currentActive={9}>
      <Text variant="h3">
        Select a resume template to format your resume
      </Text>

      <Box
        mt={2}
        display='flex'
        flexWrap='wrap'
        gap={1.5}
      >
      {Array(11).fill(undefined).map((el, index) => (
          <Card
            px={0}
            py={0}
            sx={{
              position: 'relative',
              // height: 395,
              overflow: 'hidden',
              width: {
                xs: '100%',
                md: 'calc((100% - 24px) / 3)',
                lg: 'calc((100% - 36px) / 4)',
              },

              '&:not(:hover)': {
                '& > div': {
                  opacity: 0
                }
              },

              '& img': {
                display: 'block',
                maxWidth: '100%'
              }
            }}
            disableHeader
          >
            <img src={resumes[index]} alt="" />

            <Box
              p={2}
              display='flex'
              alignItems='flex-end'
              sx={{
                background: 'linear-gradient(180deg, rgba(253, 253, 253, 0) 8.4%, #ffffff 79.34%)'
              }}
              height={1}
              width={1}
              position='absolute'
              top={0}
            >
              <Button onClick={() => navigate(RESUME_PREVIEW.replace(':previewId', `${index+1}`))} fullWidth>
                Preview &amp; Download
              </Button>
            </Box>
          </Card>
        ))}
      </Box>
    </SecondaryLayout>
  )
}

export default Resume;