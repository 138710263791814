import { Box } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Text } from "@lipihipi/ui-components";
import employer from "../../Images/employer.svg";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useEffect, useState } from "react";
import { fetchEvents } from "../../Services/Service";
import moment from "moment";
import { ASSET_URL } from "../../constant";
import NoDataFound from "../../Shared/NoData";


const Events = () => {
  const [events, setEvents] = useState<any>([]);

  useEffect(() => {
    fetchEvents((response: any) => {
      setEvents(response.data);
    })
  }, []);


  return (
    <SecondaryLayout currentActive={4}>
      {events?.length === 0 ? <NoDataFound /> : (
        <Box>
          <Text variant="h3">My Events ({events?.length})</Text>
          <Box
            mt={2}
            display='flex'
            flexDirection='column'
            rowGap={1.5}
          >
            {events?.map((event: any) => {
              return (
                <Card px={2} py={2} disableHeader>
                  <Box display='flex'>
                    <Box
                      pr={1}
                      flexGrow={1}
                      display='flex'
                      flexDirection='column'
                      rowGap={2.5}
                    >
                      <Box>
                        <Text variant="subtitle2">
                          {event?.job?.job_title}
                        </Text>
                        <Text sx={{ mt: 1 }} variant="subtitle2">
                          {`Date & Time: ${event?.interview_date} at ${event?.interview_time}`}
                        </Text>
                      </Box>

                      <Box
                        display='flex'
                        columnGap={2}
                      >
                        <Text sx={{ display: 'flex', alignItems: 'end', columnGap: 1 }}>
                          <CorporateFareIcon />
                          {event?.company_id?.company_name}
                        </Text>
                        {/* <Text variant="body2">
                        <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                      </Text>
                      <Text sx={{ display: 'flex', columnGap: 0.5 }}>
                        4.5
                        <RatingIcon color="#FFAA44" />
                      </Text> */}
                      </Box>
                    </Box>

                    <Box
                      width={60}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      height={60}
                      sx={{
                        background: '#FFFFFF',
                        border: '1px solid #F0F0F0',
                        '& img': {
                          maxWidth: '100%',
                          objectFit: 'contain',
                          maxHeight: '100%',
                        }
                      }}
                    >
                      <img src={`${ASSET_URL}/${event?.company_id?.company_logo}` ?? employer} alt="" />
                    </Box>
                  </Box>

                  <Box mt={2.5}>
                    <Text sx={{ color: '#868D90' }} variant="caption">
                      Posted {moment(event?.created_at, "YYYYMMDD").fromNow()}
                    </Text>
                  </Box>
                </Card>
              )
            })}
          </Box>
        </Box>
      )}

    </SecondaryLayout>
  )
}

export default Events;