import { Button, Card, Text } from "@lipihipi/ui-components";
import { Box, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../App";

const ReferToFriends = () => {
  const [copyText, setCopyText] = useState(false);
  const { state } = useContext<any>(AppContext);

  if (!state?.user?.short_url) return null;
  return (
    <Card disableHeader sx={{ textAlign: 'center' }}>
      <Text variant="h3" sx={{ mb: 0.5 }}>Refer to friends</Text>
      <Text variant="h4" sx={{ mb: 1.5 }}>
        Share it on your social media handles
      </Text>

      <Tooltip title={copyText ? "Copied" : "Copy Referral Link"} placement="top" arrow>
        <Box>
          <Button onMouseLeave={() => setTimeout(() => setCopyText(false), 200)} variant="outlined" color="secondary" onClick={() => {
            navigator?.clipboard?.writeText(state?.user?.short_url);
            setCopyText(true);
          }}>
            Copy Referral Link
          </Button>
        </Box>
      </Tooltip>
    </Card>
  )
};

export default ReferToFriends;