import React, { useState } from "react";
import GRAPHIC from "../../Images/identity.svg";
import { AuthLayout } from "../../Components";
import { Button, Form, InputBox, Text } from "@lipihipi/ui-components";
import {  forgotPassword } from "../../Services/Service";
import { ForgotPasswordSchema } from "./validationSchema";
import handleToast, { success } from "../../util/Toast";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Your Identity is,",
  secondaryText: "important for your Identity!",
};

const ForgotPassword = () => {
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
  });

  return (
    <AuthLayout currentStep={2} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please enter the email address. A password reset link will be sent to
        you
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values: any) => {
          return new Promise((res: any) => {
            forgotPassword(
              values,
              (response: any) => {
                handleToast(response?.message, success);
                res("");
              },
              (error: any) => {
                handleToast(error.data.message);
                res("");
              }
            );
          });
        }}
        render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
          return (
            <>
              <InputBox
                fullWidth
                required
                sx={{ mb: 3 }}
                onChange={handleChange}
                placeholder="Entere here..."
                label="Email Id"
                name="email"
              />

              <Button
                disabled={!isValid || isSubmitting}
                onClick={isValid ? submitForm : () => {}}
                shape="primary"
                className="ml-3"
                size="large"
                fullWidth
              >
                Submit
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
