import { useEffect, useState } from "react";
import { SecondaryLayout } from "../../Components";
import {
  Button,
  Card,
  CustomTable,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import { Box, Grid, InputAdornment, Tooltip } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import REFER from "../../Images/refer.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { fetchReferralInfo, referFriend } from "../../Services/Service";
import handleToast, { success } from "../../util/Toast";
import NoDataFound from "../../Shared/NoData";

const TableData = {
  heading: [
    { id: 0, label: "S.No." },
    { id: 1, label: "Name" },
    { id: 2, label: "Email" },
    { id: 3, label: "Contact" },
    { id: 4, label: "Referred" },
    { id: 5, label: "Profile" },
  ],
};

const Refer = () => {
  const [sortValue, setSortValue] = useState('');
  const [myInfo, setMyInfo] = useState<any>({});
  const [copyText, setCopyText] = useState(false);
  const [referralInfo, setReferralInfo] = useState<any>([]);
  const [refer, setRefer] = useState<any>([]);
  const [applied, setApplied] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);

  const fetchData = (query: string) => {
    fetchReferralInfo(query, (response: any) => {
      const updatedData = response?.data?.map((item: any, index: any) => {
        return {
          id: index + 1,
          name: item.name ?? "--",
          email: item.email ?? "--",
          contact: item.contact_no ?? "--",
          referred: item.referred_user_count ?? 0,
          profile: item.profile_strength ? `${item.profile_strength}%` : "0%",
        };
      });
      setReferralInfo(updatedData);
      setRefer(updatedData);
      setMyInfo(response.short_url);
    });
  };

  useEffect(() => {
    // if (sortValue) {
      fetchData(`?sortFilter=${sortValue}`);
    // }
  }, [sortValue]);

  return (
    <SecondaryLayout currentActive={3}>
      <Box mb={4}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="h3">Referred to Friends</Text>
        </Box>

        <Card disableHeader>
          <Grid columnSpacing={2} container>
            <Grid textAlign="center" alignSelf="center" item md={5}>
              <img src={REFER} alt="" />
            </Grid>
            <Grid item md={7}>
              <Text variant="subtitle2">
                Help your friend to get a great job and earn lot more with our
                affiliate program
              </Text>

              <Form
                initialValues={{ name: "", email: "" }}
                onSubmit={(values: any, { resetForm }: any) => {
                  referFriend(
                    { name: values.name, email: values.email },
                    (response: any) => {
                      if (response.status) {
                        handleToast(response.message, success);
                        resetForm({ name: "", email: "" });
                      }
                    }
                  );
                }}
                render={({ values, handleChange }: any) => {
                  return (
                    <>
                      <InputBox
                        name="name"
                        fullWidth
                        required
                        value={values.name}
                        sx={{ mt: 3 }}
                        label="Name"
                        placeholder="Enter here..."
                        onChange={handleChange}
                      />

                      <InputBox
                        name="email"
                        fullWidth
                        label="Email ID"
                        required
                        value={values.email}
                        sx={{ my: 3 }}
                        placeholder="Enter here..."
                        onChange={handleChange}
                      />

                      <Button type="submit" sx={{ px: 8 }} size="large">
                        Send Link
                      </Button>
                    </>
                  );
                }}
              />

              {myInfo && (
                <Box mt={3} display="flex" alignItems="center" columnGap={2}>
                  <Text variant="subtitle1">
                    Share it on your social media handles:
                  </Text>
                  <Tooltip
                    title={copyText ? "Copied" : "Copy Referral Link"}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <Button
                        onMouseLeave={() =>
                          setTimeout(() => setCopyText(false), 200)
                        }
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          navigator.clipboard.writeText(myInfo);
                          setCopyText(true);
                        }}
                      >
                        <ContentCopyOutlinedIcon sx={{ fontSize: 20, mr: 1 }} />
                        Copy URL
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>

      {refer?.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="h3">Referred Friends ({referralInfo?.length})</Text>
          </Box>

          <Card sx={{ px: 0 }} disableHeader>
            <Box px={2} mb={3}>
              <Box display="flex" alignItems="flex-end" columnGap={4}>
                <Form
                  style={{ flexGrow: 1 }}
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values: any) => {
                    const temp = [...refer];
                    const filteredItems = temp?.filter(
                      (item: any) =>
                        item.name.toLowerCase().includes(values.searchValue) ||
                        item.email.toLowerCase().includes(values.searchValue) ||
                        item.contact.toLowerCase().includes(values.searchValue)
                    );
                    setReferralInfo(filteredItems);
                    setApplied(true);
                  }}
                  render={({ values, handleChange, resetForm }: any) => {
                    return (
                      <Box
                        flex={1}
                        flexShrink={0}
                        display="flex"
                        alignItems="flex-end"
                      >
                        <InputBox
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:after": {
                                borderColor: "#00C385",
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          name="searchValue"
                          value={values.searchValue}
                          fullWidth
                          placeholder="Search from the list below from Email id/Mobile no or Name"
                          onChange={handleChange}
                          label="Email id/Mobile no or Name"
                        />

                        <Button
                          type="submit"
                          sx={{ ml: 3 }}
                          variant="outlined"
                          color="secondary"
                        >
                          Go
                        </Button>

                        {applied && (
                          <Button
                            onClick={() => {
                              resetForm({ searchValue: "" });
                              setApplied(false);
                              setReferralInfo(refer);
                            }}
                            variant="text"
                            sx={{ color: "#156CD2" }}
                          >
                            Clear
                          </Button>
                        )}
                      </Box>
                    );
                  }}
                />

                <Form
                  initialValues={{ sort: "" }}
                  style={{ minWidth: 250 }}
                  render={({ values, handleChange }: any) => {
                    return (
                      <SelectBox
                        fullWidth
                        label="Sort By"
                        items={[
                          { value: "latest-on-top", text: "Latest on top" },
                          { value: "oldest-on-top", text: "Oldest on top" },
                          { value: "most-reffered", text: "Most Reffered" },
                          { value: "lowest-reffered", text: "Lowest Reffered" },
                          {
                            value: "profile-completeness-high",
                            text: "Profile Completeness High",
                          },
                          {
                            value: "profile-completeness-low",
                            text: "Profile Completeness Low",
                          },
                        ]}
                        name="sort"
                        value={values.sort}
                        onChange={(e) => {
                          handleChange(e);
                          setSortValue(e);
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
            <CustomTable data={referralInfo} heading={TableData.heading} />
          </Card>
        </Box>
      )}
    </SecondaryLayout>
  );
};

export default Refer;
