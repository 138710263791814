import { useContext, useEffect, useState } from "react";
import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/self-intro.svg";
import { Button, Form, InputBox, Text } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { saveAboutMe } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { PROFILE } from "../../../RouteConstant";
import { SelfIntroductionSchema } from "./validationSchema";
import { AppContext } from "../../../App";

const onboardingData = {
  icon: GRAPHIC,
};

const EditSelfIntroduction = () => {
  const navigate = useNavigate();
  const [bio, setBio] = useState('');
  const { state } = useContext<any>(AppContext);
  useEffect(() => {
    setBio(state.user.about_me)
  }, []);

  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Describe about yourelf
      </Text>

      <Form
        initialValues={{ self_intro: bio ? bio : '' }}
        validateOnMount={true}
        validationSchema={SelfIntroductionSchema}
        onSubmit={(values: any) => {
          saveAboutMe({ aboutMe: values.self_intro }, (response: any) => {
            if (response?.success) {
              handleToast(response?.message, success)
              navigate(PROFILE)
            }
          })
        }}

        render={({
          values,
          isValid,
          handleChange,
          isSubmitting
        }: any) => {
          return (
            <>
              <InputBox
                fullWidth
                name="self_intro"
                inputProps={{ maxLength: 1000 }}
                value={values.self_intro}
                sx={{ mb: 3 }}
                multiline
                helperText={`${values.self_intro.length}/1000 Characters`}
                maxRows={10}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Enter details here"
              />
              <Button
                type="submit"
                disabled={!isValid||isSubmitting}
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          )
        }}
      />
    </AuthLayout>
  )
};

export default EditSelfIntroduction;