import React, { useState } from "react";
import GRAPHIC from "../../Images/identity.svg";
import { AuthLayout } from "../../Components";
import {
  Button,
  Form,
  InputBox,
  Text,
} from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { addPhone } from "../../Services/Service";
import { MOBILE_OTP } from "../../RouteConstant";
import { mobileValidationSchema } from "./validationSchema";
import { InputAdornment } from "@mui/material";
import handleToast from "../../util/Toast";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Your Identity is,",
  secondaryText: "important for your Identity!",
};


const AddPhone = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    contact_no: "",
  });
  const [isSubmitting,setIsSubmitting] = useState(false);
 

  const onSucess = (res:any) => {
    navigate(MOBILE_OTP);
  };
  const onAddFailure = (err:any)=>{
    handleToast(err.data.errors.contact_no[0])
    setIsSubmitting(false)

  }
  return (
    <AuthLayout currentStep={2} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please Provide your Contact no
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={mobileValidationSchema}
        onSubmit={(values: any) => {
            localStorage.setItem('mobile',values.contact_no)
          addPhone(onSucess,onAddFailure,values)
        }}
        render={({ handleChange,isValid }: any) => {
          return (
            <>
              <InputBox
                fullWidth
                required
                sx={{ mb: 3 }}
                onChange={handleChange}
                placeholder="Entere here..."
                label="Contact No"
                name="contact_no"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Text>+91 - </Text>
                    </InputAdornment>
                  ),
                }}
              />
              
              <Button type="submit" sx={{ mt: 5 }} fullWidth size="large" disabled={!isValid || isSubmitting}>
                Next
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default AddPhone;
