import * as Yup from "yup";

export const EmploymentSchema = Yup.object().shape({
    company_name: Yup.string()
    .required("Company Name is required")
    .min(10, "Title must have at least 10 characters"),
    location: Yup.mixed().required("Location is required"),
    designation: Yup.string()
    .required("Designation is required"),
    job_type: Yup.mixed().required("Job type is required"),
    start_month: Yup.string().required("Start Month is required"),
    start_year: Yup.number().required("Start is required"),
    // currently_working: Yup.boolean(),
    // end_month: Yup.string().when("currently_working", { is: false, then:()=> Yup.string().required('End Month is required') }),
    end_year: Yup.number().min(Yup.ref('start_year'), 'To year must be greater than from year'),
      
});

