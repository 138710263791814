import {
  Button,
  PrimaryList,
  Card,
  DefaultInfoCard,
  Text,
  ConfirmationModal,
  useSmartPhone,
} from "@lipihipi/ui-components";
import { EducationIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import subtract from "../../../Images/subtract.svg";
import { EDIT_EDUCATION, EDUCATION } from "../../../RouteConstant";
import { deleteEducation } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { useRef, useState } from "react";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const EducationDetails = ({ educations, setToggle }: any) => {
  const navigate = useNavigate();
  const selected = useRef<Record<string, any> | null>(null);
  const isSmartPhone = useSmartPhone();
  const [open, setOpen] = useState(false);
  if (educations?.length === 0) {
    return (
      <DefaultInfoCard icon={<EducationIcon />}>
        <Text>Its seems that you are here for the first time?</Text>
        <Text
          sx={{
            color: "#292929",
          }}
        >
          Why dont you add your{" "}
          <Text sx={{ fontSize: "20px", fontWeight: "400" }} component="span">
            Education Details.
          </Text>
        </Text>
        <Button
          onClick={() => navigate("/education")}
          color="primary"
          sx={{ mt: 3, mx: "auto" }}
          variant="outlined"
        >
          Add Now
        </Button>
      </DefaultInfoCard>
    );
  }
  const handleDelete = () => {
    deleteEducation(selected?.current?.id, (response: any) => {
      handleToast(response.message, success);
      setTimeout(() => setToggle((prev: any) => !prev));
    });
  };
  return (
    <Card
      action={{
        icon: null,
        text: isSmartPhone ? 'Add' : "Add Education",
      }}
      onClick={() => navigate(EDUCATION)}
      heading="Education Details"
    >
      {educations?.map((education: any) => {
        return (
          <>
            <PrimaryList
            isSecondary={isSmartPhone ? true : false}
              icon={<img src={subtract} alt="" />}
              onClickEdit={() =>
                navigate(EDIT_EDUCATION.replace(":id", education?.id))
              }
              onClickDelete={() => {
                selected.current = education;
                setOpen(true);
              }}
              chip={{
                color: "warning",
                label: "Not Verified",
              }}
              data={{
                heading: education?.education_type
                  ? education?.education_type.value
                  : education?.graduation_type?.value,
                primaryText: education?.university,
                secondaryText: `${education?.passing_from_year} ${
                  education?.passing_to_year
                    ? `to ${education?.passing_to_year}`
                    : ""
                }`,
              }}
            />
          </>
        );
      })}
      <ConfirmationModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={handleDelete}
        title={"Are you sure want to delete education?"}
      />
    </Card>
  );
};

export default EducationDetails;
