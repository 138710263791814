import React, { useContext, useEffect, useState } from "react";
import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/personal-details.svg";
import {
  Button,
  CheckBox,
  CustomRadio,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import { Box, InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import { Datepicker } from "../../../Shared/Datepicker";
import { useNavigate } from "react-router-dom";
import { getUserMe, savePersonalDetails } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { AppContext } from "../../../App";
import { PROFILE } from "../../../RouteConstant";
import { basicDetailSchema } from "./validationSchema";
import { ActionType } from "../../../Reducer";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const cityStateArr: any = {
  "Andra Pradesh": [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Kadapa",
    "Krishna",
    "Kurnool",
    "Prakasam",
    "Nellore",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
  ],
  "Arunachal Pradesh": [
    "Anjaw",
    "Changlang",
    "Dibang Valley",
    "East Kameng",
    "East Siang",
    "Kra Daadi",
    "Kurung Kumey",
    "Lohit",
    "Longding",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Namsai",
    "Papum Pare",
    "Siang",
    "Tawang",
    "Tirap",
    "Upper Siang",
    "Upper Subansiri",
    "West Kameng",
    "West Siang",
    "Itanagar",
  ],
  Assam: [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup Metropolitan",
    "Kamrup (Rural)",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Dima Hasao",
    "Sivasagar",
    "Sonitpur",
    "South Salmara Mankachar",
    "Tinsukia",
    "Udalguri",
    "West Karbi Anglong",
  ],
  Bihar: [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",
  ],
  Chhattisgarh: [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada",
    "Dhamtari",
    "Durg",
    "Gariaband",
    "Janjgir Champa",
    "Jashpur",
    "Kabirdham",
    "Kanker",
    "Kondagaon",
    "Korba",
    "Koriya",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur",
    "Surguja",
  ],
  Goa: ["North Goa", "South Goa"],
  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udaipur",
    "Dahod",
    "Dang",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kheda",
    "Kutch",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada",
    "Navsari",
    "Panchmahal",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha",
    "Surat",
    "Surendranagar",
    "Tapi",
    "Vadodara",
    "Valsad",
  ],
  Haryana: [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Mewat",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar",
  ],
  "Himachal Pradesh": [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Una",
  ],
  "Jammu Kashmir": [
    "Anantnag",
    "Bandipora",
    "Baramulla",
    "Budgam",
    "Doda",
    "Ganderbal",
    "Jammu",
    "Kargil",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Reasi",
    "Samba",
    "Shopian",
    "Srinagar",
    "Udhampur",
  ],
  Jharkhand: [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela Kharsawan",
    "Simdega",
    "West Singhbhum",
  ],
  Karnataka: [
    "Bagalkot",
    "Bangalore Rural",
    "Bangalore Urban",
    "Belgaum",
    "Bellary",
    "Bidar",
    "Vijayapura",
    "Chamarajanagar",
    "Chikkaballapur",
    "Chikkamagaluru",
    "Chitradurga",
    "Dakshina Kannada",
    "Davanagere",
    "Dharwad",
    "Gadag",
    "Gulbarga",
    "Hassan",
    "Haveri",
    "Kodagu",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysore",
    "Raichur",
    "Ramanagara",
    "Shimoga",
    "Tumkur",
    "Udupi",
    "Uttara Kannada",
    "Yadgir",
  ],
  Kerala: [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ],
  "Madhya Pradesh": [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],
  Maharashtra: [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  Manipur: [
    "Bishnupur",
    "Chandel",
    "Churachandpur",
    "Imphal East",
    "Imphal West",
    "Jiribam",
    "Kakching",
    "Kamjong",
    "Kangpokpi",
    "Noney",
    "Pherzawl",
    "Senapati",
    "Tamenglong",
    "Tengnoupal",
    "Thoubal",
    "Ukhrul",
  ],
  Meghalaya: [
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "North Garo Hills",
    "Ri Bhoi",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],
  Mizoram: [
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  Nagaland: [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],
  Odisha: [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Debagarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghpur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Subarnapur",
    "Sundergarh",
  ],
  Punjab: [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Firozpur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Mohali",
    "Muktsar",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sangrur",
    "Shaheed Bhagat Singh Nagar",
    "Tarn Taran",
  ],
  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Ganganagar",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Tonk",
    "Udaipur",
  ],
  Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  "Tamil Nadu": [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],
  Telangana: [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar",
    "Jogulamba",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem",
    "Mahabubabad",
    "Mahbubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Ranga Reddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal Rural",
    "Warangal Urban",
    "Yadadri Bhuvanagiri",
  ],
  Tripura: [
    "Dhalai",
    "Gomati",
    "Khowai",
    "North Tripura",
    "Sepahijala",
    "South Tripura",
    "Unakoti",
    "West Tripura",
  ],
  "Uttar Pradesh": [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi",
    "Amroha",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kasganj",
    "Kaushambi",
    "Kheri",
    "Kushinagar",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "Raebareli",
    "Rampur",
    "Saharanpur",
    "Sambhal",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamli",
    "Shravasti",
    "Siddharthnagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],
  Uttarakhand: [
    "Almora",
    "Bageshwar",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Pauri",
    "Pithoragarh",
    "Rudraprayag",
    "Tehri",
    "Udham Singh Nagar",
    "Uttarkashi",
  ],
  WestBengal: [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Cooch Behar",
    "Dakshin Dinajpur",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Jhargram",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Bardhaman",
    "Paschim Medinipur",
    "Purba Bardhaman",
    "Purba Medinipur",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur",
  ],
  "Andaman Nicobar": ["Nicobar", "North Middle Andaman", "South Andaman"],
  Chandigarh: ["Chandigarh"],
  DadraHaveli: ["Dadra Nagar Haveli"],
  DamanDiu: ["Daman", "Diu"],
  Delhi: [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West Delhi",
    "West Delhi",
  ],
  Lakshadweep: ["Lakshadweep"],
  Puducherry: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
};

const bloodGroupOptions = [
  { value: "A+", text: "A+" },
  { value: "A-", text: "A-" },
  { value: "B+", text: "B+" },
  { value: "B-", text: "B-" },
  { value: "AB+", text: "AB+" },
  { value: "AB-", text: "AB-" },
  { value: "O-", text: "O-" },
  { value: "O+", text: "O+" },
  { value: "Unknown", text: "Unknown" },
];

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <Text sx={colorObj} variant="body1">
      People with{" "}
      <Text
        sx={{ ...colorObj, fontWeight: 600 }}
        variant="body2"
        component="span"
      >
        updated profile
      </Text>{" "}
      have{" "}
      <Text
        variant="body2"
        sx={{ ...colorObj, fontWeight: 600 }}
        component="span"
      >
        higher chances
      </Text>{" "}
      of being recogonised by Mentors and Recruiters.
    </Text>
  ),
};

const EditBasicDetails = () => {
  const navigate = useNavigate();
  
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const { state,dispatch } = useContext<any>(AppContext);
  const [data, setData] = useState<{
    fname: string;
    lname: string;
    contact_no: string;
    email: string;
    dob: any;
    gender: string;
    marital_status: string;
    blood_group: string;
    address_1: string;
    address_2: string;
    post_code: string;
    state: string;
    city: string;
    is_hide_phone:boolean;
  }>({
    fname: "",
    lname: "",
    contact_no: "",
    email: "",
    dob: "",
    gender: "",
    marital_status: "",
    blood_group: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    post_code: "",
    is_hide_phone:false
  });
  useEffect(() => {
    setData(state?.user);
    if (state?.user?.state) {
      filterCities(state?.user?.state);
    }
  }, [state]);
  useEffect(() => {
    setStates(
      Object.keys(cityStateArr).map((state) => ({
        value: state,
        text: state,
      }))
    );
  }, []);

  const filterCities = (state: any) => {
    const tempCities = cityStateArr[state]?.map((city: any) => {
      return {
        value: city,
        text: city,
      };
    });
    setCities(tempCities);
  };

  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Form
        validateOnMount={true}
        validationSchema={basicDetailSchema}
        initialValues={{
          fname: data.fname,
          lname: data.lname,
          contact_no: data.contact_no,
          email: data.email,
          dob: data.dob,
          gender: data.gender,
          marital_status: data.marital_status,
          blood_group: data.blood_group,
          address_1: data.address_1,
          address_2: data.address_2,
          state: data.state,
          city: data.city,
          post_code: data.post_code,
          is_hide_phone:data.is_hide_phone
        }}
        onSubmit={(values: any) => {
          const payload = {
            ...values,
            dob: dayjs(values.dob).format("MM-DD-YYYY"),
          };
          return new Promise((res: any) => {
            savePersonalDetails(
              payload,
              (response: any) => {
                res("");
                getUserMe(
                  (data: any) => {
                    dispatch({
                      type: ActionType.GET_USER_SUCCESS,
                      payload: data.user,
                    });
                    navigate(PROFILE);
                  },
                  (error: any) => {
                    console.log(error);
                  }
                );
                handleToast(response.message, success);
              },
              (error: any) => {
                res("");
              }
            );
          });
        }}
        render={({
          values,
          handleChange,
          isValid,
          submitForm,
          isSubmitting,
          setFieldValue,
          dirty 
        }: any) => {
          return (
            <>
              <Box>
                <Text
                  sx={{
                    mb: 3,
                  }}
                  variant="h1"
                >
                  Personal Details
                </Text>

                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="First Name"
                  required
                  name="fname"
                />

                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Last Name"
                  required
                  name="lname"
                />

                <InputBox
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Text>+91 - </Text>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 3 }}
                  onChange={handleChange}
                  placeholder="Entere here..."
                  label="Mobile number"
                  name="contact_no"
                  disabled
                />
                <CheckBox
                  onChange={handleChange}
                  name="is_hide_phone"
                  label="Hide my number"
                  checked={values.is_hide_phone}
                />
              
                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Email ID"
                  required
                  name="email"
                  disabled
                />

                <Datepicker
                  name="dob"
                  views={["day", "month", "year"]}
                  value={dayjs(values.dob)}
                  onChange={(
                    newValue: React.SetStateAction<dayjs.Dayjs | null>
                  ) => setFieldValue('dob',newValue)}
                  label="Date of Birth"
                  maxDate={dayjs().subtract(16, "year")}
                  defaultValue={dayjs().subtract(16, "year")}
                />

                <CustomRadio
                  sx={{ mt: 3 }}
                  label="Gender"
                  name="gender"
                  id="gender"
                  items={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Others",
                      label: "Others",
                    },
                  ]}
                />

                <CustomRadio
                  sx={{ mt: 3 }}
                  label="Marital Status"
                  name="marital_status"
                  id="marital-status"
                  items={[
                    {
                      value: "Married",
                      label: "Married",
                    },
                    {
                      value: "UnMarried",
                      label: "Un Married",
                    },
                  ]}
                />

                <SelectBox
                  sx={{ mt: 3 }}
                  onChange={handleChange}
                  items={bloodGroupOptions}
                  label="Blood Group"
                  name="blood_group"
                />
              </Box>

              <Box mt={5}>
                <Text
                  sx={{
                    mb: 3,
                  }}
                  variant="h1"
                >
                  Current Address
                </Text>

                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  multiline
                  maxRows={3}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Address 1"
                  name="address_1"
                  required
                />

                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  multiline
                  maxRows={3}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Address 2"
                  name="address_2"
                />

                <InputBox
                  fullWidth
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="PIN Code"
                  name="post_code"
                  required
                />

                <SelectBox
                  sx={{ mb: 3 }}
                  id="state"
                  required
                  items={states}
                  label="State"
                  onChange={(e) => {
                    handleChange(e);
                    filterCities(e);
                  }}
                  name="state"
                />

                <SelectBox
                  sx={{ mb: 3 }}
                  id="city"
                  required
                  items={cities}
                  label="Dist"
                  onChange={handleChange}
                  name="city"
                />
              </Box>

              <Button
                fullWidth
                sx={{ mt: 5 }}
                disabled={!isValid || isSubmitting || !dirty}
                onClick={isValid ? submitForm : () => {}}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default EditBasicDetails;
