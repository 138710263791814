import { useContext, useEffect, useState } from "react";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoginSchema } from "./validation";
import { login } from "../../Services/Service";
import { FORGOT_PASSWORD, PROFILE } from "../../RouteConstant";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ActionType } from "../../Reducer";
import { AppContext } from "../../App";
import { Link } from "react-router-dom";
import handleToast from "../../util/Toast";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useContext<any>(AppContext);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
    password: "",
  });
  const email = localStorage.getItem("email");
  useEffect(() => {
    console.log(email);
    setInitialValues({
      email: email,
      password: "",
    });
  }, [email]);
  const onSuccess = (res: any) => {
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: res.user,
    });

    navigate(PROFILE);
  };

  const OnFailure = (err: any) => {
    console.log(err.data);
    handleToast(err?.data?.message);
  };

  return (
    <Form
      validationSchema={LoginSchema}
      initialValues={initialValues}
      onSubmit={(values: any) => {
        values.email=values.email.toLowerCase();
        return new Promise((rs, rj) => {
          login(
            values,
            (res: any) => {
              onSuccess(res);
              rs("");
            },
            (err: any) => {
              OnFailure(err);
              rj("");
            }
          );
        });
      }}
      render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
        return (
          <>
            <InputBox
              name="email"
              fullWidth
              sx={{ mb: 4 }}
              placeholder="Entere here..."
              label="Email Id"
              onChange={handleChange}
              autoFocus
            />
            <InputBox
              name="password"
              fullWidth
              sx={{ mb: 4 }}
              placeholder="Entere here..."
              label="Password"
              onChange={handleChange}
              type={!showPassword ? "password" : "text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              display="flex"
              justifyContent="end"
              mb={3}
              sx={{
                "& a": {
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
            >
              <Link to={FORGOT_PASSWORD}>Forgot Password</Link>
            </Box>

            <Button
              type="submit"
              shape="primary"
              className="ml-3"
              fullWidth
              size="large"
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              onClick={isValid ? submitForm : () => {}}
            >
              Sign In
            </Button>
          </>
        );
      }}
    />
  );
};

export default Login;
