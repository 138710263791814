import { Card, Text, useSmartPhone } from "@lipihipi/ui-components";
import { Box, IconButton } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { NotVerifiedIcon, VerifiedIcon } from "../../Icons";
import { useState } from "react";
import ProfileDrawer from "./ProfileDrawer";
import { useNavigate } from "react-router-dom";
import * as routePaths from "../../RouteConstant";

const ProfilePicture = ({ profile, setToggle }: any) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(false);
  const [coverImage, setCoverImage] = useState(false);
  const isSmartPhone = useSmartPhone();
  return (
    <>
      <Card disableHeader sx={{ p: 0 }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
          paddingTop={1.5}
          paddingRight={1.5}
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: "#EBF4FF",
            height: 224,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            backgroundImage: `url(${profile?.cover_image})`,
          }}
        >
          <IconButton
            onClick={() => setCoverImage(true)}
            color="primary"
            sx={{ background: "#ffffff", "&:hover": { background: "#ffffff" } }}
          >
            <CameraAltOutlinedIcon htmlColor="#0C4C97" />
          </IconButton>
        </Box>
        <Box px={1.5} pt={3} columnGap={2} pb={1.5}
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row'
            },
            alignItems: {
              xs: 'center'
            },
            rowGap: {
              xs: 2,
              lg: 0
            },
            textAlign: {
              xs: 'center',
              lg: 'left'
            }
          }}
        >
          <Box
            mt={-9}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${profile?.image})`,
              height: "181px",
              border: "6px solid #FFFFFF",
              backgroundColor: "#EBF4FF",
              borderRadius: "50%",
              width: "181px",
            }}
          >
            <IconButton
              onClick={() => setProfileImage(true)}
              color="primary"
              sx={{
                background: "#ffffff",
                "&:hover": { background: "#ffffff" },
              }}
            >
              <CameraAltOutlinedIcon htmlColor="#0C4C97" />
            </IconButton>
          </Box>
          <Box>
            <Box mb={1} columnGap={1.5} display="flex" alignItems="center">
              <Text variant="h1">
                {profile?.user?.fname} {profile?.user?.lname}
              </Text>
              <Box display="flex" columnGap={0.5} alignItems="center">
                {parseInt(profile?.user?.verified_user) ? (
                  <>
                    <VerifiedIcon />
                    {!isSmartPhone && <Text sx={{ color: "#00C385" }}>Verified</Text>}
                  </>
                ) : (
                  <>
                    {!isSmartPhone ? (
                      <>
                        <NotVerifiedIcon />
                        <Text
                          onClick={() => navigate(routePaths.SUBSCRIPTION)}
                          sx={{ color: "#EB5757",cursor:'pointer' }}
                        >
                          Not Verified
                        </Text>
                      </>
                    ) : <NotVerifiedIcon onClick={() => navigate(routePaths.SUBSCRIPTION)} />}
                    
                  </>
                )}
              </Box>
            </Box>
            {profile?.job_type === "0" ? (
              <Text sx={{ mt: 0.5 }}>Fresher</Text>
            ) : (
              <Text sx={{ mt: 0.5 }}>
                {profile?.designation ? `${profile?.designation} at` : ""}{" "}
                {profile?.company_name ? profile?.company_name : ""}
              </Text>
            )}
            <Text sx={{ mt: 0.5 }}>{profile?.state}</Text>
          </Box>
        </Box>
      </Card>

      <ProfileDrawer
        open={profileImage}
        setOpen={setProfileImage}
        previewPic={profile?.image}
        setToggle={setToggle}
      />

      <ProfileDrawer
        isCoverImage
        open={coverImage}
        setOpen={setCoverImage}
        previewPic={profile?.cover_image}
        setToggle={setToggle}
      />
    </>
  );
};

export default ProfilePicture;
