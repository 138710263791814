import { Card, Text } from "@lipihipi/ui-components";
import React from "react";
import { WalletIcon } from "../../Icons";

const Wallet = ({ walletAmount = "00.00" }: any) => {
  return (
    <Card disableHeader sx={{ textAlign: 'center' }}>
      <WalletIcon />
      <Text sx={{ mb: 0.5, mt: 1.5 }}>My Wallet Balance</Text>
      <Text variant="h1">Rs. {walletAmount}</Text>
    </Card>
  )
};

export default Wallet;