import { useContext, useEffect, useState } from "react";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RegisterSchema } from "./validation";
import { register } from "../../Services/Service";
import { OTP } from "../../RouteConstant";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ActionType } from "../../Reducer";
import { AppContext } from "../../App";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { dispatch } = useContext<any>(AppContext);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
    password: "",
    password_confirmation: "",
    termsandconditions: false,
    user_type: 2,
  });
  const searchParams = new URLSearchParams(window.location.search);
  const referCode = searchParams.get("code");
  const email = localStorage.getItem("email");
  useEffect(() => {
    setInitialValues({
      email: email,
      password: "",
      password_confirmation: "",
      termsandconditions: false,
      user_type: 2,
    });
  }, [email]);
  const onSuccess = (res: any) => {
    console.log(res);
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: res.user,
    });
    navigate(OTP);
  };

  const OnFailure = (err: any) => {
    console.log(err);
    console.log(err, "after login email failure");
  };
  return (
    <Form
      validationSchema={RegisterSchema}
      initialValues={initialValues}
      onSubmit={(values: any) => {
        values.email=values.email.toLowerCase();
        return new Promise((rs, rj) => {
          if(referCode){
            values.referal_code = referCode
          }
          register(
            values,
            (res: any) => {
              onSuccess(res);
              rs("");
            },
            (err: any) => {
              OnFailure(err);
              rj("");
            }
          );
        });
      }}
      render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
        return (
          <>
            <InputBox
              name="email"
              type="email"
              id="email"
              fullWidth
              required
              sx={{ mb: 4 }}
              placeholder="Entere here..."
              label="Email Id"
              onChange={handleChange}
              autoComplete="on"
              autoFocus
            />
            <InputBox
              type={!showPassword ? "password" : "text"}
              required
              name="password"
              fullWidth
              sx={{ mb: 4 }}
              placeholder="Entere here..."
              label="Password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <InputBox
              name="password_confirmation"
              fullWidth
              sx={{ mb: 4 }}
              placeholder="Entere here..."
              label="Confirm Password"
              onChange={handleChange}
              type={!showConfirmPassword ? "password" : "text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {!showConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleChange}
                  name="termsandconditions"
                />
              }
              label="Terms and condition"
            />
            <Button
              type="submit"
              shape="primary"
              className="ml-3"
              fullWidth
              size="large"
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              onClick={isValid ? submitForm : () => {}}
            >
              Sign In
            </Button>
          </>
        );
      }}
    />
  );
};

export default Register;
