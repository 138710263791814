export enum ActionType {
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  LOGOUT = "LOGOUT",
  SET_LOADING_FLAG = "SET_LOADING_FLAG",
}

// export type AppState = {
//   loading: boolean;
//   user: any;
//   business: any;
// };

export type Action =
  | {
      type: ActionType.GET_USER_SUCCESS;
      payload: any;
    }
  | {
      type: ActionType.LOGOUT;
      payload: any;
    }
  | {
      type: ActionType.SET_LOADING_FLAG;
      payload: any;
    };

export const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case ActionType.SET_LOADING_FLAG:
      return {
        ...state,
        page_loading: action.payload,
      };
    case ActionType.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        page_loading: false,
      };
    case ActionType.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};
