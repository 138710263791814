import { useEffect } from "react";
import { saveInterviewDetail } from "../../Services/Service";
import { Button, Text } from "@lipihipi/ui-components";
import { Box, Container } from "@mui/material";
import { Logo } from "../../Icons";
import { SITE_URL } from "../../constant";
const SaveInterviewResult = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const jwt = searchParams.get("jwt");

  useEffect(() => {
    saveInterviewDetail({ jwt: jwt });
  }, []);

  return (
    <Box
      sx={{ background: "#fff" }}
      py={3}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          <Box
            mb={2.5}
            sx={{
              width: "125px",
              borderRadius: "18px",
              overflow: "hidden",
              backgroundColor: "#D9D9D9",
              border: "1px solid #D9D9D9",

              "& img": {
                maxWidth: "100%",
                display: "block",
              },
            }}
          >
          </Box>
          <Text align="center" variant="h2" sx={{ mb: 1 }}>
            Thanks for completing the AI- Based Screening Round.
          </Text>
          <Text align="center" variant="body1" sx={{ mb: 3 }}>
            Someone from the team will connect with you soon.
          </Text>

          <Button
            onClick={() => {
              window.location.href = SITE_URL;
            }}
          >
            Close Window
          </Button>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text variant="h4" sx={{ mb: 3 }} align="center">
            Powered by:
          </Text>
          <Logo />
          <Box
            my={2}
            sx={{
              fontSize: "14px",
              lineHeight: "190%",
              color: "#272634",
              padding: "6px 16px",
              borderRadius: "4px",
              display: "inline-flex",
              background: "#F9FAFC",
            }}
          >
            AI-Based JobTech Platform
          </Box>
          <Text variant="h4" sx={{ mb: 1 }} align="center">
            We are known for connecting people to their next great opportunity.
          </Text>
          <Text variant="h4" align="center">
            Our mission is not only to help people in finding meaningful
            employment but also to earn money during job searching period.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default SaveInterviewResult;
