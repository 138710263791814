import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume10: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td style={{ padding: "50px 0px 0" }} colSpan={2}>
          <div style={{ borderBottom: "1px solid #5B5B5B", padding: "40px 0" }}>
            <h1
              style={{
                textAlign: "center",
                lineHeight: "normal",
                margin: 0,
                textTransform: "uppercase",
                fontSize: 46,
                fontFamily:
                  "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                fontWeight: 300,
                color: "#5A5A5A",
              }}
            >
              {data.resume?.firstName} {data.resume?.lastName}
            </h1>
            <p
              style={{
                margin: "10px 0 0",
                letterSpacing: 2,
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: 20,
                color: "#5A5A5A",
                fontFamily:
                  "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
              }}
            >
              {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "30%",
            padding: 30,
            borderRight: "1px solid #5B5B5B",
            borderBottom: "1px solid #5B5B5B",
            verticalAlign: "top",
          }}
        >
          <div style={{ marginTop: 0 }}>
            <h3
              style={{
                lineHeight: "normal",
                margin: 0,
                fontSize: 24,
                fontFamily:
                  "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                fontWeight: "normal",
                color: "#5A5A5A",
              }}
            >
              Contact
            </h3>
            <div style={{ marginTop: 16 }}>
              <div style={{ paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 16,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 16,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#5A5A5A",
                    fontSize: 16,
                    margin: "6px 0 12px",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.educations?.length ? (
            <div
              style={{
                marginTop: 25,
                borderTop: "1px dashed #5B5B5B",
                paddingTop: 20,
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Education
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 16 }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#5A5A5A",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.educationType.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#5A5A5A",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.schoolName.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                          color: "#5A5A5A",
                          fontSize: 14,
                          margin: "6px 0 12px",
                        }}
                      >
                        <span>Percentage:</span> {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        | <span>Year:</span> {education.passedYear}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div
              style={{
                marginTop: 30,
                borderTop: "1px dashed #5B5B5B",
                paddingTop: 20,
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Expertise
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 14,
                        marginTop: 8,
                        marginRight: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {skill.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div
              style={{
                marginTop: 30,
                borderTop: "1px dashed #5B5B5B",
                paddingTop: 20,
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 14,
                        marginTop: 8,
                        marginRight: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {hobby}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div
              style={{
                marginTop: 30,
                borderTop: "1px dashed #5B5B5B",
                paddingTop: 20,
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 14,
                        marginTop: 8,
                        marginRight: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {language}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td
          style={{
            width: "70%",
            borderBottom: "1px solid #5B5B5B",
            padding: 30,
            verticalAlign: "top",
          }}
        >
          {data.resume?.objective?.length ? (
            <div
              style={{ borderBottom: "1px dashed #5B5B5B", paddingBottom: 20 }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Profile
              </h3>
              <div style={{ marginTop: 8 }}>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                    lineHeight: "170%",
                    color: "#5A5A5A",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  }}
                >
                  {data.resume?.objective}
                </p>
              </div>
            </div>
          ) : null}
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 20 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Work Experience
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.experiences?.map((experience, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                        color: "#5A5A5A",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {experience.position}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#5A5A5A",
                        fontSize: 15,
                        margin: "6px 0 12px",
                      }}
                    >
                      {index === 0 ? (
                        <>
                          {`${experience.companyName} | ${experience.startDate} - `}
                          <strong style={{ fontWeight: "bold" }}>
                            {experience.endDate !== null
                              ? experience.endDate
                              : "Present"}
                          </strong>
                        </>
                      ) : (
                        <>{`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}</>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 24,
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#5A5A5A",
                }}
              >
                Projects
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.projects?.map((project, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                        color: "#5A5A5A",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#5A5A5A",
                        fontSize: 16,
                        margin: "8px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#5A5A5A" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#5A5A5A" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#5A5A5A" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#5A5A5A",
                        fontSize: 16,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume10;
