import * as Yup from "yup";

export const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Password is required"),
});
export const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Password is required"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ).required("Password confirmation is required"),
  termsandconditions: Yup.boolean()
    .test('is-true', 'Please accept the terms and conditions', (value) => value === true).required("Password is required")
});
