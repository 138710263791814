import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Text } from "@lipihipi/ui-components";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  flex: 1,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#A7E3D0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FDFDFD",
  },
}));

const classes = {
	accordion: {
		background: "#00C385",
		borderRadius: "8px !important",
		boxShadow: "0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)",
		"&.Mui-expanded": {
			margin: 0,
		},
	},
	root: {
		padding: "16px",
		alignItems: "flex-end",
		"& .MuiAccordionSummary-expandIconWrapper": {
			color: "#fff",
		},
		"&.Mui-expanded": {
			minHeight: "48px",
		},
		"& .MuiAccordionSummary-content": {
			margin: 0,
			"&.Mui-expanded": {
				margin: "0",
			},
		},
	},
}

const Strength = ({ data }: any) => {
	const profileStrength = data?.profile?.given_strength_area?.split(",");

	if (data?.profile?.profile_strength === 100) {
		return <></>
	}

	return (
		<Accordion sx={classes.accordion} elevation={0}>
			<AccordionSummary
				sx={classes.root}
				expandIcon={<ExpandCircleDownIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Box flexGrow={1} pr={1}>
					<Text sx={{ color: "#fff", mb: 1 }} variant="h4">
						Profile Completeness
					</Text>
					<Box display="flex" columnGap={1} alignItems="center">
						<BorderLinearProgress
							variant="determinate"
							value={data?.profile?.profile_strength}
						/>
						<Text
							sx={{ color: "#fff" }}
							variant="subtitle2"
						>{`${data?.profile?.profile_strength}%`}</Text>
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ paddingTop: 0 }}>
				<Box display="flex" flexDirection="column" rowGap={1}>
					{!profileStrength?.includes("1") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Image
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								5%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("2") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Basic
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								5%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("3") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Job Details*
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								10%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("4") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Education*
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								15%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("12") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Employer*
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								15%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("6") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Skill*
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								15%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("8") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Hobbies
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								5%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("9") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Projects
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								10%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("10") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								About*
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								10%
							</Text>
						</Box>
					)}
					{!profileStrength?.includes("11") && (
						<Box
							display="flex"
							alignItems="center"
							flexGrow={1}
							justifyContent="space-between"
						>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								Resume
							</Text>
							<Text sx={{ color: "#fff" }} variant="subtitle2">
								10%
							</Text>
						</Box>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	)
};

export default Strength;