import React, { useState,useContext } from "react";
import GRAPHIC from "../../Images/identity.svg";
import { AuthLayout } from "../../Components";
import {
  Button,
  CustomRadio,
  Form,
  InputBox,
  Text,
} from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { updatePersonalInfo } from "../../Services/Service";
import { onFailure } from "../../util/helper";
import { ADD_PHONE } from "../../RouteConstant";
import { personalDetailValidationSchema } from "./validationSchema";
import { AppContext } from "../../App";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Your Identity is,",
  secondaryText: "important for your Identity!",
};

const GeneralInfo = () => {
  const navigate = useNavigate();
  const { state } = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = useState<any>({
    fname: state?.user?.fname,
    lname: state?.user?.lname,
  });

  const onSucess = (res: any) => {
    navigate(ADD_PHONE);
  };

  return (
    <AuthLayout currentStep={2} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please Provide your Name
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={personalDetailValidationSchema}
        onSubmit={(values: any) => {
          updatePersonalInfo(onSucess, onFailure, values);
        }}
        render={({ handleChange, isValid, isSubmitting }: any) => {
          return (
            <>
              <InputBox
                fullWidth
                required
                sx={{ mb: 3 }}
                onChange={handleChange}
                placeholder="Entere here..."
                label="First Name"
                name="fname"
              />
              <InputBox
                fullWidth
                required
                sx={{ mb: 3 }}
                onChange={handleChange}
                placeholder="Entere here..."
                label="Last Name"
                name="lname"
              />
              <CustomRadio
                sx={{ mt: 3 }}
                label="Gender"
                name="gender"
                id="gender"
                items={[
                  {
                    value: "Male",
                    label: "Male",
                  },
                  {
                    value: "Female",
                    label: "Female",
                  },
                  {
                    value: "Others",
                    label: "Others",
                  },
                ]}
              />
              <CustomRadio
                sx={{ mt: 3 }}
                label="Marital Status"
                name="marital_status"
                id="marital-status"
                items={[
                  {
                    value: "Married",
                    label: "Married",
                  },
                  {
                    value: "UnMarried",
                    label: "Un Married",
                  },
                ]}
              />
              <Button
                type="submit"
                sx={{ mt: 5 }}
                fullWidth
                size="large"
                disabled={!isValid || isSubmitting}
              >
                Next
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default GeneralInfo;
