import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/job.svg";
import {
  Button,
  FileUpload,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import {
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getProject,
  saveProject,
} from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { ProjectSchema } from "./validationSchema";
import { PROFILE } from "../../../RouteConstant";
import { remove, uploadPublic } from "../../../Services/FileUploadService";
import { useParams } from "react-router";
import { ASSET_URL } from "../../../constant";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <Text sx={colorObj} variant="body1">
      People with{" "}
      <Text
        sx={{ ...colorObj, fontWeight: 600 }}
        variant="body2"
        component="span"
      >
        updated profile
      </Text>{" "}
      have{" "}
      <Text
        variant="body2"
        sx={{ ...colorObj, fontWeight: 600 }}
        component="span"
      >
        higher chances
      </Text>{" "}
      of being recogonised by Mentors and Recruiters.
    </Text>
  ),
};

const projectType = [
  {
    value: "Company Project",
    text: "Company Project",
  },
  {
    value: "Course",
    text: "Course",
  },
  {
    value: "Certification",
    text: "Certification",
  },
  {
    value: "Organisation",
    text: "Organisation",
  },
  {
    value: "Language",
    text: "Language",
  },
];
const AddEditProject = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [initialValues, setinitialValues] = useState({
    project_type: "",
    project_name: "",
    project_duration: "",
    role: "",
    team_size: "",
    technology: "",
    project_link: "",
    project_doc: "",
  });
  useEffect(() => {
    if(id){
      getProject(id, (res: any) => {
        setinitialValues(res.project);
      });
    }
  }, [id]);
  const handleSubmit = (values: any) => {
    const payload: any = {};
    if (id) {
      payload.job_seeker_project_id = id;
    }
    if (values.project_doc) {
      payload.project_doc = values.project_doc;
    }
    payload.project_duration = values.project_duration;
    if (values.project_link) {
      payload.project_link = values.project_link;
    }
    if (values.project_desc) {
      payload.project_desc = values.project_desc;
    }
    if (values.company_associated) {
      payload.company_associated = values.company_associated;
    }
    payload.project_name = values.project_name;
    payload.project_type = values.project_type;
    payload.role = values.role;
    payload.team_size = values.team_size;
    payload.technology = values.technology;
    saveProject(
      payload,
      (response: any) => {
        handleToast(response.message, success);
        navigate(PROFILE);
      },
      (error: any) => {
        setIsSubmitting(false);
      }
    );
  };

  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Project
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={ProjectSchema}
        onSubmit={handleSubmit}
        render={({
          values,
          handleChange,
          isValid,
          submitForm,
        }: //   isSubmitting,
        any) => {
          return (
            <>
              <SelectBox
                sx={{ mb: 3 }}
                required
                fullWidth
                items={projectType}
                label="Porject Type"
                name="project_type"
              />
              <InputBox
                fullWidth
                name="company_associated"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Company Name"
              />
              <InputBox
                fullWidth
                name="project_name"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Project Name"
              />
              <InputBox
                fullWidth
                name="project_duration"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Project Duration"
                required
              />
              <InputBox
                fullWidth
                name="role"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Role"
              />
              <InputBox
                fullWidth
                name="team_size"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Team Size"
              />
              <InputBox
                fullWidth
                name="technology"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Technology"
                required
              />
              <InputBox
                fullWidth
                name="project_link"
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Project Link"
              />
              <Box
                flexGrow={1}
                p={3}
                sx={{
                  "& .row + div": {
                    mt: 2,
                    "& > div": {
                      margin: 0,
                      gap: "1rem",
                      width: "100%",
                      "& svg": {
                        width: "1.5rem",
                        height: "1.5rem",
                      },
                      "& > div": {
                        flexGrow: 1,
                        "& .card-title": {},

                        "& p": {
                          margin: 0,
                        },
                      },
                    },
                  },
                  "& .col-md-2": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  },
                  "& .custom-file label": {
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px dashed #3047EC",
                    cursor: "pointer",
                    background: "#FFF",
                    height: "48px",
                  },
                }}
              >
                <FileUpload
                  name={"project_doc"}
                  label="Upload project"
                  upload={uploadPublic}
                  accept={[
                    "application/pdf",
                    "application/doc",
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                  ]}
                  type={"single"}
                  assetUrl={ASSET_URL}
                  maxSize={"10mb"}
                  onDelete={remove}
                />
              </Box>
              <InputBox
                fullWidth
                name="project_desc"
                inputProps={{ maxLength: 3000 }}
                sx={{ mb: 3 }}
                multiline
                helperText={`${
                  values?.project_desc?.length
                    ? values?.project_desc?.length
                    : 0
                }/3000 Characters`}
                maxRows={10}
                placeholder="Write here something about your roles and responsibilities..."
                onChange={handleChange}
                label="Description"
              />

              <Button
                disabled={!isValid || isSubmitting}
                onClick={isValid ? submitForm : () => {}}
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default AddEditProject;
