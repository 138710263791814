import * as Yup from 'yup';

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

  export const mobileValidationSchema = Yup.object().shape({
    contact_no: Yup.string()
    .matches(MOBILE_REGEX, 'Invalid Mobile No.')
    .required('Mobile No. is required')
    
  });
  export const personalDetailValidationSchema = Yup.object().shape({
    fname: Yup.string().required('First name is required'),
    lname: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
    marital_status: Yup.string().required('Marital status is required')
  });





