import { useEffect, useState } from "react";
import { AuthLayout } from "../../Components";
import GRAPHIC from "../../Images/profile.svg";
import { Button, Form, InputBox, Text } from "@lipihipi/ui-components";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router-dom";
import { changePassword, getEmailFromToken } from "../../Services/Service";
import { PasswordResetSchema } from "./validationSchema";
import { LOGIN } from "../../RouteConstant";
import Swal from "sweetalert2";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "A complete Profile,",
  secondaryText: "Attracts more recruiters!",
};

const Password = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
    password: "",
    token: token,
  });
  useEffect(() => {
    if (token) {
      getEmailFromToken(
        token,
        (res: any) => {
          setInitialValues({
            email: res.email,
          });
        },
        (err: any) => {
          setError(err.data.message);
        }
      );
    }
  }, [token]);

  return (
    <AuthLayout currentStep={0} data={onboardingData}>
      {!error ? (
        <>
          <Text
            sx={{
              mb: 3,
            }}
            variant="h2"
          >
            Please update your password
          </Text>

          <Form
            initialValues={initialValues}
            validationSchema={PasswordResetSchema}
            validateOnMount={true}
            onSubmit={(values: any) => {
              return new Promise((res: any) => {
                values.token = token
                changePassword(
                  values,
                  (response: any) => {
                    res("");
                    Swal.fire({
                      title: 'Success',
                      text: "Your password changed successfully",
                      icon: 'success',
                      confirmButtonText: 'Ok'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate(LOGIN);
                      }
                    })
                  },
                  (err: any) => {
                    res("");
                  }
                );
              });
            }}
            render={({
              handleChange,
              isValid,
              submitForm,
              isSubmitting,
            }: any) => {
              return (
                <>
                  <InputBox
                    fullWidth
                    required
                    sx={{ mb: 3 }}
                    onChange={handleChange}
                    placeholder="Entere here..."
                    label="Email Id"
                    name="email"
                  />
                  <InputBox
                    fullWidth
                    type={!showPassword ? "password" : "text"}
                    required
                    name="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mb: 3 }}
                    onChange={handleChange}
                    placeholder="Entere here..."
                    label="Create Password"
                  />

                  <InputBox
                    fullWidth
                    type="password"
                    sx={{ mb: 2 }}
                    required
                    name="password_confirmation"
                    onChange={handleChange}
                    placeholder="Entere here..."
                    label="Re Enter Password"
                  />
                  <Button
                    sx={{ mt: 5 }}
                    disabled={!isValid || isSubmitting}
                    onClick={isValid ? submitForm : () => {}}
                    fullWidth
                    size="large"
                  >
                    Next
                  </Button>
                </>
              );
            }}
          />
        </>
      ) : (
        <Text
          sx={{
            mb: 3,
          }}
          variant="h2"
        >
          {error}
        </Text>
      )}
    </AuthLayout>
  );
};

export default Password;
