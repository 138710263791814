import { Button, Card, Text } from "@lipihipi/ui-components"
import { SecondaryLayout } from "../SecondaryLayout"
import { Avatar, Box } from "@mui/material"

const styles = {
    primaryText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#161458'
    },
    secondaryText: {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#7A7A7A'
    },
    button: {
        padding: 0,
        minWidth: '1px',
        height: 'auto',
        borderRadius: 0,
        marginTop: '9px',
        color: '#00C385',
        '&:hover': {
            background: 'transparent'
        }
    },
    withImage: {
        display: 'flex',
        gap: '1rem',
    },
    card: {
        display: 'flex',
        gap: '1rem',
        '& > div': {
            flex: 1
        }
    }
}

const Notification = () => {
    return (
        <SecondaryLayout currentActive={-1}>
            <Text variant="h3">All Notification for you</Text>

            <Box display='flex' flexDirection='column' gap={2} mt={2}>
                <Card px={2} py={2} disableHeader sx={styles.card}>
                    <Box>
                        <Text sx={styles.primaryText}>New job postings matching their skills and preferences.</Text>
                        <Button sx={styles.button} variant="text">View</Button>
                    </Box>
                    <Text sx={styles.secondaryText}>3 days ago</Text>
                </Card>
                <Card px={2} py={2} disableHeader sx={styles.card}>
                    <Box>
                        <Text sx={styles.primaryText}>New job postings matching their skills and preferences.</Text>
                    </Box>
                    <Text sx={styles.secondaryText}>3 days ago</Text>
                </Card>

                <Card px={2} py={2} disableHeader sx={styles.card}>
                    <Box sx={styles.withImage}>
                        <Avatar src="https://lipijobs-stage.s3.ap-south-1.amazonaws.com/371712847.png" />
                        <Text sx={styles.primaryText}>
                        Congratulations! You've earned reward points for your recent activity. Keep engaging with our platform to earn more points and unlock exciting rewards!"
                        </Text>
                    </Box>
                    <Text sx={styles.secondaryText}>3 days ago</Text>
                </Card>
            </Box>
        </SecondaryLayout>
    )
}

export default Notification