import { useContext, useEffect, useState } from "react";
import { AuthLayout } from "../../Components";
import {
  Button,
  Form,
  InputBox,
  SocialButton,
  Text,
} from "@lipihipi/ui-components";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { SocialLoginArr, userLogin } from "./constant";
import { socialLoginObject } from "./types";
import GRAPHIC from "../../Images/login.svg";
import { useNavigate } from "react-router-dom";
import { EmailSchema } from "./validation";
import { login, register, socialLogin } from "../../Services/Service";
import {
  FORGOT_PASSWORD,
  GENERAL_INFO,
  OTP,
  PROFILE,
} from "../../RouteConstant";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ActionType } from "../../Reducer";
import { AppContext } from "../../App";
import { Link } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase";
import handleToast from "../../util/Toast";
import Login from "./Login";
import Register from "./Register";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Fix your goals",
  secondaryText: "we’ll help you achieve!",
};

const LoginPage = () => {
  const { state, dispatch } = useContext<any>(AppContext);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const referCode = searchParams.get("code");
  const [isLogin, setIsLogin] = useState<any>(userLogin.Init);

  useEffect(() => {
    const user = state?.user;

    if (!!user) {
      navigate(PROFILE);
    }
  }, [state]);

  const socialLoginHandler = async (provider: any) => {
    const response: any = await signInWithPopup(auth, provider);
    let payload: any = {};
    payload.id_token = response._tokenResponse.idToken;
    console.log(payload);
    if (referCode) {
      payload.referral_code = referCode;
    }
    socialLogin(
      payload,
      (res: any) => {
        dispatch({
          type: ActionType.GET_USER_SUCCESS,
          payload: res.user,
        });
        if (res.user.provider_id && !res.user.gender) {
          navigate(GENERAL_INFO);
        } else {
          navigate(PROFILE);
        }
      },
      (err: any) => {
        handleToast(err.message);
      }
    );
    // Handle the Google login response here
  };
  const googleHandler = () => {
    const provider = new GoogleAuthProvider();
    socialLoginHandler(provider);
  };

  const appleHandler = () => {
    const provider = new OAuthProvider("apple.com");
    socialLoginHandler(provider);
  };

  const onCheckMailSuccess = (res: any) => {
    console.log(res);
    if (res.response_code === 200 && res.user.email) {
      setIsLogin(userLogin.Login);
      // Render the login form
    }
  };

  const OnCheckMailFailure = (err: any) => {
    setIsLogin(userLogin.Register);
  };
  return (
    <AuthLayout isLogin data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Sign in with
      </Text>

      <Box display="flex">
        <Box display="flex" flexDirection="column" rowGap={2}>
          {SocialLoginArr?.map((obj: socialLoginObject) => {
            const { id, borderColor, color, text, backgroundColor, Icon } = obj;
            return (
              <SocialButton
                key={id}
                onClick={id === "google" ? googleHandler : appleHandler}
                borderColor={borderColor}
                color={color}
                backgroundColor={backgroundColor}
                icon={<Icon size={22} style={{ marginRight: 16 }} />}
              >
                {text}
              </SocialButton>
            );
          })}
        </Box>
      </Box>

      <Box
        my={4}
        display="flex"
        justifyContent="center"
        position="relative"
        sx={{
          "&:before": {
            content: "''",
            height: "1px",
            width: "100%",
            backgroundColor: "#DADADA",
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
          },
        }}
      >
        <Text
          px={2.25}
          sx={{
            backgroundColor: "#fff",
            color: "#A5AAB7",
          }}
          position="relative"
        >
          or
        </Text>
      </Box>
      {isLogin === userLogin.Init ? (
        <Form
          validationSchema={EmailSchema}
          initialValues={{ email: "" }}
          onSubmit={(values: any) => {
            values.email=values.email.toLowerCase();
            localStorage.setItem("email", values.email);
            return new Promise((rs, rj) => {
              register(
                values,
                (res: any) => {
                  onCheckMailSuccess(res);
                  rs("");
                },
                (err: any) => {
                  console.log(err);
                  OnCheckMailFailure(err);
                  rj("");
                }
              );
            });
          }}
          render={({
            handleChange,
            isValid,
            isSubmitting,
            submitForm,
          }: any) => {
            return (
              <>
                <InputBox
                  name="email"
                  type="email"
                  id="email"
                  fullWidth
                  required
                  sx={{ mb: 4 }}
                  placeholder="Entere here..."
                  label="Email Id"
                  onChange={handleChange}
                  autoComplete="on"
                  autoFocus
                />
                <Box
                  display="flex"
                  justifyContent="end"
                  mb={3}
                  sx={{
                    "& a": {
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                >
                  <Link to={FORGOT_PASSWORD}>Forgot Password</Link>
                </Box>

                <Button
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={isValid ? submitForm : () => {}}
                  type="submit"
                  shape="primary"
                  className="ml-3"
                  fullWidth
                  size="large"
                >
                  Sign In
                </Button>
              </>
            );
          }}
        />
      ) : isLogin === userLogin.Login ? (
        <Login />
      ) : (
        isLogin === userLogin.Register && <Register />
      )}
    </AuthLayout>
  );
};

export default LoginPage;
