import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume5: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            backgroundColor: "#006666",
            borderTop: "10px solid #004848",
            width: "35%",
            padding: "30px 0",
            verticalAlign: "top",
          }}
        >
          <h1
            style={{
              lineHeight: "normal",
              margin: 0,
              paddingLeft: 30,
              paddingRight: 15,
              textTransform: "uppercase",
              fontSize: 36,
              fontFamily:
                "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
              fontWeight: 600,
              color: "#fff",
            }}
          >
            <span
              style={{
                fontFamily:
                  "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
              }}
            >
              {data.resume?.firstName}
            </span>{" "}
            {data.resume?.lastName}
          </h1>
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  paddingBottom: 8,
                  fontSize: 18,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#fff",
                }}
              >
                Skills
              </h3>
              <div style={{ marginTop: 6 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li key={index}>{skill.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  paddingBottom: 8,
                  fontSize: 18,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#fff",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 6 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  paddingBottom: 8,
                  fontSize: 18,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#fff",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 6 }}>
                <ul
                  style={{
                    padding: "0 8px 0 9",
                    listStyleType: "none",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li key={index}>{language}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td
          style={{
            width: "65%",
            padding: 30,
            verticalAlign: "top",
            borderRight: "1px solid #f1f1f1",
            borderTop: "1px solid #f1f1f1",
            borderBottom: "1px solid #f1f1f1",
          }}
        >
          <p
            style={{
              margin: 0,
              textAlign: "right",
              textTransform: "uppercase",
              fontSize: 18,
              color: "#1AB0B3",
              fontFamily:
                "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          <div style={{ marginTop: 15 }}>
            <div style={{ marginTop: 16 }}>
              <div style={{ paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 16,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 16,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                    color: "#384347",
                    fontSize: 16,
                    margin: "6px 0 12px",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.objective?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  textTransform: "uppercase",
                  fontSize: 24,
                  borderBottom: "2px solid #384347",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#384347",
                }}
              >
                Summary
              </h3>
              <p
                style={{
                  margin: "8px 0 0",
                  fontSize: 16,
                  lineHeight: "170%",
                  fontWeight: "normal",
                  color: "#384347",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                }}
              >
                {data.resume?.objective}
              </p>
            </div>
          ) : null}
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  textTransform: "uppercase",
                  fontSize: 24,
                  borderBottom: "2px solid #384347",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#384347",
                }}
              >
                Work Experience
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #006666",
                  marginTop: 16,
                  marginLeft: 15,
                  paddingLeft: 25,
                }}
              >
                {data.resume?.experiences?.map((experience, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                        lineHeight: "190%",
                        color: "#384347",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          width: 30,
                          height: 30,
                          display: "inline-block",
                          color: "#fff",
                          border: "2px solid #006666",
                          borderRadius: 50,
                          textAlign: "center",
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          fontWeight: "bold",
                          verticalAlign: "top",
                          marginLeft: "-41px",
                          lineHeight: "170%",
                          marginRight: 6,
                          backgroundColor: "#006666",
                        }}
                      >
                        {index + 1}
                      </span>
                      {experience.position}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#384347",
                        fontSize: 16,
                        margin: "6px 0 12px",
                      }}
                    >
                      {index === 0 ? (
                        <>
                          {`${experience.companyName} | ${experience.startDate} - `}
                          <strong style={{ fontWeight: "bold" }}>
                            {experience.endDate !== null
                              ? experience.endDate
                              : "Present"}
                          </strong>
                        </>
                      ) : (
                        <>{`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}</>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  textTransform: "uppercase",
                  fontSize: 24,
                  borderBottom: "2px solid #384347",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#384347",
                }}
              >
                Education
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #006666",
                  marginTop: 16,
                  marginLeft: 15,
                  paddingLeft: 25,
                }}
              >
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          lineHeight: "190%",
                          color: "#384347",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        <span
                          style={{
                            width: 30,
                            height: 30,
                            display: "inline-block",
                            border: "2px solid #006666",
                            borderRadius: 50,
                            textAlign: "center",
                            fontFamily:
                              "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            fontWeight: "bold",
                            verticalAlign: "top",
                            marginLeft: "-41px",
                            lineHeight: "170%",
                            color: "#fff",
                            marginRight: 6,
                            backgroundColor: "#006666",
                          }}
                        >
                          {index + 1}
                        </span>
                        {education.educationType}
                      </p>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          lineHeight: "190%",
                          color: "#384347",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {education.schoolName}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                          color: "#384347",
                          fontSize: 16,
                          margin: "6px 0 12px",
                        }}
                      >
                        Percentage: {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        ( Passing Year: {education.passedYear} )
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 50 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  textTransform: "uppercase",
                  fontSize: 24,
                  borderBottom: "2px solid #384347",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  fontWeight: "normal",
                  color: "#384347",
                }}
              >
                Projects
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.projects?.map((project, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                        color: "#384347",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#384347",
                        fontSize: 16,
                        margin: "8px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#384347" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#384347" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#384347" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                        color: "#384347",
                        fontSize: 16,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume5;
