import { Box, CardActionArea, IconButton } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Text } from "@lipihipi/ui-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import employer from "../../Images/employer.svg";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { useEffect, useState } from "react";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { fetchSavedJobs, saveJob } from "../../Services/Service";
import { ASSET_URL } from "../../constant";
import moment from "moment";
import NoDataFound from "../../Shared/NoData";
import { JOBS_DETAILS } from "../../RouteConstant";
import Loader from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import RightSection from "../../Shared/RightSection";
import handleToast, { success } from "../../util/Toast";
import InfiniteScroll from "react-infinite-scroll-component";

const SavedJobs = () => {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState<any>([]);
  const [toggleData, setToggleData] = useState(false);
  const [count, setCount] = useState(0);
  const [appliedJobCount, setAppliedJobCount] = useState();
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    fetchData(page);
  }, [toggleData]);

  const fetchData = async (pageNumber: any) => {
    // Simulate fetching data from an API
    await fetchSavedJobs(pageNumber, (response: any) => {
      setAppliedJobCount(response?.appliedJobCount);
      setJobData((prev: any) => [...prev, ...response?.data?.data]);
      setTotalPages(response?.data?.last_page);
      setCount(response?.data?.total);
      setHasMore(pageNumber < response?.data?.last_page);
    });
  };

  const loadMoreData = () => {
    if (page < totalPages) {
      setPage(page + 1);
      fetchData(page + 1);
    }
  };
  const handleBookmark = ({ id, alreadyApplied }: any) => {
    const payload = {
      id,
      save_for_later: !Boolean(alreadyApplied),
    };
    saveJob(payload, (response: any) => {
      if (response.success) {
        setToggleData((prev) => !prev);
        handleToast("Job Updated Successfully", success);
      }
    });
  };

  return (
    <SecondaryLayout
      rightPanel={<RightSection appliedJobCount={appliedJobCount} />}
      currentActive={1}
    >
      {jobData?.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box>
          <Text variant="h3">Saved Jobs ({count})</Text>
          <InfiniteScroll
            dataLength={jobData?.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
              {jobData.map((obj: any, index: any) => {
                return (
                  <Card px={0} py={0} disableHeader>
                    <CardActionArea
                      onClick={() =>
                        navigate(JOBS_DETAILS.replace(":jobId", obj?.id))
                      }
                      sx={{ p: 2 }}
                    >
                      <Box display="flex">
                        <Box
                          pr={1}
                          flexGrow={1}
                          display="flex"
                          flexDirection="column"
                          rowGap={2}
                        >
                          <Box>
                            <Text
                              sx={{ textTransform: "capitalize" }}
                              variant="subtitle2"
                            >
                              {obj?.job_title?.toLowerCase()}
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="column"
                            rowGap={1.5}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Text
                                variant="caption"
                                sx={{
                                  textTransform: "capitalize",
                                  display: "flex",
                                  alignItems: "end",
                                  columnGap: 1,
                                }}
                              >
                                <CorporateFareIcon
                                  sx={{ fontSize: 16, color: "#161458" }}
                                />
                                {obj?.company_name?.toLowerCase()}
                              </Text>

                              <Text variant="body2">
                                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                              </Text>

                              <Text
                                variant="caption"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 1,
                                }}
                              >
                                <WorkHistoryIcon
                                  sx={{ fontSize: 16, color: "#161458" }}
                                />
                                {obj?.min_exp}+ years
                              </Text>
                              <Text variant="body2">
                                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                              </Text>
                              <Text
                                variant="caption"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 0.5,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, color: "#161458" }}
                                />
                                {!obj.hide_salary
                                  ? `${obj?.min_annual_salary}${
                                      obj?.min_annual_salary_thousand
                                        ? `.${obj?.min_annual_salary_thousand}`
                                        : ".0"
                                    } - ${obj?.max_annual_salary}${
                                      obj?.max_annual_salary_thousand
                                        ? `.${obj?.max_annual_salary_thousand}`
                                        : ".0"
                                    } LPA `
                                  : "Not Disclosed"}
                              </Text>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Text
                                variant="caption"
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  columnGap: 0.5,
                                }}
                              >
                                <LocationOnIcon
                                  sx={{ fontSize: 16, color: "#161458" }}
                                />
                                {obj?.job_locations}
                              </Text>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              columnGap={2}
                            >
                              <Text
                                variant="caption"
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  columnGap: 0.5,
                                }}
                              >
                                <MilitaryTechIcon
                                  sx={{ fontSize: 16, color: "#161458" }}
                                />
                                {obj?.key_skills}
                              </Text>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          width={60}
                          flexShrink={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={60}
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #F0F0F0",
                            "& img": {
                              maxWidth: "100%",
                              objectFit: "contain",
                              maxHeight: "100%",
                            },
                          }}
                        >
                          <img
                            src={
                              obj?.company_logo
                                ? `${ASSET_URL}/${obj?.company_logo}`
                                : employer
                            }
                            alt=""
                          />
                        </Box>
                      </Box>
                    </CardActionArea>
                    <Box sx={{ px: 2 }} display="flex" alignItems="center">
                      <Text sx={{ color: "#868D90" }} variant="caption">
                        Posted {moment(obj?.created_at, "YYYYMMDD").fromNow()} -
                        By {obj?.created_by}
                      </Text>
                      <IconButton
                        onClick={() =>
                          handleBookmark({
                            id: obj?.id,
                            alreadyApplied: obj?.save_for_later,
                          })
                        }
                        color={
                          obj?.save_for_later === 1 ? "success" : "default"
                        }
                        sx={{ ml: "auto" }}
                      >
                        {obj?.save_for_later === 1 ? (
                          <BookmarkRemoveIcon
                            sx={{ fontSize: 20, color: "#00C385" }}
                          />
                        ) : (
                          <BookmarkBorderIcon sx={{ fontSize: 20 }} />
                        )}
                      </IconButton>
                    </Box>
                  </Card>
                );
              })}
            </Box>
          </InfiniteScroll>
        </Box>
      )}
    </SecondaryLayout>
  );
};

export default SavedJobs;
