import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume1: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            backgroundColor: "#313b4b",
            width: "30%",
            padding: "30px 0",
            verticalAlign: "top",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                width: 180,
                height: 180,
                display: "inline-block",
                borderRadius: 180,
                border: "6px solid rgb(255, 255, 255)",
              }}
              src={data?.user?.image}
              alt=""
            />
          </div>
          <div style={{ marginTop: 30, paddingLeft: 30 }}>
            <h3
              style={{
                lineHeight: "normal",
                margin: 0,
                paddingBottom: 8,
                fontSize: 24,
                borderBottom: "2px solid #fff",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                fontWeight: 600,
                color: "#fff",
              }}
            >
              Contact
            </h3>
            <div style={{ marginTop: 16 }}>
              <div style={{ paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#fff",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#fff",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#fff",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 12,
                    margin: "6px 0 12px",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Education
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        paddingRight: 8,
                        marginTop: index === 0 ? 0 : 16,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                          color: "#fff",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.educationType.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                          color: "#fff",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.schoolName.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                          color: "#fff",
                          fontSize: 12,
                          margin: "6px 0 12px",
                        }}
                      >
                        <span>Percentage:</span> {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        | <span>Year:</span> {education.passedYear}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Expertise
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li key={index}>{skill.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #fff",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 9",
                    listStyleType: "none",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li key={index}>{language}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td
          style={{
            width: "70%",
            padding: 30,
            verticalAlign: "top",
            borderRight: "1px solid #f1f1f1",
            borderTop: "1px solid #f1f1f1",
            borderBottom: "1px solid #f1f1f1",
          }}
        >
          <h1
            style={{
              lineHeight: "normal",
              margin: 0,
              fontSize: 36,
              fontFamily:
                "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
              fontWeight: 300,
              color: "#2f3847",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
              }}
            >
              {data.resume?.firstName}
            </span>{" "}
            {data.resume?.lastName}
          </h1>
          <p
            style={{
              margin: "10px 0 0",
              fontSize: 18,
              color: "#2f3847",
              fontFamily:
                "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          {data.resume?.objective?.length ? (
            <p
              style={{
                margin: "32px 0 0",
                fontSize: 14,
                lineHeight: "170%",
                color: "#707070",
                fontFamily:
                  "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
              }}
            >
              {data.resume?.objective}
            </p>
          ) : null}

          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 50 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #2f3847",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Work Experience
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #2f3847",
                  marginTop: 16,
                  paddingLeft: 16,
                }}
              >
                {data.resume?.experiences?.map((experience, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                        color: "#2f3847",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 0,
                          width: 16,
                          height: 16,
                          display: "inline-block",
                          border: "2px solid #2f3847",
                          borderRadius: 50,
                          verticalAlign: "top",
                          marginLeft: "-25px",
                          marginRight: 6,
                          backgroundColor: "#fff",
                        }}
                      >
                        Icon
                      </span>
                      {experience.position}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 12px",
                      }}
                    >
                      {index === 0 ? (
                        <>
                          {`${experience.companyName} | ${experience.startDate} - `}
                          <strong style={{ fontWeight: "bold" }}>
                            {experience.endDate !== null
                              ? experience.endDate
                              : "Present"}
                          </strong>
                        </>
                      ) : (
                        <>{`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}</>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 50 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #2f3847",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Projects
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.projects?.map((project, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                        color: "#2f3847",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                        color: "#707070",
                        fontSize: 14,
                        margin: "8px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande'",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume1;
