import React, { useEffect } from "react";
import GRAPHIC from "../../../Images/education-details.svg";
import {
  Button,
  CustomRadio,
  FileUpload,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import { AuthLayout } from "../../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  checkSaveEducation,
  fetchActions,
  getEducation,
  saveEducation,
} from "../../../Services/Service";
import { EDIT_EDUCATION, PROFILE } from "../../../RouteConstant";
import { remove, uploadPublic } from "../../../Services/FileUploadService";
import { ASSET_URL, CourseType, getYears } from "../../../constant";
import { educationSchema } from "./validationSchema";
import handleToast, { success } from "../../../util/Toast";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <>
      <Text sx={colorObj} variant="body1">
        Share your{" "}
        <Text
          sx={{ ...colorObj, fontWeight: 600 }}
          variant="body2"
          component="span"
        >
          education details
        </Text>{" "}
        to make your profile{" "}
        <Text
          variant="body2"
          sx={{ ...colorObj, fontWeight: 600 }}
          component="span"
        >
          stronger
        </Text>{" "}
        for the compitition.
      </Text>

      <Text sx={{ ...colorObj, mt: 1 }} variant="body1">
        Dont forget to get these verified as well.
      </Text>
    </>
  ),
};

const educationID = 12;
const mediumID = 14;
const boardId = 13;
const gradingId = 17;
const graduationId = 15;
const specialisationId = 16;
const courseTypeId = 18;
const Education = () => {
  const [initialValues, setinitialValues] = React.useState<any>({
    education_type: "",
    board: "",
    medium: "",
    course_type: "",
    passing_to_year: "",
    passing_from_year: "",
    specialisation: "",
    graduation: "",
    grading_systems: "",
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [educationType, setEducationType] = React.useState([]);
  const [medium, setMedium] = React.useState([]);
  const [board, setBoard] = React.useState([]);
  const [startYears, setStartYears] = React.useState<any>([]);
  const [endYears, setEndYears] = React.useState<any>([]);
  const [gradingType, setGradingType] = React.useState([]);
  const [graduation, setGraduation] = React.useState([]);
  const [specialisation, setSpecialisation] = React.useState([]);
  const [courseType, setCourseType] = React.useState([]);
  const [ education,setEducation] = React.useState();
  useEffect(() => {
    if (id) {
      getEducation(id, (res: any) => {
        setinitialValues(res.education);
      });
    }
  }, [id]);

  useEffect(() => {
    fetchActions({ id: educationID }, (response: any) => {
      if (response.success) {
        const tempEducation = response.options.map((option: any) => {
          return {
            value: option.id,
            text: option.value,
          };
        });
        setEducationType(tempEducation);
      }
    });
    const tempYears = getYears();
    setStartYears(tempYears);
    setEndYears(tempYears);
  }, []);

  useEffect(()=>{
    if (education || id) {
      fetchActions({ id: mediumID }, (response: any) => {
        if (response.success) {
          const tempMedium = response.options.map((option: any) => {
            return {
              value: option.id,
              text: option.value,
            };
          });
          setMedium(tempMedium);
        }
      });
      fetchActions({ id: boardId }, (response: any) => {
        if (response.success) {
          const tempBoard = response.options.map((option: any) => {
            return {
              value: option.id,
              text: option.value,
            };
          });
          setBoard(tempBoard);
        }
      });
      fetchActions({ id: gradingId }, (response: any) => {
        if (response.success) {
          const tempBoard = response.options.map((option: any) => {
            return {
              value: option.id,
              text: option.value,
            };
          });
          setGradingType(tempBoard);
        }
      });
      fetchActions({ id: graduationId }, (response: any) => {
        if (response.success) {
          const tempBoard = response.options.map((option: any) => {
            return {
              value: option.id,
              text: option.value,
            };
          });
          setGraduation(tempBoard);
        }
      });
      fetchActions({ id: specialisationId }, (response: any) => {
        if (response.success) {
          const tempBoard = response.options.map((option: any) => {
            return {
              value: option.id,
              text: option.value,
            };
          });
          setSpecialisation(tempBoard);
        }
      });
      fetchActions({ id: courseTypeId }, (response: any) => {
        if (response.success) {
          const tempBoard = response.options.map((option: any) => {
            return {
              value: option.id,
              label: option.value,
            };
          });
          setCourseType(tempBoard);
        }
      });
    }
  },[education, id])
  const onSuccessCheck = (res: any) => {
    if (res.success) {
      navigate(EDIT_EDUCATION.replace(":id", res.edu_id));
    }
  };
  const onChangeEducation = (values: any) => {
    checkSaveEducation(values, onSuccessCheck);
    setEducation(values)
  };
  const handleSubmit = (values: any) => {
    const payload: any = {};
    if (id) {
      payload.job_seeker_education_id = parseInt(id);
    }
    payload.education_type = values.education_type;
    if (values.school_medium) {
      payload.school_medium = values.school_medium;
    }
    if (values.board) {
      payload.board = values.board;
    }
    payload.university = values.university;
    payload.passing_from_year = values.passing_from_year;
    if (values.passing_to_year) {
      payload.passing_to_year = values.passing_to_year;
    }
    payload.grading_systems = values.grading_systems;
    payload.grading_value = values.grading_value;
    if (values.marksheet) {
      payload.marksheet = values.marksheet;
    }
    if (values.certificate) {
      payload.certificate = values.certificate;
    }
    if (values.graduation) {
      payload.graduation = values.graduation;
    }
    if (values.specialisation) {
      payload.specialisation = values.specialisation;
    }
    if (values.course_type) {
      payload.course_type = parseInt(values.course_type);
    }
    return new Promise((res: any) => {
      saveEducation(
        payload,
        (response: any) => {
          handleToast(response.message, success);
          res("");
          navigate(PROFILE);
        },
        (error: any) => {
          res("");
        }
      );
    });
  };
  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Educational Details
      </Text>
      <Form
        validateOnMount={true}
        validationSchema={educationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({
          isValid,
          values,
          handleChange,
          submitForm,
          isSubmitting,
        }: any) => {
          return (
            <>
              <SelectBox
                required
                sx={{ mb: 3 }}
                items={educationType}
                onChange={onChangeEducation}
                label="Education"
                name="education_type"
              />
              {values.education_type &&
                (values.education_type === CourseType.TENTH ||
                values.education_type === CourseType.TWELTH ||
                values.education_type === CourseType.BELOW10 ? (
                  <>
                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="school_medium"
                      items={medium}
                      label="Medium"
                      name="school_medium"
                    />
                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="board"
                      items={board}
                      label="Board"
                      name="board"
                    />

                    <InputBox
                      fullWidth
                      sx={{ mb: 3 }}
                      placeholder="Enter here.."
                      onChange={handleChange}
                      label="School Name"
                      required
                      name="university"
                    />

                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="passing_from_year"
                      items={startYears}
                      label="Start year"
                      name="passing_from_year"
                    />

                    <Box display="flex" columnGap={3}>
                      <SelectBox
                        required
                        id="grading_systems"
                        items={gradingType}
                        label="Grading type"
                        name="grading_systems"
                      />

                      <InputBox
                        fullWidth
                        placeholder="Enter here.."
                        onChange={handleChange}
                        label="Value"
                        required
                        name="grading_value"
                      />
                    </Box>
                    <Box
                      flexGrow={1}
                      p={3}
                      sx={{
                        "& .row + div": {
                          mt: 2,
                          "& > div": {
                            margin: 0,
                            gap: "1rem",
                            width: "100%",
                            "& svg": {
                              width: "1.5rem",
                              height: "1.5rem",
                            },
                            "& > div": {
                              flexGrow: 1,
                              "& .card-title": {},

                              "& p": {
                                margin: 0,
                              },
                            },
                          },
                        },
                        "& .col-md-2": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        },
                        "& .custom-file label": {
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed #3047EC",
                          cursor: "pointer",
                          background: "#FFF",
                          height: "48px",
                        },
                      }}
                    >
                      <FileUpload
                        name={"marksheet"}
                        upload={uploadPublic}
                        accept={[
                          "application/pdf",
                          "application/doc",
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                        type="single"
                        maxSize="10mb"
                        assetUrl={ASSET_URL}
                        label="Upload Marksheet"
                        onDelete={remove}
                      />
                    </Box>
                    <Box
                      flexGrow={1}
                      p={3}
                      sx={{
                        "& .row + div": {
                          mt: 2,
                          "& > div": {
                            margin: 0,
                            gap: "1rem",
                            width: "100%",
                            "& svg": {
                              width: "1.5rem",
                              height: "1.5rem",
                            },
                            "& > div": {
                              flexGrow: 1,
                              "& .card-title": {},

                              "& p": {
                                margin: 0,
                              },
                            },
                          },
                        },
                        "& .col-md-2": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        },
                        "& .custom-file label": {
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed #3047EC",
                          cursor: "pointer",
                          background: "#FFF",
                          height: "48px",
                        },
                      }}
                    >
                      <FileUpload
                        name={"certificate"}
                        upload={uploadPublic}
                        accept={[
                          "application/pdf",
                          "application/doc",
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                        type="single"
                        maxSize="10mb"
                        assetUrl={ASSET_URL}
                        label="Upload Certificate"
                        onDelete={remove}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="graduation"
                      items={graduation}
                      label="Course Name"
                      name="graduation"
                    />
                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="specialisation"
                      items={specialisation}
                      label="Specialisation"
                      name="specialisation"
                    />

                    <InputBox
                      fullWidth
                      sx={{ mb: 3 }}
                      placeholder="Enter here.."
                      onChange={handleChange}
                      label="Institute Name"
                      required
                      name="university"
                    />

                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="passing_from_year"
                      items={startYears}
                      label="Start year"
                      name="passing_from_year"
                    />
                    <SelectBox
                      required
                      sx={{ mb: 3 }}
                      id="passing_to_year"
                      items={endYears}
                      label="End year"
                      name="passing_to_year"
                    />
                    <CustomRadio
                      sx={{ mb: 3 }}
                      label="Course Type"
                      name="course_type"
                      id="course_type"
                      items={courseType}
                      onChange={handleChange}
                    />

                    <Box display="flex" columnGap={3}>
                      <SelectBox
                        required
                        id="grading_systems"
                        items={gradingType}
                        label="Grading type"
                        name="grading_systems"
                      />

                      <InputBox
                        fullWidth
                        placeholder="Enter here.."
                        onChange={handleChange}
                        label="Value"
                        required
                        name="grading_value"
                      />
                    </Box>
                    <Box
                      flexGrow={1}
                      p={3}
                      sx={{
                        "& .row + div": {
                          mt: 2,
                          "& > div": {
                            margin: 0,
                            gap: "1rem",
                            width: "100%",
                            "& svg": {
                              width: "1.5rem",
                              height: "1.5rem",
                            },
                            "& > div": {
                              flexGrow: 1,
                              "& .card-title": {},

                              "& p": {
                                margin: 0,
                              },
                            },
                          },
                        },
                        "& .col-md-2": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        },
                        "& .custom-file label": {
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed #3047EC",
                          cursor: "pointer",
                          background: "#FFF",
                          height: "48px",
                        },
                      }}
                    >
                      <FileUpload
                        name={"marksheet"}
                        upload={uploadPublic}
                        accept={[
                          "application/pdf",
                          "application/doc",
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                        type="single"
                        maxSize="10mb"
                        assetUrl={ASSET_URL}
                        label="Upload Marksheet"
                      />
                    </Box>
                    <Box
                      flexGrow={1}
                      p={3}
                      sx={{
                        "& .row + div": {
                          mt: 2,
                          "& > div": {
                            margin: 0,
                            gap: "1rem",
                            width: "100%",
                            "& svg": {
                              width: "1.5rem",
                              height: "1.5rem",
                            },
                            "& > div": {
                              flexGrow: 1,
                              "& .card-title": {},

                              "& p": {
                                margin: 0,
                              },
                            },
                          },
                        },
                        "& .col-md-2": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        },
                        "& .custom-file label": {
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed #3047EC",
                          cursor: "pointer",
                          background: "#FFF",
                          height: "48px",
                        },
                      }}
                    >
                      <FileUpload
                        name={"certificate"}
                        upload={uploadPublic}
                        accept={[
                          "application/pdf",
                          "application/doc",
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                        type="single"
                        maxSize="10mb"
                        assetUrl={ASSET_URL}
                        label="Upload Certificate"
                      />
                    </Box>
                  </>
                ))}

              <Button
                disabled={!isValid || isSubmitting}
                onClick={isValid ? submitForm : () => {}}
                type="submit"
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default Education;
