import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/job.svg";
import {
  Button,
  CustomRadio,
  Form,
  SelectBox,
  Text,
  CheckBox,
} from "@lipihipi/ui-components";
import { FormHelperText, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchActions,
  getUserMe,
  saveJobInfo,
} from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { PROFILE } from "../../../RouteConstant";
import { AppContext } from "../../../App";
import { ActionType } from "../../../Reducer";
import { JobSchema } from "./validationSchema";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <Text sx={colorObj} variant="body1">
      People with{" "}
      <Text
        sx={{ ...colorObj, fontWeight: 600 }}
        variant="body2"
        component="span"
      >
        updated profile
      </Text>{" "}
      have{" "}
      <Text
        variant="body2"
        sx={{ ...colorObj, fontWeight: 600 }}
        component="span"
      >
        higher chances
      </Text>{" "}
      of being recogonised by Mentors and Recruiters.
    </Text>
  ),
};

const noticeId = 20;
const industryId = 10;
const functionalAreaId = 11;

const dummyArray = Array(100)
  ?.fill(undefined)
  ?.map((el, index) => {
    return {
      id: index.toString(),
      value: `${index}`,
      text: index.toString(),
    };
  });

const EditJob = () => {
  const navigate = useNavigate();
  const [noticePeriodList, setNoticePeriodList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [functionalAreaList, setFunctionalAreaList] = useState([]);
  const [lacs, setLacs] = useState<any>([]);
  const [thousand, setThousand] = useState<any>([]);
  const { state, dispatch } = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = useState<any>({
    industry: "",
    functional_area: "",
    notice_period: "",
    current_salary: "",
    current_salary_thousand: "",
    is_freelance: "",
    job_type: "",
    is_hide_salary: false,
  });

  useEffect(() => {
    setLacs(dummyArray.filter((el: any) => el.id > 0));
    setThousand(dummyArray.filter((el: any) => el.id % 5 === 0));
    if (state.user) {
      setInitialValues({
        industry: state?.user?.industry,
        is_freelance: state?.user?.is_freelance === 1 ? true : false,
        job_type: state?.user?.job_type,
        functional_area: state?.user?.functional_area,
        notice_period: state?.user?.notice_period,
        current_salary: state?.user?.current_salary,
        current_salary_thousand: state?.user?.current_salary_thousand,
        is_hide_salary: state?.user?.is_hide_salary,
      });
    }
  }, []);

  useEffect(() => {
    // FETCH NOTICE PERIOD LIST
    fetchActions({ id: noticeId }, (response: any) => {
      if (response.success) {
        setNoticePeriodList(() => {
          const updatedArr = response?.options?.map((option: any) => {
            return {
              value: option?.id,
              text: option?.value,
            };
          });
          return updatedArr;
        });
      }
    });

    // FETCH INDUSTRY LIST
    fetchActions({ id: industryId }, (response: any) => {
      if (response.success) {
        setIndustryList(() => {
          const updatedArr = response?.options?.map((option: any) => {
            return {
              value: `${option.id}`,
              text: option.value,
            };
          });
          return updatedArr;
        });
      }
    });

    // FETCH Functional Area
    fetchActions({ id: functionalAreaId }, (response: any) => {
      if (response.success) {
        setFunctionalAreaList(() => {
          const updatedArr = response?.options?.map((option: any) => {
            return {
              value: `${option.id}`,
              text: option.value,
            };
          });
          return updatedArr;
        });
      }
    });
  }, []);

  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Job Information
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={JobSchema}
        onSubmit={(values: any) => {
          saveJobInfo(values, (response: any) => {
            getUserMe(
              (data: any) => {
                dispatch({
                  type: ActionType.GET_USER_SUCCESS,
                  payload: data.user,
                });
                navigate(PROFILE);
              },
              (error: any) => {
                console.log(error);
              }
            );
            handleToast(response?.message, success);
          });
        }}
        render={({
          values,
          submitForm,
          handleChange,
          isSubmitting,
          isValid,
          errors,
          dirty
        }: any) => {
          return (
            <>
              <SelectBox
                sx={{ mb: 3 }}
                required
                fullWidth
                items={functionalAreaList}
                label="Functional Area"
                name="functional_area"
              />

              <SelectBox
                sx={{ mb: 3 }}
                required
                fullWidth
                items={industryList}
                label="Industry"
                name="industry"
              />
              <CheckBox
                onChange={handleChange}
                name="is_freelance"
                label="Freelance"
                checked={values.is_freelance}
              />
              <CustomRadio
                sx={{ mt: 3 }}
                label="Job Type"
                name="job_type"
                id="job_type"
                items={[
                  {
                    value: "1",
                    label: "Experience",
                  },
                  {
                    value: "0",
                    label: "Fresher",
                  },
                ]}
              />
              {(!values.job_type || values.job_type === "1") && (
                <>
                  <FormHelperText sx={{ mb: 2 }}>
                    Last Drawn Salary (Per Year)
                  </FormHelperText>

                  <Grid columnSpacing={2} container>
                    <Grid item md={6}>
                      <SelectBox
                        sx={{ mb: 3 }}
                        required
                        fullWidth
                        items={lacs}
                        label="In Lacs"
                        name="current_salary"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SelectBox
                        sx={{ mb: 3 }}
                        required
                        fullWidth
                        items={thousand}
                        label="In Thousand"
                        name="current_salary_thousand"
                      />
                    </Grid>
                  </Grid>
                  <CheckBox
                    onChange={handleChange}
                    name="is_hide_salary"
                    label="Hide my salary"
                    checked={values.is_hide_salary}
                  />
                  <SelectBox
                    sx={{ mt: 0 }}
                    required
                    fullWidth
                    items={noticePeriodList}
                    label="Current Notice Period"
                    name="notice_period"
                  />
                </>
              )}

              <Button
                fullWidth
                sx={{ mt: 5 }}
                size="large"
                disabled={!isValid || isSubmitting || !dirty}
                onClick={isValid ? submitForm : () => {}}
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default EditJob;
