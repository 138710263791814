import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume7: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td>
          <div
            style={{
              backgroundColor: "#FBEDCD",
              padding: 15,
              textAlign: "center",
            }}
          >
            <div style={{ marginTop: 0, lineHeight: 1 }}>
              <div
                style={{
                  paddingRight: 10,
                  marginRight: 10,
                  display: "inline-block",
                  textAlign: "left",
                  borderRight: "1px solid #E9A506",
                }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#000",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div
                style={{
                  marginTop: 0,
                  marginRight: 10,
                  display: "inline-block",
                  textAlign: "left",
                  paddingRight: 10,
                  borderRight: "1px solid #E9A506",
                }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#000",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div
                style={{
                  marginTop: 0,
                  display: "inline-block",
                  textAlign: "left",
                  paddingRight: 0,
                }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    color: "#000",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          <h1
            style={{
              textAlign: "center",
              borderTop: "2px solid #E9A506",
              padding: "30px 0",
              lineHeight: "normal",
              margin: 0,
              fontSize: 42,
              textTransform: "uppercase",
              fontFamily:
                "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontFamily:
                  "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
              }}
            >
              {data.resume?.firstName}{" "}
            </span>
            {data.resume?.lastName}
          </h1>
          {data.resume?.objective?.length ? (
            <div>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Professional Summary
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 16,
                      lineHeight: "170%",
                      color: "#707070",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    {data.resume?.objective}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.experiences?.length && (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Work Experience
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  {data.resume?.experiences?.map((experience) => (
                    <div>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#000",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {experience.position}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "6px 0 12px",
                        }}
                      >
                        {`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Education
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  {data.resume?.educations?.map(
                    (education: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginTop: index === 0 ? 0 : 16 }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            fontFamily:
                              "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            color: "#000",
                            fontSize: 16,
                            textTransform: "capitalize",
                            margin: 0,
                          }}
                        >
                          {education.educationType.toLowerCase()}
                        </p>
                        <p
                          style={{
                            fontWeight: 600,
                            fontFamily:
                              "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            color: "#000",
                            fontSize: 16,
                            textTransform: "capitalize",
                            margin: 0,
                          }}
                        >
                          {education.schoolName.toLowerCase()}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            fontFamily: "Arial, Helvetica, sans-serif",
                            color: "#707070",
                            fontSize: 14,
                            margin: "6px 0 12px",
                          }}
                        >
                          <span>Percentage:</span> {education.gradingValue}{" "}
                          {education.gradingSystems === "Percentage"
                            ? "%"
                            : "CGPA"}{" "}
                          | <span>Year:</span> {education.passedYear}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Expertise
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.skills?.map((skill, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 12,
                          marginTop: 8,
                          marginLeft: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {skill.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Hobbies
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.hobbies?.map((hobby, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 12,
                          marginTop: 8,
                          marginLeft: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {hobby}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Language
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.languageKnown.map((language, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 12,
                          marginTop: 8,
                          marginLeft: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {language}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    borderTop: "6px solid #FBEDCD",
                    display: "inline-block",
                    width: "25%",
                  }}
                />
                <div style={{ width: "75%", display: "inline-block" }} />
              </div>
              <div style={{ fontSize: 0, borderTop: "2px solid #E9A506" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "15px 15px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textTransform: "uppercase",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Projects
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "15px 15px 0 0",
                  }}
                >
                  {data.resume?.projects?.map((project, index) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#000",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {project?.projectName}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "8px 0 12px",
                        }}
                      >
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Technology:
                        </span>
                        {project?.technology} |
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Team Size:
                        </span>
                        {project?.teamSize} |
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Year:
                        </span>
                        {project?.year}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "6px 0 0",
                          lineHeight: "170%",
                        }}
                      >
                        {project?.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume7;
