import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import employer from "../../Images/employer.svg";
import { SecondaryLayout } from "../../Components";
import { Button, Card, Text } from "@lipihipi/ui-components";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import vars from "../../theme/variables";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BadgeIcon from "@mui/icons-material/Badge";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { useParams } from "react-router-dom";
import { getJobDetail, jobApply, saveJob } from "../../Services/Service";
import { Link } from "react-router-dom";
import { ASSET_URL } from "../../constant";
import handleToast, { success } from "../../util/Toast";
import moment from "moment";
import { Clock, Location, Salary, ShortDesc } from "../../Icons";

const { primaryFont } = vars;

const JobDetails = () => {
  const [jobData, setJobData] = useState<any>(null);
  const [toggleData, setToggleData] = useState(false);
  const { jobId } = useParams();
  const [buttonText, setButtonText] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
  };

  const handleJobApply = () => {
    const payload = {
      id: jobId,
      action: 1,
    };
    jobApply(payload, (response: any) => {
      handleToast(response.message, success);
      setToggleData((prev) => !prev);
    });
  };

  const getButtonText = () => {
    switch (jobData?.jobDetails?.action) {
      case 0:
        setButtonText("Apply Now");
        break;
      case 1:
        setButtonText("Applied");
        break;
      case 2:
        setButtonText("Shortlisted");
        break;
      case 3:
        setButtonText("Rejected");
        break;
      default:
        setButtonText("Apply Now");
    }
  };

  useEffect(() => {
    getJobDetail(jobId, (response: any) => {
      if (response?.status) {
        setJobData(response?.data);
      }
    });
  }, [toggleData]);

  useEffect(() => {
    if (jobData) {
      getButtonText();
    }
  }, [jobData]);

  const leftPanel = (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box>
        <Box
          textAlign="center"
          py={3}
          px={2}
          sx={{
            borderRadius: "8px 8px 0 0",
            backgroundColor: "#EBF4FF",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            mx="auto"
            mb={2}
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${ASSET_URL}/${jobData?.jobDetails?.company_id?.company_logo})`,
              height: "150px",
              border: "6px solid #FFFFFF",
              backgroundColor: "#EBF4FF",
              borderRadius: "50%",
              width: "150px",
            }}
          ></Box>
          <Text variant="h3">
            {jobData?.jobDetails?.company_id?.company_name}
          </Text>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#FDFDFD",
            boxShadow: "0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <List>
            <ListItem alignItems="flex-start">
              <ListItemText
                sx={{
                  textAlign: "center",
                  "& .MuiListItemText-secondary": {
                    marginTop: 1,
                  },
                }}
                primary="Employee Strength"
                secondary={
                  jobData?.jobDetails?.company_id?.company_emp_strength
                }
              />
            </ListItem>
            <Divider
              sx={{ mx: "auto", width: "calc(100% - 80px)" }}
              variant="inset"
              component="li"
            />

            <ListItem alignItems="flex-start">
              <ListItemText
                sx={{
                  textAlign: "center",
                  "& .MuiListItemText-secondary": {
                    marginTop: 1,
                  },
                }}
                primary="Establishment Year"
                secondary={
                  jobData?.jobDetails?.company_id?.company_year_establish
                }
              />
            </ListItem>
            <Divider
              sx={{ mx: "auto", width: "calc(100% - 80px)" }}
              variant="inset"
              component="li"
            />

            <ListItem alignItems="flex-start">
              <ListItemText
                sx={{
                  textAlign: "center",
                  "& .MuiListItemText-secondary": {
                    marginTop: 1,
                    "& a": {
                      color: "rgba(0, 0, 0, 0.6)",
                    },
                  },
                }}
                primary="Website"
                secondary={
                  <Link
                    to={jobData?.jobDetails?.company_id?.company_url}
                    target="_blank"
                  >
                    {jobData?.jobDetails?.company_id?.company_url}
                  </Link>
                }
              />
            </ListItem>
            <Divider
              sx={{ mx: "auto", width: "calc(100% - 80px)" }}
              variant="inset"
              component="li"
            />

            <ListItem alignItems="flex-start">
              <ListItemText
                sx={{
                  textAlign: "center",
                  "& .MuiListItemText-secondary": {
                    marginTop: 1,
                  },
                }}
                primary="Corporate address"
                secondary={jobData?.jobDetails?.company_id?.company_addr}
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      {jobData && jobData?.benefits?.length > 0 && (
        <Card sx={{ p: 0, mb: 1 }} disableHeader>
          <Text sx={{ px: 2, pt: 2 }} variant="subtitle2">
            Benefits
          </Text>

          <List>
            {jobData?.benefits?.map((benefit: any, index: any) => (
              <>
                {index !== 0 && <Divider variant="inset" component="li" />}
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar
                      src={`https://lipijobs.com/images/${benefit.benefit_image}`}
                      sx={{
                        fontFamily: primaryFont,
                        color: "#00c385",
                        backgroundColor: "#e0faf1",

                        "& img": {
                          maxWidth: "50%",
                          maxHeight: "50%",
                          backgroundSize: "contain",
                        },
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={benefit.benefit_name} />
                </ListItem>
              </>
            ))}
          </List>
        </Card>
      )}
    </Box>
  );

  const rightPanel = (
    <Box display="flex" flexDirection="column" rowGap={2}>
      {jobData?.galleries?.length > 0 && (
        <Card
          sx={{ p: 0, textAlign: "center", overflow: "hidden" }}
          disableHeader
        >
          <Text sx={{ p: 2 }} variant="subtitle2">
            Gallery
          </Text>

          <Slider {...settings}>
            {jobData?.galleries?.map((gallery: any) => (
              <img src={`${ASSET_URL}/${gallery?.gallery_images}`} alt="" />
            ))}
          </Slider>
        </Card>
      )}

      {jobData?.achivement?.length > 0 && (
        <Card sx={{ p: 0 }} disableHeader>
          <Text sx={{ px: 2, pt: 2 }} variant="subtitle2">
            Achievements
          </Text>

          <List>
            {jobData?.achivement?.map((achivement: any, index: any) => (
              <>
                {index !== 0 && <Divider variant="inset" component="li" />}
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        fontFamily: primaryFont,
                        color: "#00c385",
                        backgroundColor: "#e0faf1",
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={achivement.title}
                    secondary={achivement.description}
                  />
                </ListItem>
              </>
            ))}
          </List>

          {jobData?.achivement?.length > 5 && (
            <Box textAlign="center" px={2} pb={2}>
              <Button variant="outlined" color="secondary">
                View All
              </Button>
            </Box>
          )}
        </Card>
      )}
    </Box>
  );

  const handleBookmark = ({ id, alreadyApplied }: any) => {
    const payload = {
      id,
      save_for_later: !Boolean(alreadyApplied),
    };
    saveJob(payload, (response: any) => {
      if (response.success) {
        setToggleData((prev) => !prev);
      }
    });
  };

  return (
    <SecondaryLayout
      back
      leftPanel={leftPanel}
      rightPanel={
        jobData?.galleries?.length > 0 || jobData?.achivement?.length
          ? rightPanel
          : null
      }
      currentActive={1}
    >
      <Card sx={{mb: 2}} px={2} py={2} disableHeader>
        <Box display="flex">
          <Box
            pr={1}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            rowGap={1}
          >
            <Text
              sx={{ 
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#161458',
                fontWeight: 700 
              }}
              variant="subtitle2"
            >
              {jobData?.jobDetails?.job_title?.toLowerCase()}
            </Text>

            <Box
              display="flex"
              alignItems="center"
              columnGap={2}
            >
              <Text
                variant="caption"
                sx={{
                  textTransform: "capitalize",
                  display: "flex",
                  fontSize: '12px',
                  color: '#9C9B9B',
                  alignItems: "end",
                  columnGap: 1,
                }}
              >
                {jobData?.jobDetails?.company_id?.company_name?.toLowerCase()}
              </Text>
            </Box>






            <Box
              display="flex"
              flexDirection="column"
              rowGap={1.5}
            >
              <Box
                display="flex"
                alignItems="center"
                columnGap={1.5}
              >

                <Text
                  variant="caption"
                  sx={{
                    color: '#161458',
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                  }}
                >
                  <Clock />
                  {jobData?.jobDetails?.min_exp}+ years
                </Text>
                <Divider sx={{ height: '12px', width: '1px', background: '#D3D3D3'}} />
                <Text
                  variant="caption"
                  sx={{
                    color: '#161458',
                    display: "flex",
                    alignItems: "center",
                    columnGap: 0.5,
                  }}
                >
                  <Salary />
                  {!jobData?.jobDetails?.hide_salary
                    ? `${jobData?.jobDetails?.min_annual_salary}${
                        jobData?.jobDetails?.min_annual_salary_thousand
                          ? `.${jobData?.jobDetails?.min_annual_salary_thousand}`
                          : ".0"
                      } - ${jobData?.jobDetails?.max_annual_salary}${
                        jobData?.jobDetails?.max_annual_salary_thousand
                          ? `.${jobData?.jobDetails?.max_annual_salary_thousand}`
                          : ".0"
                      } LPA `
                    : "Not Disclosed"}
                </Text>

                <Divider sx={{ height: '12px', width: '1px', background: '#D3D3D3'}} />

                <Text
                  variant="caption"
                  sx={{
                    color: '#161458',
                    display: "flex",
                    alignItems: "center",
                    columnGap: 0.5,
                  }}
                >
                  <Location />
                  {jobData?.jobDetails?.job_locations}
                </Text>
              </Box>

              
              

              <Box
                display="flex"
                alignItems="center"
                columnGap={1}
              >
                {jobData?.jobDetails?.key_skills?.split(',')?.map((skill: any, index: any) => (
                  <>
                    {index !== 0 && <FiberManualRecordIcon sx={{ fontSize: 8, color: '#929191' }} />}
                    <Text
                      key={index}
                      variant="caption"
                      sx={{
                        fontSize: '11px',
                        color: '#929191'
                      }}
                    >
                      {skill}
                    </Text>
                  </>
                ))}
                
              </Box>
            </Box>
          </Box>

          <Box
            width={60}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={60}
            sx={{
              background: "#FFFFFF",
              border: "1px solid #F0F0F0",
              "& img": {
                maxWidth: "100%",
                objectFit: "contain",
                maxHeight: "100%",
              },
            }}
          >
            <img
              src={
                jobData?.jobDetails?.company_id?.company_logo
                  ? `${ASSET_URL}/${jobData?.jobDetails?.company_id?.company_logo}`
                  : employer
              }
              alt=""
            />
          </Box>
        </Box>

        <Box mt={1} display="flex" alignItems="center" justifyContent='space-between'>
          <Text sx={{ color: "#868D90" }} variant="caption">
            Posted <strong style={{color: '#2E2E2E', fontWeight: 600}}>{moment(jobData?.jobDetails?.created_at, "YYYYMMDD").fromNow()}</strong>{" "}
            - By <strong style={{color: '#2E2E2E', fontWeight: 600}}>{jobData?.jobDetails?.created_by}</strong>
          </Text>
          
          <Box display="flex" columnGap={2} alignItems="center">
          <Button
            disabled={
              jobData?.jobDetails?.action === 0 ||
              jobData?.jobDetails?.action === null
                ? false
                : true
            }
            onClick={handleJobApply}
          >
            {buttonText}
          </Button>

          <IconButton
            onClick={() =>
              handleBookmark({
                id: jobData?.jobDetails?.id,
                alreadyApplied: jobData?.jobDetails?.save_for_later,
              })
            }
            color={
              jobData?.jobDetails?.save_for_later === 1 ? "success" : "default"
            }
          >
            {jobData?.jobDetails?.save_for_later === 1 ? (
              <BookmarkRemoveIcon sx={{ fontSize: 20, color: "#00C385" }} />
            ) : (
              <BookmarkBorderIcon sx={{ fontSize: 20 }} />
            )}
          </IconButton>
        </Box>
          
        </Box>
      </Card>

      <Card px={2} sx={{
        '& *': {
          fontFamily: "'Epilogue',sans- serif !important"
        },
        mb: 1.5
      }} py={2} disableHeader>
        <Text
          sx={{
            fontSize: '12px',
            color: '#161458',
            lineHeight: '160%'
          }}
        >
          {jobData?.jobDetails?.short_description}
        </Text>

        

        {jobData?.jobDetails?.description && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Job Description</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.jobDetails?.description,
                }}
              />
            </Box>
          </Box>
        )}

        {jobData?.jobDetails?.must_have && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Must Have</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.jobDetails?.must_have,
                }}
              />
            </Box>
          </Box>
        )}

        {jobData?.jobDetails?.good_to_have && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Good To Have</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.jobDetails?.good_to_have,
                }}
              />
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="center" mt={5}>
          <Button
            sx={{ px: 8 }}
            disabled={
              jobData?.jobDetails?.action === 0 ||
              jobData?.jobDetails?.action === null
                ? false
                : true
            }
            onClick={handleJobApply}
          >
            {buttonText}
          </Button>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          columnGap={2}
          alignItems="center"
          mt={3}
        >
          <Text sx={{ color: "#868D90" }} variant="caption">
            Posted{" "}
            {moment(jobData?.jobDetails?.created_at, "YYYYMMDD").fromNow()} - By{" "}
            {jobData?.jobDetails?.company_id?.emp_fname}
          </Text>

          <IconButton
            onClick={() =>
              handleBookmark({
                id: jobData?.jobDetails?.id,
                alreadyApplied: jobData?.jobDetails?.save_for_later,
              })
            }
            color={
              jobData?.jobDetails?.save_for_later === 1 ? "success" : "default"
            }
          >
            {jobData?.jobDetails?.save_for_later === 1 ? (
              <BookmarkRemoveIcon sx={{ fontSize: 20, color: "#00C385" }} />
            ) : (
              <BookmarkBorderIcon sx={{ fontSize: 20 }} />
            )}
          </IconButton>
        </Box>
      </Card>
    </SecondaryLayout>
  );
};

export default JobDetails;
