import {
  Box,
  Container,
  Grid,
} from "@mui/material";
import { Back, Header, Icon, Text, useSmartPhone } from "@lipihipi/ui-components";

import BasicDetails from "./BasicDetails";
import JobDetails from "./Job";
import EducationDetails from "./Education";
import EmployerDetails from "./Employer";
import SkillsDetails from "./Skills";
import HobbyDetails from "./Hobby";
import ProjectDetails from "./Project";
import SelfIntroduction from "./SelfIntroduction";
import UploadResume from "./UploadResume";
import ProfilePicture from "./ProfilePicture";
import { CONTAINER_MAX_WIDTH } from "../../constant";
import { DropDown } from "./Dropdown";

import { useEffect, useState } from "react";
import { getProfile } from "../../Services/Service";
import { onFailure } from "../../util/helper";
import ReferToFriends from "../../Shared/ReferToFriends";
import Wallet from "../../Shared/Wallet";
import { Logo } from "../../Icons";
import { MY_JOBS } from "../../RouteConstant";
import { useNavigate } from "react-router-dom";

import Strength from "./Strength";



const Profile = () => {
  const isSmartPhone = useSmartPhone();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [toggle, setToggle] = useState(false);
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    getProfile(onSuccess, onFailure);
  }, [toggle]);
  const onSuccess = (res: any) => {
    if (res.success) {
      setData(res.data);
    }
  };

  
  return (
    <>
      <Header
        maxWidth={CONTAINER_MAX_WIDTH}
        logo={<Logo style={{ cursor: "pointer" }} />}
      >
        {parseInt(data?.profile?.profile_strength) >= 80 && (
          <Text
            onClick={() => navigate(MY_JOBS)}
            sx={{ "padding-right": "20px",cursor:"pointer" }}
          >
            Home
          </Text>
        )}
        <DropDown />
      </Header>

      <Box py={2.5}>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          {parseInt(data?.profile?.profile_strength) >= 80 ? (
            <Back
              onClick={() => navigate(MY_JOBS)}
              back={{
                label: "Go to Dashboard",
                icon: <Icon.BackIcon style={{ marginRight: 8 }} />,
              }}
            />
          ) : (
            <Back onClick={handleBack} />
          )}
          {(data?.profile?.job_type === "0" &&
            parseInt(data?.profile?.profile_strength) <= 75) ||
            (parseInt(data?.profile?.profile_strength) < 100 && (
              <Box mt={2.5}>
                <Text>
                  Your Profile is Incomplete. Please fill all details to proceed
                </Text>
              </Box>
            ))}
          <Box mt={2.5}>
            <Grid alignItems="flex-start" container spacing={2.5}>
              <Grid
                flexDirection="column"
                display="flex"
                rowGap={2.5}
                item
                xs={12}
                lg={9}
              >
                {isSmartPhone && (
                  <Strength data={data} />
                )}
                
                <ProfilePicture setToggle={setToggle} profile={data?.profile} />

                <BasicDetails profileDetail={data?.profile} />

                <JobDetails profileDetail={data?.profile} />

                <EducationDetails
                  setToggle={setToggle}
                  educations={data?.education}
                />

                <EmployerDetails
                  setToggle={setToggle}
                  employments={data?.employment}
                />

                <SkillsDetails skills={data?.profile?.skills} />

                <HobbyDetails hobbies={data?.profile?.hobbies} />

                <ProjectDetails
                  setToggle={setToggle}
                  projects={data?.project}
                />

                <SelfIntroduction about={data?.profile?.about_me} />

                <UploadResume
                  setToggle={setToggle}
                  url={data?.profile?.resume_name}
                  name={data?.profile?.resume_original_name}
                  date={data?.profile?.resume_uploaded_date}
                />
              </Grid>
              <Grid position="sticky" top={90} item xs={12} lg={3}>
                <Box display="flex" flexDirection="column" rowGap={1.5}>
                  {!isSmartPhone && (
                    <Strength data={data} />
                  )}

                  <Wallet
                    walletAmount={
                      data?.walletDetails?.balance
                        ? data?.walletDetails?.balance
                        : 0
                    }
                  />

                  {/* <Card disableHeader sx={{ textAlign: "center" }}>
                    <WalletIcon />
                    <Text sx={{ mb: 0.5, mt: 1.5 }}>My Wallet Balance</Text>
                    <Text variant="h1">
                      Rs.
                      {data?.walletDetails ? data?.walletDetails?.balance : 0}
                    </Text>
                  </Card> */}

                  <ReferToFriends />

                  {/* <Card disableHeader>
                    <Box
                      display='flex'
                      alignItems='center'
                      columnGap={3}
                    >
                      <Box
                        borderRadius={100}
                        width={44}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height={44}
                        sx={{
                          background: '#E0FAF1'
                        }}
                      >
                        <VideoIcon />
                      </Box>
                      <Box>
                        <Text>
                          Videos
                        </Text>
                        <Text variant="h2">
                          255
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      mt={3.5}
                      display='flex'
                      alignItems='center'
                      columnGap={3}
                    >
                      <Box
                        borderRadius={100}
                        width={44}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height={44}
                        sx={{
                          background: '#E0FAF1'
                        }}
                      >
                        <LikeIcon />
                      </Box>
                      <Box>
                        <Text>
                          Like
                        </Text>
                        <Text variant="h2">
                          22,00,872
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      mt={3.5}
                      display='flex'
                      alignItems='center'
                      columnGap={3}
                    >
                      <Box
                        borderRadius={100}
                        width={44}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height={44}
                        sx={{
                          background: '#E0FAF1'
                        }}
                      >
                        <RatingIcon />
                      </Box>
                      <Box>
                        <Text>
                          Ratings
                        </Text>
                        <Text variant="h2">
                          4.74
                        </Text>
                      </Box>
                    </Box>
                  </Card> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Profile;
