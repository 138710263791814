import {
  Button,
  InfoRow,
  Card,
  DefaultInfoCard,
  Text,
  useSmartPhone,
} from "@lipihipi/ui-components";
import { EditIcon, JobIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { JOB } from "../../../RouteConstant";

const JobDetails = ({ profileDetail }: any) => {
  const navigate = useNavigate();
  const isSmartPhone = useSmartPhone();
  const breakpoints = isSmartPhone ? [{ xs: 5 }, { xs: 7 }] : [{ xs: 3 }, { xs: 9 }]

  if (!profileDetail?.functional_area) {
    return (
      <DefaultInfoCard mandatory icon={<JobIcon />}>
        <Text>
          Your{" "}
          <Text sx={{ fontSize: "20px", fontWeight: "400" }} component="span">
            Job detail
          </Text>{" "}
          section is yet to complete
        </Text>

        <Text>Please do it now!</Text>
        <Button
          onClick={() => navigate(JOB)}
          color="primary"
          sx={{ mt: 3, mx: "auto" }}
          variant="outlined"
        >
          Add Now
        </Button>
      </DefaultInfoCard>
    );
  }
  return (
    <Card action={{
      icon: <EditIcon style={{ marginRight: 8 }} />,
      text: isSmartPhone ? 'Edit' : 'Edit Details'
    }} onClick={() => navigate(JOB)} heading="Job Details">
      <Box display="flex" flexDirection="column" rowGap={2}>
        {profileDetail?.job_type === "0" ? (
          <InfoRow breakPoints={breakpoints} heading="Experience" content="Fresher" />
        ) : (
          <InfoRow
          breakPoints={breakpoints}
            heading="Current Salary"
            content={`${profileDetail?.current_salary} Lakhs ${profileDetail?.current_salary_thousand} Thousand PA`}
          />
        )}

        <InfoRow breakPoints={breakpoints} heading="Industry" content={profileDetail?.industry?.value} />

        <InfoRow
        breakPoints={breakpoints}
          heading="Functional Area"
          content={profileDetail?.functional_area?.value}
        />
        {profileDetail?.job_type !== 0 && (
          <InfoRow
          breakPoints={breakpoints}
            heading="Notice period"
            content={profileDetail?.notice_period?.value ?? '--'}
          />
        )}
      </Box>
    </Card>
  );
};

export default JobDetails;
