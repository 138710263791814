import React from "react";
import { Card, InfoRow, Text, useSmartPhone } from "@lipihipi/ui-components";
import { EditIcon } from "../../../Icons";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BASIC_DETAILS } from "../../../RouteConstant";


const BasicDetails = ({profileDetail}:any) => {
  const navigate = useNavigate()
  const isSmartPhone = useSmartPhone();
  const breakpoints = isSmartPhone ? [{ xs: 5 }, { xs: 7 }] : [{ xs: 3 }, { xs: 9 }]
  return (
    <Card
      onClick={() => navigate(BASIC_DETAILS)}
      action={{
        icon: <EditIcon style={{ marginRight: 8 }} />,
        text: isSmartPhone ? 'Edit' : 'Edit Details'
      }}
      heading="Basic Details"
    >
      <Box
        display='flex'
        flexDirection='column'
        rowGap={2}
      >
        <InfoRow breakPoints={breakpoints} heading="Name" content={`${profileDetail?.user?.fname} ${profileDetail?.user?.lname}`} />

        <InfoRow breakPoints={breakpoints} heading="Mobile Number" content={profileDetail?.user?.contact_no} />

        <InfoRow breakPoints={breakpoints} heading="Email Address" sx={{
          '& .MuiTypography-root': {
            wordBreak: 'break-all'
          }
        }} content={profileDetail?.user?.email} />

        <InfoRow breakPoints={breakpoints} heading="Date of Birth" content={profileDetail?.dob ? profileDetail.dob :'-'} />

        <InfoRow breakPoints={breakpoints} heading="Blood Group" content={profileDetail?.blood_group ? profileDetail.blood_group :'-'} />

        <InfoRow breakPoints={breakpoints} heading="Address" content={`${profileDetail?.address_1 ? profileDetail?.address_1 :'-'} ${profileDetail?.address_2 ? profileDetail?.address_2 : ''}`} />

        <InfoRow breakPoints={breakpoints} heading="State/City" content={`${profileDetail?.state ? profileDetail?.state :'-'} ${profileDetail?.city ? `,${profileDetail?.city}` :''}`} />

        <InfoRow breakPoints={breakpoints} heading="Marital Status" content={profileDetail?.marital_status?profileDetail?.marital_status:'-'} />

        <InfoRow breakPoints={breakpoints} heading="Gender" content={profileDetail?.gender?profileDetail?.gender:''} />
      </Box>
    </Card>
  )
};

export default BasicDetails;