import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/hobby.svg";
import { Button, Chips, Form, InputBox, Text } from "@lipihipi/ui-components";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../../Icons";
import { useEffect, useState } from "react";
import handleToast, { success } from "../../../util/Toast";
import { fetchHobbies, saveHobbies } from "../../../Services/Service";
import { PROFILE } from "../../../RouteConstant";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <>
      <Text sx={colorObj} variant="body1">
        This brings
      </Text>
      <Text sx={{ ...colorObj, fontWeight: 600, mt: 0.5 }} variant="body2">
        happiness and motivation in me
      </Text>
    </>
  ),
};

const EditHobbyDetails = () => {
  const navigate = useNavigate();
  const [hobbyExist, setHobbyExist] = useState(false);
  const [hobby, setHobby] = useState<any>([]);

  useEffect(() => {
    fetchHobbies((response: any) => {
      if(response.hobbies.hobbies){
        setHobby(response?.hobbies?.hobbies?.split(", "));
      }
    });
  }, []);

  const handleSubmit = () => {
    saveHobbies({ hobbies: hobby?.join(", ") }, (response: any) => {
      if (response?.success) {
        handleToast(response?.message, success);
        navigate(PROFILE);
      }
    });
  };

  const handleDelete = (skill: any) => {
    setHobby((prevSkills: any) => {
      return prevSkills?.filter((prev: any) => prev !== skill);
    });
  };
  const handleKeyDown = (e: any,values:any,resetForm:any) => {
    if (e.key === ",") {
      e.preventDefault();
      if (hobby.includes(values.skill)) {
        setHobbyExist(true);
      } else {
        setHobby((prevSkill: any) => {
          return [...prevSkill, values.skill];
        });
        setHobbyExist(false);
        resetForm()
      }
    }
  };
  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Please enter your Hobbies and Interests
      </Text>

      <Box display="flex" gap={1.5} flexWrap="wrap" mb={5}>
        {hobby.map((skill: any) => (
          <Chips
            deleteIcon={
              <IconButton size="small" color="primary">
                <CrossIcon />
              </IconButton>
            }
            onDelete={() => handleDelete(skill)}
            label={skill}
          />
        ))}
      </Box>

      <Form
        initialValues={{ skill: "" }}
        onSubmit={(values: any, { resetForm }: any) => {
          if (values.skill.trim().length > 0) {
            if (hobby.includes(values.skill)) {
              setHobbyExist(true);
            } else {
              setHobby((prevSkill: any) => {
                return [...prevSkill, values.skill];
              });
              setHobbyExist(false);
              resetForm();
            }
          }
        }}
        render={({ values, handleChange ,resetForm}: any) => {
          return (
            <InputBox
              error={hobbyExist}
              fullWidth
              name="skill"
              value={values.skill}
              sx={{ mb: 3 }}
              placeholder="Enter here.."
              onChange={handleChange}
              helperText={
                hobbyExist
                  ? "Hobby Already Exist!"
                  : "You can add multiple hobbies and interests by enter"
              }
              label="Enter hobbies and interests here"
              onKeyDown={(e)=>{
                handleKeyDown(e,values,resetForm)
              }}
            />
          );
        }}
      />

      <Button
        onClick={handleSubmit}
        fullWidth
        disabled={hobby?.length === 0}
        sx={{ mt: 5 }}
        size="large"
      >
        Save
      </Button>
    </AuthLayout>
  );
};

export default EditHobbyDetails;
