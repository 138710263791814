import {
  useRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { routes } from "./routes";
import { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";
import { ActionType, reducer } from "./Reducer";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { UserToken, getUserMe } from "./Services/Service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./editor-data.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const initialState = {
  state: {
    page_loading: true,
  },
  dispatch: () => {},
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

function Main() {
  const { dispatch } = useContext<any>(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const onUserSuccess = (data: any) => {
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: data.user,
    });
    if (location) {
      navigate(location.pathname);
    }
  };

  const userFailure = (resp: any) => {
    // console.log(resp);
    dispatch({
      type: ActionType.SET_LOADING_FLAG,
      payload: false,
    });
  };

  useEffect(() => {
    // auto login user if token exists
    if (UserToken()) {
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: true,
      });
      getUserMe(onUserSuccess, userFailure);
    } else {
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: false,
      });
    }
  }, []);

  const routing = useRoutes(routes);

  return <>{routing}</>;
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState.state);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main />
        <ToastContainer />
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
