import {
  Button,
  PrimaryList,
  Card,
  DefaultInfoCard,
  Text,
  ConfirmationModal,
  useSmartPhone,
} from "@lipihipi/ui-components";
import { EmployerIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import employer from "../../../Images/employer.svg";
import moment from "moment";
import { EDIT_EXPERIENCE, EXPERIENCE } from "../../../RouteConstant";
import { deleteExperience } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const EmployerDetails = ({ employments, setToggle }: any) => {
  const isSmartPhone = useSmartPhone();
  const navigate = useNavigate();
  const selected = useRef<Record<string, any> | null>(null);
  const [open, setOpen] = useState(false);

  if (employments?.length === 0) {
    return (
      <DefaultInfoCard icon={<EmployerIcon />}>
        <Text>Dont forget to mention Who really valued you!</Text>
        <Text
          sx={{
            color: "#292929",
          }}
        >
          mention{" "}
          <Text sx={{ fontSize: "20px", fontWeight: "400" }} component="span">
            Employer details.
          </Text>
        </Text>
        <Button
          onClick={() => navigate(EXPERIENCE)}
          color="primary"
          sx={{ mt: 3, mx: "auto" }}
          variant="outlined"
        >
          Add Now
        </Button>
      </DefaultInfoCard>
    );
  }
  const handleDelete = () => {
    deleteExperience(selected?.current?.id, (response: any) => {
      handleToast(response.message, success);
      setTimeout(() => setToggle((prev: any) => !prev));
    });
  };
  return (
    <Card
      action={{
        icon: null,
        text: isSmartPhone ? 'Add' : "Add Employment",
      }}
      onClick={() => navigate(EXPERIENCE)}
      heading="Employment Details"
    >
      {employments &&
        employments?.map((employment: any) => {
          return (
            <PrimaryList
            isSecondary={isSmartPhone ? true : false}
              icon={<img src={employer} alt="" />}
              onClickEdit={() =>
                navigate(EDIT_EXPERIENCE.replace(":id", employment?.id))
              }
              onClickDelete={() => {
                selected.current = employment;
                setOpen(true);
              }}
              chip={{
                color: "warning",
                label: "Not Verified",
              }}
              data={{
                heading: employment?.designation,
                primaryText: employment?.company_name,
                secondaryText: `${moment(employment?.start_date).format(
                  "MMM-Y"
                )} ${
                  employment?.end_date
                    ? `-${moment(new Date(employment?.end_date)).format(
                        "MMM-Y"
                      )}`
                    : " to Present"
                }`,
              }}
              additionalText={employment?.description}
            />
          );
        })}
      <ConfirmationModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={handleDelete}
        title={"Are you sure want to delete employment?"}
      />
    </Card>
  );
};

export default EmployerDetails;
