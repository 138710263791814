import * as Yup from "yup";
import { CourseType, GradingType } from "../../../constant";

export const educationSchema = Yup.object().shape({
  education_type: Yup.string().required("Education type is required"),
  school_medium: Yup.string().when("education_type", {
    is: (val: any) => {
      return (
        parseInt(val) === CourseType.BELOW10 ||
        parseInt(val) === CourseType.TENTH ||
        parseInt(val) === CourseType.TWELTH
      );
    },
    then: (educationSchema) =>
      educationSchema.required("Medium is required").nullable(),
    otherwise: (educationSchema) => educationSchema.optional().nullable(),
  }),
  board: Yup.string().when("education_type", {
    is: (val: any) => {
      return (
        parseInt(val) === CourseType.BELOW10 ||
        parseInt(val) === CourseType.TENTH ||
        parseInt(val) === CourseType.TWELTH
      );
    },
    then: (educationSchema) =>
      educationSchema.required("Board is required").nullable(),
    otherwise: (educationSchema) => educationSchema.optional().nullable(),
  }),
  graduation: Yup.string().when("education_type", {
    is: (val: any) =>
      parseInt(val) === CourseType.GRADUATION ||
      parseInt(val) === CourseType.MASTER ||
      parseInt(val) === CourseType.DOCTORATE,
    then: (educationSchema) =>
      educationSchema.required("Graduation is required").nullable(),
    otherwise: (educationSchema) => educationSchema.optional().nullable(),
  }),
  specialisation: Yup.string().when("education_type", {
    is: (val: any) =>
      parseInt(val) === CourseType.GRADUATION ||
      parseInt(val) === CourseType.MASTER ||
      parseInt(val) === CourseType.DOCTORATE,
    then: (educationSchema) =>
      educationSchema.required("Specialisation is required").nullable(),
    otherwise: (educationSchema) => educationSchema.optional().nullable(),
  }),
  passing_from_year: Yup.number().required("Passing from year is required"),
  passing_to_year: Yup.number()
    .min(
      Yup.ref("passing_from_year"),
      "Passing year must be greater than passing from year"
    )
    .when("education_type", {
      is: (val: any) =>
        parseInt(val) === CourseType.GRADUATION ||
        parseInt(val) === CourseType.MASTER ||
        parseInt(val) === CourseType.DOCTORATE,
      then: (educationSchema) =>
        educationSchema.required("Passing to year is required").nullable(),
      otherwise: (educationSchema) => educationSchema.optional().nullable(),
    }),
  course_type: Yup.string().when("education_type", {
    is: (val: any) =>
      parseInt(val) === CourseType.GRADUATION ||
      parseInt(val) === CourseType.MASTER ||
      parseInt(val) === CourseType.DOCTORATE,
    then: (educationSchema) =>
      educationSchema.required("Course type is required").nullable(),
    otherwise: (educationSchema) => educationSchema.optional().nullable(),
  }),
  university: Yup.string().when("education_type", {
    is: (val: any) =>
      parseInt(val) === CourseType.BELOW10 ||
      parseInt(val) === CourseType.TENTH ||
      parseInt(val) === CourseType.TWELTH ||
      parseInt(val) === CourseType.GRADUATION ||
      parseInt(val) === CourseType.MASTER ||
      parseInt(val) === CourseType.DOCTORATE,
    then: (educationSchema) =>
      educationSchema.required("School or College name is required").nullable(),
  }),
  grading_value: Yup.mixed().when("grading_systems", {
    is: (val: any) => parseInt(val) === GradingType.Percentage,
    then: () =>
      Yup.number().typeError('Grading Value should be in number').max(100,'Grading Value must be less than or equal to 100').positive('Grading Value should be positive').required("Grading value is required"),
    otherwise: () => Yup.number().typeError('Grading Value should be in number').max(10,'Grading Value must be less than or equal to 10').required("Grading value is required"),
  }),
  grading_systems: Yup.string().required("Grading type is required"),
});
