import { Box } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Text, Button } from "@lipihipi/ui-components";
import employer from "../../Images/employer.svg";
import { useEffect, useState } from "react";
import {
  attemptMockInterview,
  checkSubscription,
  fetchMockInterviews,
  interviewReport,
} from "../../Services/Service";
import handleToast from "../../util/Toast";
import { ASSET_URL } from "../../constant";
import NoDataFound from "../../Shared/NoData";

const Mock = () => {
  const [data, setData] = useState([]);

  const onSuccess = (data: any) => {
    setData(data?.interviews);
  };
  const onFailure = (err: any) => {
    handleToast(err.data.message)
  };

  useEffect(() => {
    fetchMockInterviews(onSuccess,onFailure);
  }, []);

  const handleClick = (slug: any) => {
    checkSubscription((res: any) => {
      if (res.success) {
        attemptMockInterview(slug, (res: any) => {
          window.open(res.interview_url, "_blank");
        });
      } else {
        handleToast(
          "You don't have any subsription.Please subscribe to attempt interview."
        );
      }
    });
  };
  const handleReport = (slug: any) => {
    interviewReport(
      slug,
      (res: any) => {
        window.open(`${ASSET_URL}/${res?.data}`, "_blank");
      },
      (err: any) => {
        handleToast(err.data.message);
      }
    );
  };

  return (
    <SecondaryLayout currentActive={8}>
      {data?.length > 0 ? (
        <Box>
          <Text variant="h3">Invited interviews for you ({data.length})</Text>
          <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
            {data.map((interview: any) => {
              return (
                <Card
                  px={2}
                  py={2}
                  disableHeader
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1.5,
                  }}
                >
                  <Box
                    width={60}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={60}
                    sx={{
                      background: "#FFFFFF",
                      border: "1px solid #F0F0F0",
                      "& img": {
                        maxWidth: "100%",
                        objectFit: "contain",
                        maxHeight: "100%",
                      },
                    }}
                  >
                    <img
                      src={
                        interview.displayPicture
                          ? interview.displayPicture
                          : employer
                      }
                      alt=""
                    />
                  </Box>

                  <Text sx={{ flexGrow: 1 }} variant="h3">
                    {interview?.name}
                  </Text>
                  {interview?.attempts !== null &&
                  interview?.attempts?.status === "success" ? (
                    interview.attempts.result !== null ? (
                      <Button
                        onClick={() =>
                          window.open(
                            `${ASSET_URL}/${interview?.attempts?.result}`,
                            "_blank"
                          )
                        }
                        color="secondary"
                        variant="outlined"
                      >
                        View Report
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleReport(interview?.slug)}
                        color="secondary"
                        variant="outlined"
                      >
                        View Report
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={() => handleClick(interview?.slug)}
                      color="secondary"
                      variant="outlined"
                    >
                      Attempt
                    </Button>
                  )}
                </Card>
              );
            })}
          </Box>
        </Box>
      ) : (
        <NoDataFound />
      )}
    </SecondaryLayout>
  );
};

export default Mock;
