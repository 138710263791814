import { Button, DefaultInfoCard, Chips, Card, Text, useSmartPhone } from "@lipihipi/ui-components";
import { HobbyIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { HOBBY } from "../../../RouteConstant";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


const HobbyDetails = ({hobbies}:any) => {
  const navigate = useNavigate();
  const isSmartPhone = useSmartPhone();

  if (hobbies === '') {
    return (
    <DefaultInfoCard icon={<HobbyIcon />}>
      <Text>
        This brings happiness and motivation in you!
      </Text>
      <Text sx={{
        color: '#292929'
      }}>
        <Text sx={{ fontSize: '20px', fontWeight: '400' }} component="span">add Hobbies and Interests</Text>
      </Text>
      <Button onClick={() => navigate(HOBBY)} color="primary" sx={{ mt: 3, mx: 'auto' }} variant="outlined">
        Add Now
      </Button>
    </DefaultInfoCard>
  )
    }
    return (
      <Card
        action={{
          icon: null,
          text: isSmartPhone ? 'Add' : "Add Hobby and Interests",
        }}
      onClick={() => navigate(HOBBY)}
      heading="Hobbies & Interests"
    >
        <Box
          display='flex'
          flexWrap='wrap'
          gap={1.5}
        >
          {hobbies?.split(',').map((el:any) => (
            <Chips label={el} />
          ))}
        </Box>
      </Card>
    )
};

export default HobbyDetails;