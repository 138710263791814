import * as Yup from "yup";

export const JobSchema = Yup.object().shape({
  functional_area: Yup.string().required("Functional Area is required"),
  industry: Yup.string().required("Industry is required"),
  job_type: Yup.mixed().required("Job type is required"),
  current_salary: Yup.string().when("job_type", {
    is: (val: any) => parseInt(val) === 1,
    then: (JobSchema) =>
      JobSchema.required("Current Salary is required").nullable(),
  }),
  current_salary_thousand: Yup.string().when("job_type", {
    is: (val: any) => parseInt(val) === 1,
    then: (JobSchema) =>
      JobSchema.required("Current Salary Thousand is required").nullable(),
  }),
  //   notice_period: Yup.mixed().when("job_type", {
  //     is: (val: any) => parseInt(val) === 1,
  //     then: (JobSchema) =>
  //       JobSchema.required("Notice Period is required").nullable(),
  //   }),
});
