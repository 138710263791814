import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume3: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            backgroundColor: "#424244",
            width: "30%",
            borderTop: "1px solid #C0C0C1",
            borderBottom: "1px solid #C0C0C1",
            padding: "30px 0",
            verticalAlign: "top",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                width: 180,
                height: 180,
                display: "inline-block",
                borderRadius: 180,
              }}
              src={data?.user?.image}
              alt=""
            />
          </div>
          {data.resume?.objective?.length ? (
            <div style={{ marginTop: 20, paddingLeft: 20 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  borderTop: "1px solid #C0C0C1",
                  paddingTop: 20,
                  fontSize: 18,
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                About Me
              </h3>
              <div style={{ marginTop: 8 }}>
                <div style={{ paddingRight: 20 }}>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontFamily:
                        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                      color: "#C0C0C1",
                      fontSize: 12,
                      lineHeight: "170%",
                      margin: 0,
                    }}
                  >
                    {data.resume?.objective}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div style={{ marginTop: 20, paddingLeft: 20 }}>
            <h3
              style={{
                lineHeight: "normal",
                margin: 0,
                textTransform: "uppercase",
                borderTop: "1px solid #C0C0C1",
                paddingTop: 20,
                fontSize: 18,
                fontFamily:
                  "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                fontWeight: 600,
                color: "#fff",
              }}
            >
              Contact Details
            </h3>
            <div style={{ marginTop: 16 }}>
              <div style={{ paddingRight: 20 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#fff",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#fff",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#fff",
                    fontSize: 13,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 12,
                    margin: "6px 0 12px",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 20, paddingLeft: 20 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  fontSize: 18,
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  borderTop: "1px solid #C0C0C1",
                  paddingTop: 20,
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Skills
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: "0 20px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li key={index}>{skill.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 20, paddingLeft: 20 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  fontSize: 18,
                  borderTop: "1px solid #C0C0C1",
                  paddingTop: 20,
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: "0 20px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 20, paddingLeft: 20 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  textTransform: "uppercase",
                  borderTop: "1px solid #C0C0C1",
                  paddingTop: 20,
                  fontSize: 18,
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: "0 20px 0 12px",
                    listStyleType: "none",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#C0C0C1",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li key={index}>{language}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td
          style={{
            width: "70%",
            padding: 30,
            verticalAlign: "top",
            borderRight: "1px solid #C0C0C1",
            borderTop: "1px solid #C0C0C1",
            borderBottom: "1px solid #C0C0C1",
          }}
        >
          <h1
            style={{
              lineHeight: "normal",
              margin: 0,
              fontSize: 52,
              fontFamily:
                "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#3E3B3C",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontFamily:
                  "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
              }}
            >
              {data.resume?.firstName}
            </span>
            <br />
            {data.resume?.lastName}
          </h1>
          <p
            style={{
              margin: "10px 0 0",
              fontSize: 18,
              color: "#3E3B3C",
              fontFamily:
                "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 20,
                  borderBottom: "2px solid #3E3B3C",
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#3E3B3C",
                }}
              >
                Work Experience
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #3E3B3C",
                  marginTop: 16,
                  paddingLeft: 16,
                }}
              >
                {data.resume?.experiences?.map((experience, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#3E3B3C",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 0,
                          width: 16,
                          height: 16,
                          display: "inline-block",
                          border: "2px solid #3E3B3C",
                          borderRadius: 50,
                          verticalAlign: "top",
                          marginLeft: "-25px",
                          marginRight: 6,
                          backgroundColor: "#fff",
                        }}
                      >
                        Icon
                      </span>
                      {experience.position}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 12px",
                      }}
                    >
                      {index === 0 ? (
                        <>
                          {`${experience.companyName} | ${experience.startDate} - `}
                          <strong style={{ fontWeight: "bold" }}>
                            {experience.endDate !== null
                              ? experience.endDate
                              : "Present"}
                          </strong>
                        </>
                      ) : (
                        <>{`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}</>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 20,
                  borderBottom: "2px solid #3E3B3C",
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#3E3B3C",
                }}
              >
                Education
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #3E3B3C",
                  marginTop: 16,
                  paddingLeft: 16,
                }}
              >
                {data.resume?.educations?.map((education, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#3E3B3C",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 0,
                          width: 16,
                          height: 16,
                          display: "inline-block",
                          border: "2px solid #3E3B3C",
                          borderRadius: 50,
                          verticalAlign: "top",
                          marginLeft: "-25px",
                          marginRight: 6,
                          backgroundColor: "#fff",
                        }}
                      >
                        Icon
                      </span>
                      {education.educationType}
                    </p>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#3E3B3C",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {education.schoolName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 12px",
                      }}
                    >
                      <span>Percentage:</span> {education.gradingValue}{" "}
                      {education.gradingSystems === "Percentage" ? "%" : "CGPA"}{" "}
                      | <span>Year:</span> {education.passedYear}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 20,
                  borderBottom: "2px solid #3E3B3C",
                  fontFamily:
                    "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
                  fontWeight: 600,
                  color: "#3E3B3C",
                }}
              >
                Projects
              </h3>
              <div
                style={{
                  borderLeft: "2px solid #3E3B3C",
                  marginTop: 16,
                  paddingLeft: 16,
                }}
              >
                {data.resume?.projects?.map((project, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#3E3B3C",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 0,
                          width: 16,
                          height: 16,
                          display: "inline-block",
                          border: "2px solid #3E3B3C",
                          borderRadius: 50,
                          verticalAlign: "top",
                          marginLeft: "-25px",
                          marginRight: 6,
                          backgroundColor: "#fff",
                        }}
                      >
                        Icon
                      </span>
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#3E3B3C" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#3E3B3C" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#3E3B3C" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume3;
