import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume8: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td style={{ padding: "30px 0" }}>
          <h1
            style={{
              lineHeight: "normal",
              margin: 0,
              fontSize: 36,
              fontFamily:
                "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
              fontWeight: 600,
              color: "#252932",
            }}
          >
            {data.resume?.firstName} {data.resume?.lastName}
          </h1>
          <p
            style={{
              margin: "10px 0 0",
              fontSize: 18,
              color: "#252932",
              fontFamily:
                "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          <div style={{ marginTop: 30 }}>
            <div>
              <div style={{ paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#252932",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Phone:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontFamily:
                        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                      color: "#252932",
                      fontSize: 14,
                      margin: "6px 0 12px",
                    }}
                  >
                    {data?.resume?.mobile}
                  </span>
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#252932",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Email:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontFamily:
                        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                      color: "#252932",
                      fontSize: 14,
                      margin: "6px 0 12px",
                    }}
                  >
                    {data?.resume?.emailId}
                  </span>
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 8 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                    color: "#252932",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Address:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontFamily:
                        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                      color: "#252932",
                      fontSize: 14,
                      margin: "6px 0 12px",
                    }}
                  >
                    {data?.resume?.address}, {data?.resume?.city},{" "}
                    {data?.resume?.pincode}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {data.resume?.objective?.length ? (
            <div style={{ marginTop: 30 }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 14,
                  lineHeight: "170%",
                  color: "#252932",
                  fontFamily:
                    "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                }}
              >
                {data.resume?.objective}
              </p>
            </div>
          ) : null}
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Work Experience
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.experiences?.map(
                  (
                    experience: {
                      position: any;
                      companyName: any;
                      startDate: any;
                      endDate: any;
                    },
                    index: number
                  ) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                          color: "#252932",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {experience.position}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#252932",
                          fontSize: 14,
                          margin: "6px 0 12px",
                        }}
                      >
                        {index === 0 ? (
                          <>
                            {`${experience.companyName} | ${experience.startDate} - `}
                            <strong style={{ fontWeight: "bold" }}>
                              {experience.endDate !== null
                                ? experience.endDate
                                : "Present"}
                            </strong>
                          </>
                        ) : (
                          <>{`${experience.companyName} | ${
                            experience.startDate
                          } - ${
                            experience.endDate !== null
                              ? experience.endDate
                              : "Present"
                          }`}</>
                        )}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Projects
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.projects?.map((project, index: number) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                        color: "#252932",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#252932",
                        fontSize: 14,
                        margin: "8px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#252932" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#252932" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#252932" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily:
                          "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                        color: "#252932",
                        fontSize: 14,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Education
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        paddingRight: 8,
                        marginTop: index === 0 ? 0 : 16,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                          color: "#252932",
                          fontSize: 16,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.educationType.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                          color: "#252932",
                          fontSize: 16,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.schoolName.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#252932",
                          fontSize: 14,
                          margin: "6px 0 12px",
                        }}
                      >
                        <span>Percentage:</span> {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        | <span>Year:</span> {education.passedYear}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Expertise
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#252932",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.skills?.map((skill, index: any) => (
                    <li
                      style={{
                        lineHeight: "200%",
                        fontSize: 14,
                        display: "inline-block",
                        width: "25%",
                      }}
                      key={index}
                    >
                      {skill.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#252932",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.hobbies.map((hobby: any, index: any) => (
                    <li
                      style={{
                        lineHeight: "200%",
                        fontSize: 14,
                        display: "inline-block",
                        width: "25%",
                      }}
                      key={index}
                    >
                      {hobby}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 30 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #252932",
                  fontFamily:
                    "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS'",
                  fontWeight: 600,
                  color: "#252932",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 9",
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#252932",
                    fontSize: 14,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li
                      style={{
                        lineHeight: "200%",
                        fontSize: 14,
                        display: "inline-block",
                        width: "25%",
                      }}
                      key={index}
                    >
                      {language}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume8;
