import { IData } from "../Preview";
import { Link } from "react-router-dom";

interface ResumeData {
  data: IData;
}

const Resume15: React.FC<ResumeData> = ({ data }) => (
  <table
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
    cellSpacing={0}
    cellPadding={0}
    border={0}
  >
    <tbody>
      <tr>
        <td style={{ width: "100%" }} colSpan={2}>
          <h1
            style={{
              background: "#313B4B",
              padding: "30px 0",
              margin: 0,
              textAlign: "center",
              color: "#fff",
              fontFamily: "Georgia",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "170%",
            }}
          >
            {data.resume?.firstName} {data.resume?.lastName}
          </h1>
          <p
            style={{
              margin: 0,
              background: "#D1E3FF",
              color: "#000",
              fontFamily: "Georgia",
              fontSize: "14px",
              padding: "4px 0px",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "170%",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          <table
            style={{
              width: "100%",
              borderBottom: "1px solid #E8E8E8",
              tableLayout: "fixed",
              textAlign: "center",
            }}
          >
            <td>
              <p
                style={{
                  padding: "4px 0",
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                }}
              >
                {data?.resume?.mobile}
              </p>
            </td>
            <td>
              <p
                style={{
                  padding: "4px 0",
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                }}
              >
                {data?.resume?.emailId}
              </p>
            </td>
            <td>
              <p
                style={{
                  padding: "4px 0",
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                }}
              >
                {data?.resume?.address}, {data?.resume?.city},{" "}
                {data?.resume?.pincode}
              </p>
            </td>
          </table>
          <div
            style={{
              margin: "20px 40px 0px",
              borderBottom: "1px solid #E8E8E8",
              paddingBottom: 40,
            }}
          >
            <p
              style={{
                margin: 0,
                textTransform: "uppercase",
                color: "#000",
                fontFamily: "Georgia",
                fontSize: "14px",
                textAlign: "center",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "170%",
              }}
            >
              Profile
            </p>
            <p
              style={{
                margin: "16px 0 0",
                textAlign: "center",
                marginTop: "8px",
                color: "#000",
                fontFamily: "Georgia",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "170%",
              }}
            >
              {data.resume?.objective}
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "30%",
            padding: "20px 20px 20px 40px",
            verticalAlign: "top",
            borderRight: "1px solid #E8E8E8",
          }}
        >
          <div style={{}}>
            {data.resume?.educations?.length ? (
              <div style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    margin: 0,
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "14px",
                    fontStyle: "normal",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    lineHeight: "170%",
                    textTransform: "uppercase",
                  }}
                >
                  EDucation
                </p>

                {data.resume?.educations?.map((education, index) => (
                  <div
                    key={index}
                    style={{ marginBottom: "30px", marginTop: "8px" }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: "#000",
                        fontFamily: "Georgia",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "170%",
                      }}
                    >
                      {education.degree}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        margin: 0,
                        fontFamily: "Georgia",
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "160%",
                      }}
                    >
                      {education.schoolName.toLowerCase()}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "#000",
                        fontFamily: "Georgia",
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "160%",
                      }}
                    >
                      Percentage: {education.gradingValue}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        margin: 0,
                        fontFamily: "Georgia",
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "160%",
                      }}
                    >
                      Passing Year: {education.passedYear}
                    </p>
                  </div>
                ))}
              </div>
            ) : null}

            {data.resume?.skills?.length ? (
              <div style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    margin: "0.25rem 0 0.5rem",
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "14px",
                    fontStyle: "normal",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    lineHeight: "170%",
                    textTransform: "uppercase",
                  }}
                >
                  SKILLS
                </p>

                <ul
                  style={{
                    marginTop: "8px",
                    paddingLeft: "10px",
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "170%",
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li key={index}>{skill.name}</li>
                  ))}
                </ul>
              </div>
            ) : null}

            {data.resume?.hobbies?.length ? (
              <div style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    margin: "0.25rem 0 0.5rem",
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "14px",
                    fontStyle: "normal",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    lineHeight: "170%",
                    textTransform: "uppercase",
                  }}
                >
                  Hobbies
                </p>

                <ul
                  style={{
                    marginTop: "8px",
                    paddingLeft: "10px",
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "170%",
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                  ))}
                </ul>
              </div>
            ) : null}

            {data.resume?.languageKnown?.length ? (
              <div style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    margin: 0,
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "14px",
                    fontStyle: "normal",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    lineHeight: "170%",
                    textTransform: "uppercase",
                  }}
                >
                  Language
                </p>

                <ul
                  style={{
                    marginTop: "8px",
                    paddingLeft: "10px",
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "170%",
                  }}
                >
                  {data.resume?.languageKnown?.map((language, index) => (
                    <li key={index}>{language}</li>
                  ))}
                </ul>
              </div>
            ) : null}

            {data.resume?.nationality?.length ? (
              <div style={{ marginBottom: "25px" }}>
                <p
                  style={{
                    margin: 0,
                    color: "#000",
                    fontFamily: "Georgia",
                    fontSize: "14px",
                    fontStyle: "normal",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    lineHeight: "170%",
                    textTransform: "uppercase",
                  }}
                >
                  Nationality
                </p>

                <p
                  style={{
                    color: "#000",
                    margin: "8px 0 0",
                    fontFamily: "Georgia",
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontWeight: 400,
                    lineHeight: "160%",
                  }}
                >
                  {data.resume?.nationality}
                </p>
              </div>
            ) : null}
          </div>
        </td>
        <td
          style={{
            width: "70%",
            padding: "0px 45px 40px",
            verticalAlign: "top",
            margin: 40,
          }}
        >
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: "26px" }}>
              <p
                style={{
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "170%",
                }}
              >
                Work Experience
              </p>

              {data.resume?.experiences?.map((experience, index) => (
                <div style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "170%",
                    }}
                  >
                    {experience.position}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    {index === 0 ? (
                      <>
                        {`${experience.companyName} / ${experience.startDate} - `}
                        <strong style={{ fontWeight: "bold" }}>
                          {experience.endDate !== null
                            ? experience.endDate
                            : "Present"}
                        </strong>
                      </>
                    ) : (
                      <>{`${experience.companyName} / ${
                        experience.startDate
                      } - ${
                        experience.endDate !== null
                          ? experience.endDate
                          : "Present"
                      }`}</>
                    )}
                  </p>
                </div>
              ))}
            </div>
          ) : null}

          {data.resume?.internships?.length ? (
            <div style={{ marginTop: "26px" }}>
              <p
                style={{
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                }}
              >
                Internship
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: "2px",
                  background: "#d9d9d9",
                  width: "40px",
                  height: "2px",
                }}
              />

              {data.resume?.internships?.map((internship, index) => (
                <div style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "170%",
                    }}
                  >
                    {internship.position}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    {index === 0 ? (
                      <>
                        {`${internship.companyName} / ${internship.startDate} - `}
                        <strong style={{ fontWeight: "bold" }}>
                          {internship.endDate}
                        </strong>
                      </>
                    ) : (
                      <>{`${internship.companyName} / ${internship.startDate} - ${internship.endDate}`}</>
                    )}
                  </p>
                </div>
              ))}
            </div>
          ) : null}

          {data.resume?.projects?.length ? (
            <div style={{ marginTop: "26px" }}>
              <p
                style={{
                  margin: 0,
                  color: "#000",
                  fontFamily: "Georgia",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "170%",
                }}
              >
                Projects
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: "2px",
                  background: "#d9d9d9",
                  width: "40px",
                  height: "2px",
                }}
              />

              {data.resume?.projects?.map((project, index) => (
                <div key={index} style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "170%",
                    }}
                  >
                    {project?.company?.toLowerCase()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    {project?.projectName}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    <Link to={project?.url ?? "#"} target="_blank">
                      {project?.url}
                    </Link>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    {project?.description}
                  </p>

                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    Technology: {project?.technology}
                  </p>

                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    Team Size: {project?.teamSize}
                  </p>

                  <p
                    style={{
                      margin: 0,
                      color: "#000",
                      fontFamily: "Georgia",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "170%",
                    }}
                  >
                    Year: {project?.year}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume15;
