import React, { useEffect, useState } from "react";
import GRAPHIC from "../../Images/kyc.svg";
import VERIFIED from "../../Images/verified.svg";
import CASH_FREE from "../../Images/cashfreelogo.svg";
import { AuthLayout } from "../../Components";
import { Button, Form, InputBox, Text } from "@lipihipi/ui-components";
import { Box, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import vars from "../../theme/variables";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getKyc, sendKycOTP, verifyKycOTP } from "../../Services/Service";
import { onFailure } from "../../util/helper";

const { primaryFont } = vars;

const inputStyle = {
  "& .MuiInput-input": {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "31px",
    color: "#1D183E",
  },
};

const onboardingData = {
  icon: GRAPHIC,
};

const Kyc = () => {
  const [verified, setVerified] = useState<any>({});
  const [refId, setRefId] = useState<any>("test");
  const [showOtp, setShowOtp] = useState(false);
  const [error, setError] = useState<any>();
  const [otpError, setOtpError] = useState<any>();
  useEffect(() => {
    getKyc(onSuccess, onFailure);
  }, []);

  const onSuccess = (res: any) => {
    setVerified(res.data);
  };
  const onSuccessOtp = (res: any) => {
    if (res.success) {
      setShowOtp(true);
      setRefId(res.data.ref_id);
    }
  };
  const onSuccessVerify = (res: any) => {
    getKyc(onSuccess, onFailure);
  };
  const onKycFailure = (res: any) => {
    console.log(res);
    setError(res.data.message);
  };
const onOtpFailure =(res:any)=>{
  setOtpError(res.data.message)
}
  return (
    <AuthLayout isSecondary data={onboardingData}>
      {!verified ? (
        <>
          <Box mb={3}>
            <Text
              sx={{
                mb: 0.5,
              }}
              variant="h1"
            >
              Please verify your Identity
            </Text>
            <Text variant="caption">
              This will be done from govt information.
            </Text>
          </Box>

          <Box mb={8}>
            {error && <span className="text-danger">{error}</span>}
            <Box display="flex" alignItems="flex-end" columnGap={5}>
              <Form
                onSubmit={(values: any) =>
                  sendKycOTP(values, onSuccessOtp, onKycFailure)
                }
                render={({ handleChange }: any) => {
                  return (
                    <>
                      <InputBox
                        fullWidth
                        sx={{mb: 3}}
                        placeholder="Enter here.."
                        onChange={handleChange}
                        required
                        label="Enter Your Aadhaar No"
                        name="aadhaar_number"
                      />
                      <Button
                        sx={{ flexShrink: 0 }}
                        variant="outlined"
                        color="secondary"
                        type="submit"
                      >
                        Get OTP
                      </Button>
                    </>
                  );
                }}
              />
            </Box>

            <FormHelperText
              sx={{
                fontFamily: primaryFont,
                fontWeight: 400,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                marginTop: "3px",
                lineHeight: "183%",
                color: "#9E9E9E",
              }}
            >
              Powered by <img src={CASH_FREE} alt="cash-free" />
            </FormHelperText>
          </Box>
          {showOtp && (
            <>
              <Box
                mb={8}
                p={2}
                sx={{
                  background: "#F1F1FC",
                }}
              >
                <Text>
                  Enter OTP received on your registered mobile no linked with
                  Aadhaar
                </Text>
                {otpError && <span className="text-danger">{otpError}</span>}
                <Box
                  display="flex"
                  textAlign="center"
                  columnGap={3}
                  mt={2.5}
                  pb={1}
                >
                  <Form
                    onSubmit={(values: any) => {
                      values.ref_id = refId;
                      verifyKycOTP(values, onSuccessVerify, onOtpFailure);
                    }}
                    render={({ handleChange }: any) => {
                      return (
                        <>
                          <InputBox
                            fullWidth
                            placeholder="Enter here.."
                            onChange={handleChange}
                            required
                            label="Enter Your Aadhaar No"
                            name="otp"
                          />
                          <Button
                            sx={{ flexShrink: 0 }}
                            variant="outlined"
                            color="secondary"
                            type="submit"
                          >
                            Verify OTP
                          </Button>
                        </>
                      );
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box textAlign="center" mb={5}>
            <img
              style={{ width: 120, marginBottom: 8 }}
              src={VERIFIED}
              alt="verified"
            />
            <Text variant="h3">Your Identity has been verified!</Text>
          </Box>
          <Button fullWidth size="large">
            Go to Dashboard
          </Button>
        </>
      )}
    </AuthLayout>
  );
};

export default Kyc;
