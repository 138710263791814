import { SecondaryLayout } from "../../Components";
import { Card, Form, InputBox, Text, Back } from "@lipihipi/ui-components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import employer from "../../Images/employer.svg";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActionArea,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jobAdvanceSearch, saveJob, searchJob } from "../../Services/Service";
import NoDataFound from "../../Shared/NoData";
import moment from "moment";
import { ASSET_URL } from "../../constant";
import QuickSearch from "../../Shared/QuickSearch";
import { JOBS_DETAILS, MY_JOBS } from "../../RouteConstant";

const salaryMarks = Array(101)
  .fill(undefined)
  .map((el, index) => {
    if (index % 25 === 0) {
      return {
        value: index,
        label: index + "L",
      };
    } else {
      return {
        value: index,
        label: "",
      };
    }
  });

const experienceMarks = Array(21)
  .fill(undefined)
  .map((el, index) => {
    if (index % 4 === 0) {
      return {
        value: index,
        label: index + "yr",
      };
    } else {
      return {
        value: index,
        label: "",
      };
    }
  });

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const minDistance = 2;

const JobSearch = () => {
  const navigate = useNavigate();
  const searchRef = useRef(false);
  const query = useQuery();
  const searchKeyword = query.get("keyword");
  const [jobs, setJobs] = useState<any>([]);
  const [jobType, setJobType] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [datePosted, setDatePosted] = useState<any>([]);
  const [toggleData, setToggleData] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState<any>([]);
  const [selectedLocations, setSelectedLocations] = useState<any>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<any>([]);
  const [selectedDatePosted, setSelectedDatePosted] = useState<any>("");
  const [experience, setExperience] = React.useState<any>([0, 20]);
  const [salary, setSalary] = React.useState<any>([0, 100]);
  const [toggle, setToggle] = useState(false);

  const handleExperienceChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setExperience([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setExperience([clamped - minDistance, clamped]);
      }
    } else {
      setExperience(newValue as number[]);
    }

    searchRef.current = true;
  };

  const handleSalaryChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setSalary([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSalary([clamped - minDistance, clamped]);
      }
    } else {
      setSalary(newValue as number[]);
    }
    searchRef.current = true;
  };

  useEffect(() => {
    if (searchRef.current) {
      let formData: any = {};

      if (selectedLocations?.length > 0) {
        formData.location = selectedLocations;
      }
      if (selectedCompanies?.length > 0) {
        formData.company = selectedCompanies;
      }

      if (selectedJobType?.length > 0) {
        formData.job_type = selectedJobType;
      }

      if (selectedDatePosted?.length > 0) {
        formData.date_post = selectedDatePosted;
      }

      formData.min_exp = experience[0];
      formData.max_exp = experience[1];
      formData.min_salary = salary[0];
      formData.max_salary = salary[1];

      const payload = {
        keyword: searchKeyword,
        data: formData,
      };

      jobAdvanceSearch(payload, (response: any) => {
        if (response?.status) {
          setJobs(response?.data?.findJobs);
        }
      });
    }
  }, [
    selectedDatePosted,
    selectedLocations,
    selectedCompanies,
    selectedJobType,
    toggle,
  ]);

  const handleChangeLocation = (e: any) => {
    setSelectedLocations((prev: any) => {
      if (prev.includes(e.target.name)) {
        return prev.filter((el: any) => el !== e.target.name);
      }
      return [...prev, e.target.name];
    });

    searchRef.current = true;
  };

  const handleChangeCompany = (e: any) => {
    setSelectedCompanies((prev: any) => {
      if (prev.includes(e.target.name)) {
        return prev.filter((el: any) => el !== e.target.name);
      }
      return [...prev, e.target.name];
    });

    searchRef.current = true;
  };

  const handleChangeJobType = (value: any) => {
    setSelectedJobType((prev: any) => {
      if (prev.includes(value)) {
        return prev.filter((el: any) => el !== value);
      }
      return [...prev, value];
    });

    searchRef.current = true;
  };

  const handleDatePostedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDatePosted((event.target as HTMLInputElement).value);
    searchRef.current = true;
  };

  useEffect(() => {
    const payload = {
      keyword: searchKeyword,
    };
    searchJob(payload, (response: any) => {
      setJobs(response?.data?.findJobs);
      setCompanies(response?.data?.companies);
      setLocations(response?.data?.all_loctions);
      setJobType(Object.values(response?.data?.job_type));
      setDatePosted(() => [
        {
          key: "today_jobs",
          label: "Today",
          value: response?.data?.today_jobs,
        },
        {
          key: "last_7_days_jobs",
          label: "Last 7 Days",
          value: response?.data?.last_7_days_jobs,
        },
        {
          key: "last_30_days_jobs",
          label: "Last 30 Days",
          value: response?.data?.last_30_days_jobs,
        },
        {
          key: "all_time_jobs",
          label: "All Time",
          value: response?.data?.all_time_jobs,
        },
      ]);
    });
  }, [toggleData, searchKeyword]);

  const handleBookmark = ({ id, alreadyApplied }: any) => {
    const payload = {
      id,
      save_for_later: !Boolean(alreadyApplied),
    };
    saveJob(payload, (response: any) => {
      if (response.success) {
        setToggleData((prev) => !prev);
      }
    });
  };

  const classes = {
    accordion: {
      "&.Mui-expanded": {
        margin: 0,
      },
    },
    root: {
      "&.Mui-expanded": {
        minHeight: "48px",
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
        "&.Mui-expanded": {
          margin: "0",
        },
      },
    },
  };

  const leftPanel = (
    <>
      <Text variant="h3">Filters</Text>
      <Card sx={{ p: 0 }} disableHeader>
        <Box sx={{ px: 2, pt: 2 }}>
          <Text sx={{ mb: 0.5 }} variant="subtitle2">
            Options / Total Jobs-{jobs?.length}
          </Text>
          <Text variant="caption">They apply automatically on selections</Text>
        </Box>

        <Box mt={2}>
          {datePosted?.length > 0 && (
            <Accordion sx={classes.accordion} elevation={0}>
              <AccordionSummary
                sx={classes.root}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h4">Date Posted</Text>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <RadioGroup
                  name="date_post"
                  value={selectedDatePosted}
                  onChange={handleDatePostedChange}
                >
                  {datePosted?.map((posted: any) => (
                    <FormControlLabel
                      key={posted.key}
                      value={posted.key}
                      control={<Radio />}
                      label={`${posted.label} (${posted.value})`}
                    />
                  ))}
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
          )}

          {Object?.keys(locations)?.length > 0 && (
            <Accordion sx={classes.accordion} elevation={0}>
              <AccordionSummary
                sx={classes.root}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h4">Location</Text>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <FormGroup>
                  {Object?.keys(locations)?.map((key: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={key}
                            checked={selectedLocations?.includes(key)}
                            onChange={handleChangeLocation}
                          />
                        }
                        label={`${key} (${locations[key]})`}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}

          {Object?.keys(companies)?.length > 0 && (
            <Accordion sx={classes.accordion} elevation={0}>
              <AccordionSummary
                sx={classes.root}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h4">Company</Text>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <FormGroup>
                  {Object?.keys(companies)?.map((key: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={key}
                            checked={selectedCompanies?.includes(key)}
                            onChange={handleChangeCompany}
                          />
                        }
                        label={`${key} (${companies[key]})`}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}

          {jobType?.length > 0 && (
            <Accordion sx={classes.accordion} elevation={0}>
              <AccordionSummary
                sx={classes.root}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h4">Job Type</Text>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <FormGroup>
                  {jobType.map((job: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={job.value}
                            checked={selectedJobType?.includes(job.id)}
                            onChange={() => handleChangeJobType(job.id)}
                          />
                        }
                        label={`${job.value} (${job.total_jobs})`}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion sx={classes.accordion} elevation={0}>
            <AccordionSummary
              sx={classes.root}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text variant="h4">Experience</Text>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Form
                initialValues={{ from: experience[0], to: experience[1] }}
                render={({ values }: any) => {
                  return (
                    <Box mb={2} display="flex" columnGap={3}>
                      <InputBox
                        fullWidth
                        disabled
                        name="from"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">year</InputAdornment>
                          ),
                        }}
                        value={values.from}
                        label="From"
                        placeholder="Search here..."
                        onChange={(e: any) =>
                          setExperience((prevExp: any) => {
                            let first = prevExp[0];
                            let last = prevExp[1];
                            first = +e.target.value;
                            return [first, last];
                          })
                        }
                      />
                      <InputBox
                        fullWidth
                        name="to"
                        disabled
                        value={values.to}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">year</InputAdornment>
                          ),
                        }}
                        label="To"
                        placeholder="Search here..."
                        onChange={(e: any) =>
                          setExperience((prevExp: any) => {
                            const first = prevExp[0];
                            let last = prevExp[1];

                            last = +e.target.value;

                            return [first, last];
                          })
                        }
                      />
                    </Box>
                  );
                }}
              />

              <Box px={1}>
                <Slider
                  onChangeCommitted={() => setToggle((prev) => !prev)}
                  value={experience}
                  onChange={handleExperienceChange}
                  marks={experienceMarks}
                  step={1}
                  min={0}
                  disableSwap
                  max={20}
                />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={classes.accordion} elevation={0}>
            <AccordionSummary
              sx={classes.root}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text variant="h4">Salary</Text>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Form
                initialValues={{ from: salary[0], to: salary[1] }}
                render={({ values }: any) => {
                  return (
                    <Box mb={2} display="flex" columnGap={3}>
                      <InputBox
                        fullWidth
                        disabled
                        name="from"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">LPA</InputAdornment>
                          ),
                        }}
                        value={values.from}
                        label="From"
                        placeholder="Search here..."
                        onChange={(e: any) =>
                          setSalary((prevExp: any) => {
                            let first = prevExp[0];
                            let last = prevExp[1];
                            first = +e.target.value;
                            return [first, last];
                          })
                        }
                      />
                      <InputBox
                        fullWidth
                        name="to"
                        disabled
                        value={values.to}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">LPA</InputAdornment>
                          ),
                        }}
                        label="To"
                        placeholder="Search here..."
                        onChange={(e: any) =>
                          setSalary((prevExp: any) => {
                            const first = prevExp[0];
                            let last = prevExp[1];

                            last = +e.target.value;

                            return [first, last];
                          })
                        }
                      />
                    </Box>
                  );
                }}
              />

              <Box px={1}>
                <Slider
                  onChangeCommitted={() => setToggle((prev) => !prev)}
                  value={salary}
                  onChange={handleSalaryChange}
                  marks={salaryMarks}
                  step={1}
                  min={0}
                  disableSwap
                  max={100}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Card>
    </>
  );

  return (
    <SecondaryLayout
      leftPanel={leftPanel}
      rightPanel={<QuickSearch searchKeyword={searchKeyword} />}
      currentActive={1}
    >
      <Back onClick={() => navigate(MY_JOBS)} />
      {jobs?.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box>
          <Text variant="h3">
            {jobs?.length} results found for the keyword “{searchKeyword}”
          </Text>
          <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
            {jobs?.map((job: any) => {
              return (
                <Card key={job.id} px={0} py={0} disableHeader>
                  <CardActionArea
                    onClick={() =>
                      navigate(JOBS_DETAILS.replace(":jobId", job?.id))
                    }
                    sx={{ p: 2 }}
                  >
                    <Box display="flex">
                      <Box
                        pr={1}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        rowGap={3}
                      >
                        <Box>
                          <Text variant="subtitle2">{job?.job_title}</Text>
                          <Text sx={{ mt: 1 }} variant="subtitle2">
                            ({job?.min_exp}-{job?.max_exp} years experience)
                          </Text>
                        </Box>

                        <Box display="flex" flexDirection="column" rowGap={1.5}>
                          <Box display="flex" alignItems="center" columnGap={2}>
                            <Text
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "end",
                                columnGap: 1,
                              }}
                            >
                              <CorporateFareIcon
                                sx={{ fontSize: 16, color: "#161458" }}
                              />
                              {job?.company_name}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center" columnGap={2}>
                            <Text
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                              }}
                            >
                              <WorkHistoryIcon
                                sx={{ fontSize: 16, color: "#161458" }}
                              />
                              {job?.min_exp}+ years
                            </Text>
                            <Text variant="body2">
                              <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                            </Text>
                            <Text
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 0.5,
                              }}
                            >
                              <CurrencyRupeeIcon
                                sx={{ fontSize: 16, color: "#161458" }}
                              />
                              {!job.hide_salary
                                ? `${job?.min_annual_salary}${
                                    job?.min_annual_salary_thousand
                                      ? `.${job?.min_annual_salary_thousand}`
                                      : ".0"
                                  } - ${job?.max_annual_salary}${
                                    job?.max_annual_salary_thousand
                                      ? `.${job?.max_annual_salary_thousand}`
                                      : ".0"
                                  } LPA `
                                : "Not Disclosed"}
                            </Text>
                            <Text variant="body2">
                              <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                            </Text>
                            <Text
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 0.5,
                              }}
                            >
                              <LocationOnIcon
                                sx={{ fontSize: 16, color: "#161458" }}
                              />
                              {job?.job_locations.length > 30
                                ? `${job?.job_locations.substring(0, 30)}...`
                                : job?.job_locations}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center" columnGap={2}>
                            <Text
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                columnGap: 1,
                              }}
                            >
                              <MilitaryTechIcon
                                sx={{ fontSize: 16, color: "#161458" }}
                              />
                              {job?.key_skills}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        width={60}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={60}
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid #F0F0F0",
                          "& img": {
                            maxWidth: "100%",
                            objectFit: "contain",
                            maxHeight: "100%",
                          },
                        }}
                      >
                        <img
                          src={
                            job?.company_logo
                              ? `${ASSET_URL}/${job?.company_logo}`
                              : employer
                          }
                          alt=""
                        />
                      </Box>
                    </Box>
                  </CardActionArea>

                  <Box sx={{ px: 2 }} display="flex" alignItems="center">
                    <Text sx={{ color: "#868D90" }} variant="caption">
                      Posted {moment(job?.created_at, "YYYYMMDD").fromNow()}
                    </Text>
                    <IconButton
                      onClick={() =>
                        handleBookmark({
                          id: job?.id,
                          alreadyApplied: job?.save_for_later,
                        })
                      }
                      color={job?.save_for_later === 1 ? "success" : "default"}
                      sx={{ ml: "auto" }}
                    >
                      {job?.save_for_later === 1 ? (
                        <BookmarkRemoveIcon
                          sx={{ fontSize: 20, color: "#00C385" }}
                        />
                      ) : (
                        <BookmarkBorderIcon sx={{ fontSize: 20 }} />
                      )}
                    </IconButton>
                  </Box>
                </Card>
              );
            })}
          </Box>
        </Box>
      )}
    </SecondaryLayout>
  );
};

export default JobSearch;
