import React, { useEffect } from 'react';
import myJsonData from './iosAssetlinks.json';

function IosJsonViewer() {
  useEffect(() => {
    const openJsonFile = () => {
      const jsonData = JSON.stringify(myJsonData, null, 2);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      window.open(url);
    };

    openJsonFile(); // Automatically open the JSON file when component is mounted
  }, []);

  return null; // Since we're automatically redirecting, we don't need to render anything
}

export default IosJsonViewer;