import * as Yup from "yup";

export const ProjectSchema = Yup.object().shape({
    project_type: Yup.string()
    .required("Project Type is required"),
    project_duration: Yup.string().required("Project duration is required"),
    technology: Yup.string().required("Texhnology is required"),
    project_link: Yup.string().url('Link should be in https://').nullable(''),
    team_size: Yup.number().typeError('Team size should be a number'),
});

