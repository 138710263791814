import {
  Button,
  DefaultInfoCard,
  Card,
  Text,
  FileUpload,
  Form,
  ConfirmationModal,
} from "@lipihipi/ui-components";
import { ResumeIcon } from "../../Icons";
import { Box, Drawer, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { deleteResume, saveResume } from "../../Services/Service";
import handleToast, { success } from "../../util/Toast";
import { uploadPublic } from "../../Services/FileUploadService";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ASSET_URL } from "../../constant";

const UploadResume = ({ url, name, date, setToggle }: any) => {
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDelete = () => {
    deleteResume((response: any) => {
      handleToast(response.message, success);
      setTimeout(() => setToggle((prev: any) => !prev), 200);
    });
  };
  const handleDownload = () => {
    window.open(url, "_blank");
  };

  if (!url) {
    return (
      <>
        <DefaultInfoCard icon={<ResumeIcon />}>
          <Text>It’s always good to be ready with Resume!</Text>
          <Button
            onClick={() => setOpen(true)}
            sx={{ mt: 3, mb: 1, width: "296px", mx: "auto" }}
            fullWidth
            size="large"
            variant="contained"
          >
            Update Resume
          </Button>
          <Text variant="h4">
            Supported Formats: doc, docx, rtf, pdf, upto 2 MB
          </Text>
        </DefaultInfoCard>

        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
          <Box
            borderBottom="1px solid #eee"
            px={3}
            py={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="h3">Upload Resume</Text>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Form
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
            onSubmit={(values: any) => {
              saveResume(values, (respose: any) => {
                handleToast(respose.message, success);
                setToggle((prev: any) => !prev);
                setOpen(false);
              });
            }}
            render={() => {
              return (
                <>
                  <Box
                    flexGrow={1}
                    p={3}
                    sx={{
                      "& .row + div": {
                        mt: 2,
                        "& > div": {
                          margin: 0,
                          gap: "1rem",
                          width: "100%",
                          "& svg": {
                            width: "1.5rem",
                            height: "1.5rem",
                          },
                          "& > div": {
                            flexGrow: 1,
                            "& .card-title": {},

                            "& p": {
                              margin: 0,
                            },
                          },
                        },
                      },
                      "& .col-md-2": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 2,
                      },
                      "& .custom-file label": {
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px dashed #3047EC",
                        cursor: "pointer",
                        background: "#FFF",
                        height: "48px",
                      },
                    }}
                  >
                    <FileUpload
                      name={"resume"}
                      upload={uploadPublic}
                      accept={[
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.ms-powerpoint",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                      ]}
                      type="single"
                      maxSize="10mb"
                      label="Upload Resume"
                      assetUrl={ASSET_URL}
                    />
                  </Box>
                  <Box
                    borderTop="1px solid #eee"
                    p={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      Save Resume
                    </Button>
                  </Box>
                </>
              );
            }}
          />
        </Drawer>
      </>
    );
  }
  return (
    <Card disableAction heading="Attach Resume">
      <Box display="flex" flexDirection="column" rowGap={1.5}>
        <Box display="flex" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <InsertDriveFileOutlinedIcon sx={{ mr: 1 }} />
            <Text variant="h4">{name}</Text>
          </Box>

          <Box display="flex" alignItems="center" ml={2}>
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "#E4F7F3",
                },
              }}
              onClick={handleDownload}
              size="small"
              color="success"
            >
              <FileDownloadOutlinedIcon sx={{ color: "#00A689" }} />
            </IconButton>
            <IconButton
              onClick={() => setOpenDeleteModal(true)}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffeded",
                },
              }}
              size="small"
              color="error"
            >
              <DeleteOutlineOutlinedIcon sx={{ color: "#d32f2f" }} />
            </IconButton>
          </Box>
          <Text variant="h4">Uploaded on {date}</Text>
        </Box>
      </Box>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onConfirm={handleDelete}
        title={"Are you sure want to delete resume?"}
      />
    </Card>
  );
};

export default UploadResume;
