import React, { useEffect, useState } from "react";
import GRAPHIC from "../../../Images/education-details.svg";
import {
  Button,
  CustomRadio,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import { AuthLayout } from "../../../Components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { getExperience, saveExperience } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import moment from "moment";
import { EmploymentSchema } from "./validationSchema";
import { PROFILE } from "../../../RouteConstant";
import { getYears } from "../../../constant";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const monthsNameArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthsArr = Array(12)
  .fill("")
  .map((_item, index: any) => {
    return {
      value: (index + 1).toString(),
      text: monthsNameArr[index],
    };
  });

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <>
      <Text sx={colorObj} variant="body1">
        Dont forget to mention Who really{" "}
        <Text
          sx={{ ...colorObj, fontWeight: 600 }}
          variant="body2"
          component="span"
        >
          valued you!
        </Text>
      </Text>

      <Text sx={{ ...colorObj, mt: 1 }} variant="body1">
        mention{" "}
        <Text
          variant="body2"
          sx={{ ...colorObj, fontWeight: 600 }}
          component="span"
        >
          Employer
        </Text>{" "}
        details.
      </Text>
    </>
  ),
};

const Experience = () => {
  const navigate = useNavigate();
  const [startYears, setStartYears] = useState<any>([]);
  const [endYears, setEndYears] = useState<any>([]);
  const { id } = useParams();
  const [data, setData] = useState<{
    company_name: string;
    location: string;
    designation: string;
    job_type: string;
    start_month: string;
    start_year: string;
    end_month: string;
    end_year: string;
    currently_working: boolean;
    description: string;
  }>({
    company_name: "",
    location: "",
    designation: "",
    job_type: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
    currently_working: false,
    description: "",
  });

  useEffect(() => {
    if (id) {
      getExperience(id, onSuccess);
    }
  }, [id]);
  useEffect(() => {
    const tempYears = getYears();
    setStartYears(tempYears);
    setEndYears(tempYears);
  }, []);
  const onSuccess = (res: any) => {
    if (res.employment.start_date) {
      res.employment.start_month = moment(res.employment.start_date).format(
        "M"
      );
      res.employment.start_year = moment(res.employment.start_date).format("Y");
    }
    res.employment.currently_working =
      res.employment.currently_working === 1 ? true : false;

    if (res.employment.end_date) {
      res.employment.end_month = moment(res.employment.end_date).format("M");
      res.employment.end_year = moment(res.employment.end_date).format("Y");
    }
    setData(res.employment);
  };

  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Employment Details
      </Text>

      <Form
        validateOnMount={true}
        initialValues={data}
        validationSchema={EmploymentSchema}
        enableReinitialize={true}
        onSubmit={(values: any) => {
          let payload: any = {};
          payload.company_name = values.company_name;
          payload.currently_working = values.currently_working;
          payload.location = values.location;
          payload.designation = values.designation;
          payload.job_type = values.job_type;
          payload.start_month = values.start_month;
          payload.start_year = values.start_year;
          if (values.description) {
            payload.description = values.description;
          }
          if (values.end_month) {
            payload.end_month = values.end_month;
          }
          if (values.end_year) {
            payload.end_year = values.end_year;
          }
          if (id) {
            payload.job_seeker_experience_id = id;
          }
          return new Promise((res: any) => {
            saveExperience(
              payload,
              (error: any) => {
                res("");
              },
              (response: any) => {
                handleToast(response.message, success);
                res("");
                navigate(PROFILE);
              }
            );
          });
        }}
        render={({
          values,
          handleChange,
          isValid,
          submitForm,
          isSubmitting,
          dirty,
          setFieldValue,
        }: any) => {
          return (
            <>
              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Organisation/Company Name"
                required
                name="company_name"
              />

              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Location"
                required
                name="location"
              />

              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                label="Designation/Position Name"
                required
                name="designation"
              />

              <CustomRadio
                sx={{ mb: 3 }}
                label="Job Type"
                name="job_type"
                id="job_type"
                items={[
                  {
                    value: "full_time",
                    label: "Full Time",
                  },
                  {
                    value: "internship",
                    label: "Internship",
                  },
                  {
                    value: "freelance",
                    label: "Freelance",
                  },
                ]}
              />

              <Box sx={{ mb: 3 }}>
                <FormLabel
                  sx={{ "& .MuiFormLabel-asterisk": { color: "red" } }}
                  required
                >
                  From
                </FormLabel>

                <Box my={2} display="flex" columnGap={3}>
                  <SelectBox
                    required
                    id="start_month"
                    items={monthsArr}
                    label="Month"
                    name="start_month"
                  />

                  <SelectBox
                    required
                    id="start_year"
                    items={startYears}
                    label="Year"
                    name="start_year"
                  />
                </Box>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="currently_working"
                        checked={values.currently_working}
                        onChange={(e: any) =>
                          setFieldValue(
                            "currently_working",
                            e.target.checked ? 1 : 0
                          )
                        }
                        required
                      />
                    }
                    label="Currently working here"
                  />
                </FormGroup>
              </Box>

              {!values.currently_working && (
                <Box sx={{ mb: 3 }}>
                  <FormLabel
                    sx={{ "& .MuiFormLabel-asterisk": { color: "red" } }}
                    required
                  >
                    To
                  </FormLabel>

                  <Box mt={2} display="flex" columnGap={3}>
                    <SelectBox
                      required
                      id="end_month"
                      items={monthsArr}
                      label="Month"
                      name="end_month"
                    />

                    <SelectBox
                      required
                      id="end_year"
                      items={endYears}
                      label="Year"
                      name="end_year"
                    />
                  </Box>
                </Box>
              )}

              <InputBox
                fullWidth
                name="description"
                inputProps={{ maxLength: 2000 }}
                sx={{ mb: 3 }}
                multiline
                helperText={`${
                  values?.description?.length ? values?.description?.length : 0
                }/2000 Characters`}
                maxRows={10}
                placeholder="Write here something about your roles and responsibilities..."
                onChange={handleChange}
                label="Description"
              />

              <Button
                disabled={isSubmitting || id ? !(isValid && dirty) : !isValid}
                onClick={isValid ? submitForm : () => {}}
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default Experience;
