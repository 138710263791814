import { Button, Card, Form, InputBox, Text } from "@lipihipi/ui-components";
import { Box, InputAdornment } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from "react-router-dom";
import { JOBS_SEARCH } from "../../RouteConstant";

const QuickSearch = ({ mb = 3, searchKeyword }: any) => {
  const navigate = useNavigate();
  return (
    <Box mb={mb}>
      <Text sx={{ mb: 2 }} variant="h3">Search for Job</Text>
      <Card sx={{ p: 0 }} disableHeader>
        <Box px={2} py={2}>
          <Form
            initialValues={{ search: searchKeyword ? searchKeyword : '' }}
            onSubmit={(values: any) => {
              navigate({
                pathname: JOBS_SEARCH,
                search: `?keyword=${values.search}`
              });
            }}
            render={({
              values,
              handleChange
            }: any) => {
              return (
                <Box
                  flex={1}
                  flexShrink={0}
                  display='flex'
                  rowGap={3}
                  flexDirection='column'
                >
                  <InputBox
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:after': {
                          borderColor: '#00C385',
                        }
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    name='search'
                    value={values.search}
                    placeholder="Type here..."
                    onChange={handleChange}
                    label="Search for Job"
                  />

                  <Button type="submit" variant="outlined" color="secondary">
                    Submit
                  </Button>
                </Box>
              )
            }}
          />
        </Box>
      </Card>
    </Box>
  )
};

export default QuickSearch;