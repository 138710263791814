import {
  Button,
  PrimaryList,
  Card,
  DefaultInfoCard,
  Text,
  ConfirmationModal,
  useSmartPhone,
} from "@lipihipi/ui-components";
import { ProjectIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import certificate from "../../../Images/certificate.svg";
import { deleteProject } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { EDIT_PROJECT, PROJECT } from "../../../RouteConstant";
import { useRef, useState } from "react";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const ProjectDetails = ({ projects, setToggle }: any) => {
  const isSmartPhone = useSmartPhone();
  const navigate = useNavigate();
  const selected = useRef<Record<string, any> | null>(null);
  const [open, setOpen] = useState(false);
  if (projects?.length === 0) {
    return (
      <DefaultInfoCard icon={<ProjectIcon />}>
        <Text>This brings happiness and motivation in you!</Text>
        <Text
          sx={{
            color: "#292929",
          }}
        >
          <Text sx={{ fontSize: "20px", fontWeight: "400" }} component="span">
            Add Projects / Assignments
          </Text>
        </Text>
        <Button
          color="primary"
          sx={{ mt: 3, mx: "auto" }}
          variant="outlined"
          onClick={() => navigate(PROJECT)}
        >
          Add Now
        </Button>
      </DefaultInfoCard>
    );
  }

  const handleDelete = () => {
    deleteProject(selected?.current?.id, (response: any) => {
      handleToast(response.message, success);
      setTimeout(() => setToggle((prev: any) => !prev));
    });
  };
  return (
    <Card
      action={{
        icon: null,
        text: isSmartPhone ? 'Add' : "Add More",
      }}
      onClick={() => navigate(PROJECT)}
      heading="Projects / Assignments"
    >
      {projects?.map((project: any) => {
        return (
          <>
            <PrimaryList
              isSecondary
              icon={<img src={certificate} alt="" />}
              onClickDelete={() => {
                selected.current = project;
                setOpen(true);
              }}
              onClickEdit={() =>
                navigate(EDIT_PROJECT.replace(":id", project.id))
              }
              chip={{
                color: "warning",
                label: "Under Process",
              }}
              data={{
                heading: project.project_name,
                primaryText: `Role - ${project.role}`,
                secondaryText: `Technology - ${project.technology}`,
              }}
              additionalText={
                project?.porject_desc
                  ? `Description - ${project?.porject_desc}`
                  : ""
              }
            />
          </>
        );
      })}
      <ConfirmationModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={handleDelete}
        title={"Are you sure want to delete project?"}
      />
    </Card>
  );
};

export default ProjectDetails;
