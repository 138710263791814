import { Button, Card, DefaultInfoCard, Text, useSmartPhone } from "@lipihipi/ui-components";
import { EditIcon, JobIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { SELF_INTRODUCTION } from "../../../RouteConstant";


const SelfIntroduction = ({ about }: any) => {
  const navigate = useNavigate();
  const isSmartPhone = useSmartPhone();

  if (about === "") {
    return (
      <DefaultInfoCard icon={<JobIcon />}>
        <Text>Write few words about you, this will be</Text>

        <Text>shown on the profile</Text>
        <Button
          color="primary"
          onClick={() => navigate(SELF_INTRODUCTION)}
          sx={{ mt: 3, mx: "auto" }}
          variant="outlined"
        >
          Add Now
        </Button>
      </DefaultInfoCard>
    );
  }
  return (
    <Card 
      onClick={() => navigate(SELF_INTRODUCTION)} 
      heading="About me"
      action={{
        icon: <EditIcon style={{ marginRight: 8 }} />,
        text: isSmartPhone ? 'Edit' : 'Edit Details'
      }}
    >
      <Box display="flex" flexDirection="column" rowGap={1.5}>
        <Text>{about}</Text>
      </Box>
    </Card>
  );
};

export default SelfIntroduction;
