import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  EventsIcon,
  InterviewIcon,
  JobSecondaryIcon,
  NotVerifiedIcon,
  ProfileSecondaryIcon,
  ReferIcon,
  ResumeSecondaryIcon,
  SubscriptionIcon,
} from "../../Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { VerifiedIcon } from "../../Icons";
import { Text } from "@lipihipi/ui-components";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ProfileDrawer from "../../Pages/Profile/ProfileDrawer";
import { AppContext } from "../../App";
import * as routePaths from "../../RouteConstant";
import { getUserMe } from "../../Services/Service";
import { ActionType } from "../../Reducer";
const IMAGE_URL =
  "https://img.freepik.com/free-photo/digital-painting-mountain-with-colorful-tree-foreground_1340-25699.jpg?w=1800&t=st=1685086330~exp=1685086930~hmac=cb36a2268ce6f2f5b755cb139970b65eac99364324aa4ba0c20c862543fb02cb";

const ARR = [
  // {
  //   id: 0,
  //   title: 'Home',
  //   link: '/',
  //   iconDefault: <HomeIcon />,
  //   iconActive: <HomeIcon color='#2378DB' />,
  // },
  {
    id: 1,
    title: "Jobs",
    link: routePaths.MY_JOBS,
    iconDefault: <JobSecondaryIcon />,
    iconActive: <JobSecondaryIcon color="#2378DB" />,
    child: [
      {
        id: 1.1,
        title: "Recommended jobs",
        link: routePaths.MY_JOBS,
      },
      {
        id: 1.2,
        title: "My Applied Jobs",
        link: routePaths.APPLIED_JOBS,
      },
      {
        id: 1.3,
        title: "Saved Jobs",
        link: routePaths.SAVED_JOBS,
      },
    ],
  },
  {
    id: 2,
    title: "Profile",
    link: routePaths.PROFILE,
    iconDefault: <ProfileSecondaryIcon />,
    iconActive: <ProfileSecondaryIcon color="#2378DB" />,
  },
  {
    id: 3,
    title: "Refer & Earn",
    link: routePaths.REFER,
    iconDefault: <ReferIcon />,
    iconActive: <ReferIcon color="#2378DB" />,
  },
  {
    id: 4,
    title: "Events",
    link: routePaths.EVENTS,
    iconDefault: <EventsIcon />,
    iconActive: <EventsIcon color="#2378DB" />,
  },
  // {
  //   id: 5,
  //   title: 'KYC/Verification',
  //   link: routePaths.KYC,
  //   iconDefault: <KycIcon />,
  //   iconActive: <KycIcon color='#2378DB' />,
  // },
  {
    id: 6,
    title: "Subscription",
    link: routePaths.SUBSCRIPTION,
    iconDefault: <SubscriptionIcon />,
    iconActive: <SubscriptionIcon color="#2378DB" />,
  },
  // {
  //   id: 6,
  //   title: 'Courses',
  //   link: '/courses',
  //   iconDefault: <CoursesIcon />,
  //   iconActive: <CoursesIcon color='#2378DB' />,
  // },
  // {
  //   id: 7,
  //   title: 'Mentor',
  //   link: '/mentor',
  //   iconDefault: <MentorIcon />,
  //   iconActive: <MentorIcon color='#2378DB' />,
  // },
  {
    id: 8,
    title: "Mock Interviews",
    link: routePaths.MOCK,
    iconDefault: <InterviewIcon />,
    iconActive: <InterviewIcon color="#2378DB" />,
  },
  {
    id: 9,
    title: "Resume Templates",
    link: routePaths.RESUME,
    iconDefault: <ResumeSecondaryIcon />,
    iconActive: <ResumeSecondaryIcon color="#2378DB" />,
  },
];

interface ISidebar {
  currentActive: number;
}

export const Sidebar: FC<ISidebar> = ({ currentActive }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext<any>(AppContext);
  const [id, setId] = useState(0);
  const [profileImage, setProfileImage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [activeItems, setActiveItems] = useState<number[]>([]);
  const { state } = useContext<any>(AppContext);
  const handleClick = (currId: any) => {
    setActiveItems((prevActiveItems) =>
      prevActiveItems.includes(currId)
        ? prevActiveItems.filter((item) => item !== currId)
        : [...prevActiveItems, currId]
    );
  };
  const onUserSuccess = (data: any) => {
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: data.user,
    });
    if (location) {
      navigate(location.pathname);
    }
  };
  const userFailure = (resp: any) => {
    // console.log(resp);
    dispatch({
      type: ActionType.SET_LOADING_FLAG,
      payload: false,
    });
  };
  useEffect(() => {
    getUserMe(onUserSuccess, userFailure);
  }, [toggle]);
  useEffect(() => {
    setId(currentActive);
    setActiveItems((prevActiveItems) =>
      prevActiveItems.includes(currentActive)
        ? prevActiveItems
        : [...prevActiveItems, currentActive]
    );
  }, [currentActive]);

  const classes = {
    root: {
      display: "flex",
      flexDirection: "column",
      background: "#FDFDFD",
      boxShadow: "0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)",
      borderRadius: "0px 0px 8px 8px",

      "& .MuiListItem-root": {
        paddingTop: 0.25,
        paddingBottom: 0.25,
      },

      "& a": {
        display: "flex",
        padding: "10px 15px",
        alignItems: "center",
        columnGap: 1.5,
        fontWeight: 500,
        textDecoration: "none",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.005em",
        borderRadius: "8px",
      },
    },
  };

  const location = useLocation();

  // const profileImage = state.user?.image ? `${ASSET_URL}/${state.user?.image}` : IMAGE_URL;

  // const userVerified = state.user?.verified_user === 1;

  return (
    <Box>
      <Box
        textAlign="center"
        py={3}
        px={2}
        sx={{
          borderRadius: "8px 8px 0 0",
          borderTop: `6px solid ${
            parseInt(state.user?.verified_user) ? "#00C385" : "#EB5757"
          }`,
          backgroundColor: "#EBF4FF",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mx="auto"
          mb={2}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${
              state.user?.image ? `${state?.user?.image}` : IMAGE_URL
            })`,
            height: "150px",
            border: "6px solid #FFFFFF",
            backgroundColor: "#EBF4FF",
            borderRadius: "50%",
            width: "150px",
          }}
        >
          <IconButton
            onClick={() => setProfileImage(true)}
            color="primary"
            sx={{ background: "#ffffff", "&:hover": { background: "#ffffff" } }}
          >
            <CameraAltOutlinedIcon htmlColor="#0C4C97" />
          </IconButton>
        </Box>
        <Text sx={{ mb: 1 }} variant="h3">
          {state?.user?.fname} {state.user?.lname}
        </Text>
        <Text variant="subtitle2">{state?.user?.designation}</Text>

        <Box
          display="flex"
          mt={2}
          justifyContent="center"
          columnGap={0.5}
          alignItems="center"
        >
          {parseInt(state?.user?.verified_user) ? (
            <VerifiedIcon />
          ) : (
            <NotVerifiedIcon />
          )}
          <Text
            onClick={() => navigate(routePaths.SUBSCRIPTION)}
            sx={{
              color: parseInt(state.user?.verified_user) ? "#00C385" : "#F9344C",cursor:'pointer'
            }}
          >
            {parseInt(state?.user?.verified_user)
              ? "Verified"
              : "Not Verified yet"}
          </Text>
        </Box>
      </Box>
      <Box py={1} sx={classes.root}>
        <List component="nav">
          {ARR.map((obj) => {
            const isItemActive = activeItems.includes(obj.id);

            if (obj?.child) {
              return (
                <Fragment key={obj.id}>
                  <ListItem>
                    <ListItemButton
                      selected={isItemActive}
                      onClick={() => handleClick(obj.id)}
                    >
                      <ListItemIcon>
                        {id === obj.id
                          ? obj.iconActive
                          : obj.iconDefault}
                      </ListItemIcon>
                      <ListItemText primary={obj?.title} />
                      {isItemActive ? (
                        <ExpandLess sx={{ color: "#2378DB" }} />
                      ) : (
                        <ExpandMore
                          sx={{ color: id === obj.id ? "#2378DB" : "#858585" }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={isItemActive} timeout="auto" unmountOnExit>
                    <List component="div">
                      {obj?.child?.map((child) => (
                        <ListItem key={child?.id}>
                          <ListItemButton
                            selected={child?.link === location.pathname}
                            onClick={() => {
                              navigate(child?.link);
                            }}
                            sx={{ pl: 6 }}
                          >
                            <ListItemText primary={child?.title} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              );
            }
            return (
              <ListItem key={obj.id}>
                <ListItemButton
                  disableRipple
                  onClick={() => {
                    setId(obj.id);
                    navigate(obj.link);
                  }}
                  selected={isItemActive}
                >
                  <ListItemIcon>
                    {id === obj.id ? obj.iconActive : obj.iconDefault}
                  </ListItemIcon>
                  <ListItemText primary={obj.title} />
                  {id === obj.id && (
                    <NavigateNextOutlinedIcon
                      style={{ marginLeft: "auto", color: "#2378DB" }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <ProfileDrawer
        open={profileImage}
        setOpen={setProfileImage}
        previewPic={state.user?.image ? state?.user?.image : IMAGE_URL}
        setToggle={setToggle}
      />
    </Box>
  );
};
