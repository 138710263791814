import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Button, Card, Text } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import vars from "../../theme/variables";
import { fetchEvents } from "../../Services/Service";
import QuickSearch from "../QuickSearch";
import { APPLIED_JOBS, EVENTS } from "../../RouteConstant";
const { primaryFont } = vars;
const RightSection = (props: any) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<any>([]);
  const { state } = useContext<any>(AppContext);
  useEffect(() => {
    fetchEvents((response: any) => {
      setEvents(response.data);
    });
  }, []);
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <QuickSearch mb={0} />

      <Box>
        <Card sx={{ p: 2 }} disableHeader>
          <Text variant="subtitle2">Track my application</Text>

          <Text variant="caption" component="p" sx={{ mt: 0.5 }}>
            You have applied{" "}
            <Text sx={{ fontWeight: 600, color: "#00C385" }} component="strong">
              {props.appliedJobCount ?? 0} jobs
            </Text>{" "}
          </Text>

          <Button
            onClick={() => navigate(APPLIED_JOBS)}
            sx={{ mt: 2 }}
            variant="outlined"
          >
            View All
          </Button>
        </Card>
      </Box>

      <Box>
        <Card sx={{ p: 2 }} disableHeader>
          <Text variant="subtitle2">Your Profile got</Text>

          <Text variant="caption" component="p" sx={{ mt: 0.5 }}>
            <Text sx={{ fontWeight: 600, color: "#00C385" }} component="strong">
              {state?.user?.wallet?.month_views} views
            </Text>{" "}
            in last 30 days
          </Text>
        </Card>
      </Box>

      {events?.length > 0 && (
        <Box>
          <Card sx={{ p: 0 }} disableHeader>
            <Text sx={{ px: 2, pt: 2 }} variant="subtitle2">
              My Events
            </Text>

            <List>
              {events?.slice(0, 1)?.map((event: any, index: any) => (
                <>
                  {index !== 0 && <Divider variant="inset" component="li" />}
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          fontFamily: primaryFont,
                          color: "#00c385",
                          backgroundColor: "#e0faf1",
                        }}
                      >
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${event?.company_id?.company_name} has Scheduled your interview for ${event?.job?.job_title} profile, and venue is ${event?.interview_venue}.`}
                      secondary={`—  ${event?.interview_date} at ${event?.interview_time}`}
                    />
                  </ListItem>
                </>
              ))}
            </List>

            <Box px={2} pb={2}>
              <Button
                fullWidth
                onClick={() => navigate(EVENTS)}
                sx={{ mt: 2 }}
                variant="outlined"
              >
                View All
              </Button>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
};
export default RightSection;
