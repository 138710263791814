import { Box } from "@mui/material";
import { Text, Button } from "@lipihipi/ui-components";
import IMAGE from "../../Images/block.jpg";
import { LOGIN } from "../../RouteConstant";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      <img height={350} src={IMAGE} alt="" />
      <Text variant="h2">
        Access Denied/Wrong URL
      </Text>

      <Button variant="contained" onClick={()=>navigate(LOGIN)}>Go Back</Button>
    </Box>
  )
};

export default AccessDenied;