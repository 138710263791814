import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume6: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            width: "30%",
            textAlign: "right",
            padding: 30,
            borderRight: "2px solid #eee",
            verticalAlign: "top",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <img
              style={{
                width: 120,
                height: 120,
                display: "inline-block",
                borderRadius: 10,
                border: "6px solid rgb(255, 255, 255)",
              }}
              src={data?.user?.image}
              alt=""
            />
          </div>
          <div style={{ marginTop: 30, paddingLeft: 0 }}>
            <h3
              style={{
                lineHeight: "normal",
                margin: 0,
                paddingBottom: 8,
                fontSize: 24,
                borderBottom: "2px solid #eee",
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                fontWeight: 600,
                color: "#2f3847",
              }}
            >
              Contact
            </h3>
            <div style={{ marginTop: 16 }}>
              <div style={{ paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 12px",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16, paddingRight: 0 }}>
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily:
                      "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 12px",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 0 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #eee",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Education
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        paddingRight: 8,
                        marginTop: index === 0 ? 0 : 16,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                          color: "#2f3847",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.educationType.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                          color: "#2f3847",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.schoolName.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#2f3847",
                          fontSize: 12,
                          margin: "6px 0 12px",
                        }}
                      >
                        <span>Percentage:</span> {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        | <span>Year:</span> {education.passedYear}2017
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 0 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #eee",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Expertise
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 12,
                        marginTop: 8,
                        marginLeft: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {skill.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 0 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #eee",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 12,
                        marginTop: 8,
                        marginLeft: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {hobby}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 30, paddingLeft: 0 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #eee",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 8 }}>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none",
                    lineHeight: "normal",
                    margin: 0,
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "#fff",
                    fontSize: 0,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        fontSize: 12,
                        marginTop: 8,
                        marginLeft: 8,
                        borderRadius: 4,
                        padding: "6px 12px",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      {language}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td style={{ width: "70%", padding: 30, verticalAlign: "top" }}>
          <h1
            style={{
              lineHeight: "normal",
              margin: 0,
              fontSize: 36,
              fontFamily:
                "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
              fontWeight: 300,
              color: "#2f3847",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
              }}
            >
              {data.resume?.firstName}{" "}
            </span>
            {data.resume?.lastName}
          </h1>
          <p
            style={{
              margin: "10px 0 0",
              fontSize: 18,
              color: "#2f3847",
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
          >
            {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
          </p>
          {data.resume?.objective?.length ? (
            <p
              style={{
                margin: "32px 0 0",
                fontSize: 14,
                lineHeight: "170%",
                color: "#707070",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {data.resume?.objective}
            </p>
          ) : null}
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 50 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #2f3847",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Work Experience
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.experiences?.map((experience, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        color: "#2f3847",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {experience.position}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 12px",
                      }}
                    >
                      {index === 0 ? (
                        <>
                          {`${experience.companyName} | ${experience.startDate} - `}
                          <strong style={{ fontWeight: "bold" }}>
                            {experience.endDate !== null
                              ? experience.endDate
                              : "Present"}
                          </strong>
                        </>
                      ) : (
                        <>{`${experience.companyName} | ${
                          experience.startDate
                        } - ${
                          experience.endDate !== null
                            ? experience.endDate
                            : "Present"
                        }`}</>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 50 }}>
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  paddingBottom: 8,
                  fontSize: 24,
                  borderBottom: "2px solid #2f3847",
                  fontFamily:
                    "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                  fontWeight: 600,
                  color: "#2f3847",
                }}
              >
                Projects
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.projects?.map((project, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 0 : 30 }}>
                    <p
                      style={{
                        fontWeight: 600,
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        color: "#2f3847",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {project?.projectName}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "8px 0 12px",
                      }}
                    >
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Technology:
                      </span>
                      {project?.technology} |
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Team Size:
                      </span>
                      {project?.teamSize} |
                      <span style={{ fontWeight: 500, color: "#2f3847" }}>
                        Year:
                      </span>
                      {project?.year}
                    </p>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#707070",
                        fontSize: 14,
                        margin: "6px 0 0",
                        lineHeight: "170%",
                      }}
                    >
                      {project?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume6;
