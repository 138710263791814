import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume9: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td style={{ padding: "50px 0" }}>
          <div style={{ marginTop: 0 }}>
            <div style={{ fontSize: 0 }}>
              <div
                style={{
                  display: "inline-block",
                  width: "25%",
                  padding: "0 25px 0 0",
                }}
              >
                <div style={{ textAlign: "right" }}>
                  <img
                    style={{
                      width: 100,
                      height: 100,
                      display: "inline-block",
                      borderRadius: 6,
                    }}
                    src={data?.user?.image}
                    alt=""
                  />
                </div>
              </div>
              <div
                style={{
                  width: "75%",
                  display: "inline-block",
                  verticalAlign: "top",
                  padding: "0 0 0 25px",
                }}
              >
                <h1
                  style={{
                    lineHeight: "normal",
                    margin: 0,
                    fontSize: 36,
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    fontWeight: 300,
                    color: "#000",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    }}
                  >
                    {data.resume?.firstName}{" "}
                  </span>
                  {data.resume?.lastName}
                </h1>
                <p
                  style={{
                    margin: "4px 0 0",
                    fontSize: 18,
                    color: "#000",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}
                >
                  {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
                </p>
                <div style={{ marginTop: 16 }}>
                  <div style={{ paddingRight: 0 }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#000",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {data?.resume?.mobile}
                    </p>
                  </div>
                  <div style={{ marginTop: 6, paddingRight: 0 }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#000",
                        fontSize: 16,
                        margin: 0,
                      }}
                    >
                      {data?.resume?.emailId}
                    </p>
                  </div>
                  <div style={{ marginTop: 6, paddingRight: 0 }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "#000",
                        fontSize: 16,
                        margin: 0,
                        lineHeight: "170%",
                      }}
                    >
                      {data?.resume?.address}, {data?.resume?.city},{" "}
                      {data?.resume?.pincode}
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontSize: 16,
                    lineHeight: "170%",
                    color: "#707070",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}
                >
                  {data.resume?.objective}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.experiences?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Work Experience
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  {data.resume?.experiences?.map((experience, index) => (
                    <div>
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#000",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {experience.position}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 16,
                          margin: "6px 0 12px",
                        }}
                      >
                        {index === 0 ? (
                          <>
                            {`${experience.companyName} | ${experience.startDate} - `}
                            <strong style={{ fontWeight: "bold" }}>
                              {experience.endDate !== null
                                ? experience.endDate
                                : "Present"}
                            </strong>
                          </>
                        ) : (
                          <>{`${experience.companyName} | ${
                            experience.startDate
                          } - ${
                            experience.endDate !== null
                              ? experience.endDate
                              : "Present"
                          }`}</>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.educations?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Education
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  {data.resume?.educations?.map(
                    (education: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginTop: index === 0 ? 0 : 16 }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            fontFamily:
                              "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            color: "#000",
                            fontSize: 16,
                            textTransform: "capitalize",
                            margin: 0,
                          }}
                        >
                          {education.educationType.toLowerCase()}
                        </p>
                        <p
                          style={{
                            fontWeight: 600,
                            fontFamily:
                              "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            color: "#000",
                            fontSize: 16,
                            textTransform: "capitalize",
                            margin: 0,
                          }}
                        >
                          {education.schoolName.toLowerCase()}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            fontFamily: "Arial, Helvetica, sans-serif",
                            color: "#707070",
                            fontSize: 16,
                            margin: "6px 0 12px",
                          }}
                        >
                          <span>Percentage:</span> {education.gradingValue}{" "}
                          {education.gradingSystems === "Percentage"
                            ? "%"
                            : "CGPA"}{" "}
                          | <span>Year:</span> {education.passedYear}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Expertise
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.skills?.map((skill, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 14,
                          marginBottom: 8,
                          marginRight: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {skill.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Hobbies
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.hobbies?.map((hobby, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 14,
                          marginBottom: 8,
                          marginRight: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {hobby}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Language
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  <ul
                    style={{
                      padding: 0,
                      listStyleType: "none",
                      lineHeight: "normal",
                      margin: 0,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      color: "#fff",
                      fontSize: 0,
                    }}
                  >
                    {data.resume?.languageKnown.map((language, index) => (
                      <li
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          fontSize: 14,
                          marginBottom: 8,
                          marginRight: 8,
                          borderRadius: 4,
                          padding: "6px 12px",
                          display: "inline-block",
                        }}
                        key={index}
                      >
                        {language}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {data.resume?.projects?.length ? (
            <div style={{ marginTop: 50 }}>
              <div style={{ fontSize: 0 }}>
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    padding: "0 25px 0 0",
                  }}
                >
                  <h3
                    style={{
                      lineHeight: "140%",
                      margin: 0,
                      textAlign: "right",
                      fontSize: 24,
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Projects
                  </h3>
                </div>
                <div
                  style={{
                    width: "75%",
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "0 0 0 25px",
                  }}
                >
                  {data.resume?.projects?.map((project, index) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 600,
                          fontFamily:
                            "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                          color: "#000",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {project?.projectName}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 16,
                          margin: "8px 0 12px",
                        }}
                      >
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Technology:
                        </span>
                        {project?.technology} |
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Team Size:
                        </span>
                        {project?.teamSize} |
                        <span style={{ fontWeight: 500, color: "#000" }}>
                          Year:
                        </span>
                        {project?.year} Days
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#707070",
                          fontSize: 16,
                          margin: "6px 0 0",
                          lineHeight: "170%",
                        }}
                      >
                        {project?.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume9;
