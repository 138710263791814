import { AppleIcon, FacebookIcon, GoogleIcon } from "../../Icons";
import { socialLoginObject, passwordValidationObj } from "./types";

export const passwordValidation: passwordValidationObj[] = [
  { id: 0, active: false, isCurrent: false, title: 'Create Password' },
  { id: 1, active: false, isCurrent: false, title: 'Verify Email' },
  { id: 2, active: false, isCurrent: false, title: 'General Information' },
  { id: 3, active: false, isCurrent: false, title: 'Verify Phone' },
];

export const SocialLoginArr: socialLoginObject[]  = [
  {
    id: 'google',
    borderColor: '#DCDCDC',
    color: '#757575',
    backgroundColor: '#ffffff',
    text: 'Continue with Google',
    Icon: GoogleIcon
  },
  {
    id: 'apple',
    borderColor: '#000000',
    color: '#ffffff',
    backgroundColor: '#000000',
    text: 'Continue with Apple',
    Icon: AppleIcon
  },
  // {
  //   id: 2,
  //   borderColor: '#1877F2',
  //   color: '#fff',
  //   backgroundColor: '#1877F2',
  //   text: 'Continue with Facebook',
  //   Icon: FacebookIcon
  // },
]

export enum userLogin {
  Init='Init',
  Login='Login',
  Register='Register',
}