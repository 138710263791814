import React from "react";
import NOT_FOUND from "../../Images/not-found.svg";
import { Box,  } from "@mui/material";
import { Button, Text } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../RouteConstant";

const PageNotFound = () => {
  const navigate = useNavigate();
  const classes = {
    root: {
      display: 'flex',
      minHeight: '100vh',
      background: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& img': {
        maxWidth: '400px',
      }
    }
  }
  return (
    <Box sx={classes.root}>
      <img src={NOT_FOUND} alt="Not Found" />
      <Text sx={{mb:1}} variant="h2">Page Not Found!</Text>
      <Text variant="body2">
        We're sorry, the page you requested could not found
      </Text>
      <Text variant="body2">
        Please go back to Home
      </Text>

      <Box display="flex" gap={1}>
        <Button onClick={() => navigate(-1)} sx={{ mt: 3 }} variant="contained" color="primary">Back</Button>
        <Button onClick={() => navigate(LOGIN)} sx={{mt: 3}} variant="outlined" color="primary">Home</Button>
      </Box>
    </Box>
  )
};

export default PageNotFound;