import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/skills.svg";
import { Button, Chips, Form, InputBox, Text, useFormikContext } from "@lipihipi/ui-components";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../../Icons";
import { useEffect, useState } from "react";
import { fetchSkills, saveSkills } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { PROFILE } from "../../../RouteConstant";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <>
      <Text sx={colorObj} variant="body1">
        This is{" "}
        <Text
          sx={{ ...colorObj, fontWeight: 600 }}
          variant="body2"
          component="span"
        >
          my arena, I am best at.
        </Text>
      </Text>
      <Text sx={{ ...colorObj, mt: 0.5 }} variant="body1">
        This describe me in crisp and help{" "}
        <Text
          variant="body2"
          sx={{ ...colorObj, fontWeight: 600 }}
          component="span"
        >
          recruiter approach faster.
        </Text>
      </Text>
    </>
  ),
};

const EditSkills = () => {
  const navigate = useNavigate();
  const [skillExist, setSkillExist] = useState(false);
  const [skills, setSkills] = useState<any>([]);
  
  useEffect(() => {
    fetchSkills((response: any) => {
      if (response.skills.skills) {
        setSkills(response?.skills?.skills.split(", "));
      }
    });
  }, []);

  const handleSubmit = () => {
    saveSkills({ skills: skills?.join(", ") }, (response: any) => {
      if (response?.success) {
        handleToast(response?.message, success);
        navigate(PROFILE);
      }
    });
  };

  const handleDelete = (skill: any) => {
    setSkills((prevSkills: any) => {
      return prevSkills?.filter((prev: any) => prev !== skill);
    });
  };

  const handleKeyDown = (e: any,values:any,resetForm:any) => {
    if (e.key === ",") {
      e.preventDefault();
      if (skills.includes(values.skill)) {
        setSkillExist(true);
      } else {
        setSkills((prevSkill: any) => {
          return [...prevSkill, values.skill];
        });
        setSkillExist(false);
        resetForm()
      }
    }
  };
  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h1"
      >
        Please enter your Professional Skills
      </Text>

      <Box display="flex" gap={1.5} flexWrap="wrap" mb={5}>
        {skills.map((skill: any) => (
          <Chips
            deleteIcon={
              <IconButton size="small" color="primary">
                <CrossIcon />
              </IconButton>
            }
            onDelete={() => handleDelete(skill)}
            label={skill}
          />
        ))}
      </Box>

      <Form
        initialValues={{ skill: "" }}
        onSubmit={(values: any, { resetForm }: any) => {
          if (values.skill.trim().length > 0) {
            if (skills.includes(values.skill)) {
              setSkillExist(true);
            } else {
              setSkills((prevSkill: any) => {
                return [...prevSkill, values.skill];
              });
              setSkillExist(false);
              resetForm();
            }
          }
        }}
        render={({ values, handleChange,resetForm }: any) => {
          return (
            <>
              <InputBox
                error={skillExist}
                fullWidth
                name="skill"
                value={values.skill}
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                onChange={handleChange}
                helperText={
                  skillExist
                    ? "Skill Already Exist!"
                    : "You can add multiple skills by enter"
                }
                label="Enter skills here"
                onKeyDown={(e)=>{
                  handleKeyDown(e,values,resetForm)
                }}
              />
              <Button
                onClick={handleSubmit}
                fullWidth
                disabled={skills?.length === 0}
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default EditSkills;
