import CloseIcon from "@mui/icons-material/Close";
import handleToast, { success } from "../../util/Toast";
import { saveCoverImage, saveProfileImage } from "../../Services/Service";
import { Box, Drawer, IconButton } from "@mui/material";
import { Button, Text, ImageCropper, Form } from "@lipihipi/ui-components";
import { remove, uploadPublic } from "../../Services/FileUploadService";
import * as Yup from "yup";

export const ImageSchema = Yup.object().shape({
    file: Yup.mixed().required("Image is required"),
});
const ProfileDrawer = ({
  setOpen,
  open,
  setToggle,
  previewPic,
  isCoverImage = false,
}: any) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: "50rem",
        },
      }}
      onClose={() => setOpen(false)}
    >
      <Box
        borderBottom="1px solid #eee"
        px={3}
        py={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="h3">
          {isCoverImage ? "Upload Cover Image" : "Upload Profile Image"}
        </Text>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Form
      initialValues={{file:""}}
      validationSchema={ImageSchema}
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={(values: any) => {
          const handleCallback = (respose: any) => {
            if (respose.success) {
              handleToast(respose.message, success);
              setToggle((prev: any) => !prev);
              setOpen(false);
            } else {
              handleToast(respose.message);
            }
          };
          uploadPublic(values)
            .then((res: any) => {
              let payload: any = {};
              if (isCoverImage) {
                payload.cover_image = res?.data?.fileName;
                saveCoverImage(payload, handleCallback);
              } else {
                payload.image = res?.data?.fileName;
                saveProfileImage(payload, handleCallback);
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }}
        render={({isValid,isSubmitting,dirty}:any) => {
          return (
            <>
              <Box
                overflow="auto"
                height="calc(100vh - (97px + 73px))"
                p={3}
                sx={{
                  "& .row": {
                    display: "flex",
                    gap: "1rem",
                  },

                  "& .d-flex": {
                    display: "flex",
                  },

                  "& .justify-content-center": {
                    justifyContent: "center",
                  },

                  "& .align-items-center": {
                    alignItems: "center",
                  },

                  "& button": {
                    cursor: "pointer",
                  },

                  "& .col-md-6": {
                    flexGrow: 1,
                    minWidth: "30%",
                  },

                  "& > div": {
                    "& > div": {
                      padding: 0,
                      background: "transparent",
                      border: "none",
                      borderRadius: 0,
                    },
                  },
                }}
              >

                <Text variant="h4">Supported Formats: jpeg,png</Text>
                <ImageCropper
                  id="image"
                  name={"file"}
                  // width={isCoverImage ? 500 : 250}
                  previewPic={previewPic}
                  required
                  width={250}
                  ratio={isCoverImage ? 4 / 1 : 1 / 1}
                  accept={["image/*"]}
                  deleteFile={remove}
                />
              </Box>

              <Box
                borderTop="1px solid #eee"
                p={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                disabled={!isValid || isSubmitting}

                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </>
          );
        }}
      />
    </Drawer>
  );
};

export default ProfileDrawer;
