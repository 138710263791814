import React, { useContext, useEffect, useState } from "react";
import { PrimaryLayout } from "../../Components";
import { downloadResume, fetchResumeDetails } from "../../Services/Service";
import Resume1 from "./Preview/Resume1";
import { useParams } from "react-router-dom";
import { Button, Text } from "@lipihipi/ui-components";
import Resume2 from "./Preview/Resume3";
import Resume3 from "./Preview/Resume2";
import Resume4 from "./Preview/Resume4";
import NoDataFound from "../../Shared/NoData";
import Resume5 from "./Preview/Resume5";
import Resume10 from "./Preview/Resume10";
import Resume11 from "./Preview/Resume11";
import Resume12 from "./Preview/Resume12";
import Resume13 from "./Preview/Resume13";
import Resume14 from "./Preview/Resume14";
import Resume15 from "./Preview/Resume15";
import Resume16 from "./Preview/Resume16";
import Resume6 from "./Preview/Resume6";
import Resume7 from "./Preview/Resume7";
import Resume8 from "./Preview/Resume8";
import Resume9 from "./Preview/Resume9";
import { AppContext } from "../../App";

export interface IData {
  resume?: Resume;
  user?: User;
}

export interface Resume {
  firstName: string;
  lastName: string;
  emailId: string;
  job_type:string;
  mobile: string;
  gender: string;
  city: string;
  pincode: string;
  address: string;
  hobbies: any[];
  educations?: any[];
  experiences?: any[];
  skills?: Skill[];
  languageKnown?: any[];
  nationality?: any[];
  internships?: any[];
  objective: string;
  projects?: Project[];
}

export interface Education {
  schoolName?: string;
  passedYear?: number;
}

export interface Experience {
  companyName?: string;
  position?: string;
  startDate?: string;
  endDate?: any;
  isWorking?: number;
}

export interface Skill {
  name?: string;
}

export interface Project {
  projectName?: string;
  company?: string;
  technology?: string;
  url?: string;
  teamSize?: string;
  year?: string;
  description?: string;
}

export interface User {
  image: string;
}

const showResumePreview = (id: any, data: any) => {
  switch (id) {
    case "1":
      return <Resume1 data={data} />;
    case "3":
      return <Resume2 data={data} />;
    case "2":
      return <Resume3 data={data} />;
    case "4":
      return <Resume4 data={data} />;
    case "5":
      return <Resume5 data={data} />;
    case "6":
      return <Resume6 data={data} />;
    case "7":
      return <Resume7 data={data} />;
    case "8":
      return <Resume8 data={data} />;
    case "9":
      return <Resume9 data={data} />;
    case "10":
      return <Resume10 data={data} />;
    case "11":
      return <Resume11 data={data} />;
    case "12":
      return <Resume12 data={data} />;
    case "13":
      return <Resume13 data={data} />;
    case "14":
      return <Resume14 data={data} />;
    case "15":
      return <Resume15 data={data} />;
    case "16":
      return <Resume16 data={data} />;
    default:
      return <NoDataFound />;
  }
};

const ResumePreview = () => {
  const [data, setData] = useState<IData>({
    resume: {
      firstName: "",
      lastName: "",
      job_type:"",
      emailId: "",
      mobile: "",
      gender: "",
      city: "",
      pincode: "",
      address: "",
      hobbies: [],
      educations: [],
      experiences: [],
      skills: [],
      languageKnown: [],
      nationality: [],
      internships: [],
      objective: "",
      projects: [],
    },
    user: {
      image: "",
    },
  });
  const { state } = useContext<any>(AppContext);
  const { previewId } = useParams();

  useEffect(() => {
    fetchResumeDetails((response: any) => {
      setData(response.data);
    });
  }, []);
  const handleDownload = async () => {
    downloadResume(previewId, (response: any) => {
      const fileURL = URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      let tempLink = document.createElement("a");
      tempLink.href = fileURL;
      tempLink.setAttribute("target", "_blank");
      tempLink.click();
    });
  };

  return (
    <PrimaryLayout
      action={
        parseInt(state.user.profile_strength) === 100 && (
          <Button onClick={handleDownload} variant="contained">
            Download Resume
          </Button>
        )
      }
      pb={10}
    >
      {parseInt(state.user.profile_strength) !== 100 && (
        <Text variant="subtitle1" pb={3}>
          Your Profile Strength is {state.user.profile_strength} is below 100%.
          Please update your profile to download resume
        </Text>
      )}
      {showResumePreview(previewId, data)}
    </PrimaryLayout>
  );
};

export default ResumePreview;
