import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import { Back, Header } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../Icons";
import { CONTAINER_MAX_WIDTH } from "../../constant";

interface IPrimaryLayout {
  children: ReactNode;
  back?: boolean;
  pb?: number;
  action?: any;
}

export const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children, back = true, pb, action }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header maxWidth={CONTAINER_MAX_WIDTH} logo={<Logo style={{ cursor: 'pointer' }} />}>{action}</Header>
      <Box py={2.5} pb={pb ?? 2.5}>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          {back && <Box mb={2.5}><Back onClick={() => navigate(-1)} /></Box>}
          {children}
        </Container>
      </Box>
    </>
  )
}