import { FC, useCallback, useEffect, useState } from "react";
import { PrimaryLayout } from "../../Components";
import { Box } from "@mui/material";
import { Button, Text } from "@lipihipi/ui-components";
import { Tick } from "../../Icons";
import {
  buySubscription,
  getSubscription,
} from "../../Services/Service";
import { onFailure } from "../../util/helper";
interface ISubscription {}

const plansBox = {
  flexShrink: 0,
  width: "186px",
};

const rowStyle = {
  borderBottom: "1px solid #dedede",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const plansBoxHeader = {
  textAlign: "center",
  height: "131px",
  padding: "23px 0",
};

const plansBoxBody = {};

const plansBoxFooter = {
  height: "150px",
  padding: "35px 18px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const parseOffering = (data: any) => {
  try {
    const cleanedString = data.offering.replace(/\\/g, "");
    const offeringArray = JSON.parse(cleanedString);
    return (
      <>
        {offeringArray.map((item: any) => (
          <Box key={item.id} sx={rowStyle}>
            {item.checked && (
              <Tick color={data?.isActive ? "#00C385" : "#1D183E"} />
            )}
          </Box>
        ))}
      </>
    );
  } catch (error) {
    console.error("Error parsing offering:", error);
    return [];
  }
};

const Subscription: FC<ISubscription> = () => {
  const [data, setData] = useState<any>();
  const [subscription,setSubscription] = useState<any>({})
  useEffect(() => {
    getSubscription(onSuccess, onFailure);
  }, []);
  const onSuccess = (res: any) => {
    if (res.success) {
      setData(res.data);
      const subs = res.data.subscriptions.filter((item:any) => item.isActive);
      setSubscription(subs)
    }
  };
  const _window = window as any;
  const onSubcsriptionSuccess = (res: any) => {
    if (res.success) {
      res.data.handler = onSuccessPayment;
      const rzp = new _window.Razorpay(res?.data);
      rzp.open();
    }
  };

  const onSuccessPayment = useCallback(() => {
    getSubscription(onSuccess, onFailure);
  }, []);

  const onSubmit = (id: any) => {
    const payload: any = new FormData();
    payload.append("id", id);
    buySubscription(payload, onSubcsriptionSuccess, onFailure);
  };

  return (
    <PrimaryLayout>
      <Box textAlign="center">
        <Text sx={{ mb: 1 }} variant="h1">
          If you are here it means you are serious about the opportiunities,
        </Text>

        <Text sx={{ mb: 2 }}>
          Pick from the below options to access great features and verify your
          profile to get more attention from the Recruiters
        </Text>

        <Text variant="caption">
          Currently your{" "}
          <Text sx={{ fontWeight: 600 }} variant="caption" component="strong">
            {subscription[0]?.name}
          </Text>{" "}
          plan is activated and you can upgrade to other plan at any time.
        </Text>
      </Box>

      <Box
        mt={5}
        display="flex"
        sx={{
          background: "#fdfdfd",
          boxShadow: "0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
          overflow: "auto",
          width: {
            md: "calc(100% - 30px)",
          },
          px: {
            md: 5,
          },
        }}
      >
        <Box
          sx={{
            ...plansBox,
            background: "#fff",
            width: { xs: "186px", md: "auto" },
            flexGrow: { md: 1 },
            position: "sticky",
            left: 0,
            zIndex: 9,
          }}
        >
          <Box sx={plansBoxHeader}></Box>
          <Box sx={plansBoxBody}>
            {data?.offerings?.map((item: any) => (
              <Box key={item.id} sx={{ ...rowStyle, px: { xs: 2, md: 0 } }}>
                <Text
                  variant="subtitle2"
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    color: item.color,
                    fontSize: {
                      xs: "0.75rem",
                      md: "0.875rem",
                    },
                  }}
                >
                  {item.name}
                </Text>
              </Box>
            ))}
          </Box>
          <Box sx={plansBoxFooter}></Box>
        </Box>

        {data?.subscriptions?.map((ele: any) => (
          <Box
            sx={{
              ...plansBox,
              backgroundColor: ele?.isActive
                ? "#1d183e"
                : ele.name === "Silver" || ele.name === "Platinum"
                ? "rgba(217, 217, 217, 0.2)"
                : "#fff",
            }}
          >
            <Box sx={plansBoxHeader}>
              <Text
                sx={{
                  color: ele?.isActive ? "#fff" : "#1d183e",
                }}
                variant="h1"
              >
                {ele.name}
              </Text>
              <Text variant="h2" sx={{ mt: 0.5, color: "#00C385" }}>
                {ele.selling_price ? `${ele.selling_price}/-` : "Free"}
              </Text>
              {ele.price && (
                <Text
                  variant="subtitle1"
                  sx={{
                    mt: 0.5,
                    textDecoration: "line-through",
                    color: "#96989b",
                  }}
                >
                  {ele.price}
                </Text>
              )}
            </Box>
            <Box sx={plansBoxBody}>{parseOffering(ele)}</Box>
            <Box sx={plansBoxFooter}>
              {ele?.isActive ? (
                <Text
                  variant="h2"
                  sx={{ color: "#00C385", textAlign: "center" }}
                >
                  Currently Active
                </Text>
              ) : ele?.name !== "Basic" ? (
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => onSubmit(ele.id)}
                >
                  Updgrade
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </PrimaryLayout>
  );
};

export default Subscription;
