import React from "react";
import { IData } from "../Preview";

interface ResumeData {
  data: IData;
}

const Resume2: React.FC<ResumeData> = ({ data }) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    border={0}
    style={{
      maxWidth: 850,
      width: "100%",
      border: "none",
      margin: "0 auto",
      borderCollapse: "collapse",
      backgroundColor: "#fff",
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            backgroundColor: "#e3d5c8",
            width: "30%",
            padding: 30,
            verticalAlign: "top",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                width: 180,
                height: 180,
                display: "inline-block",
                borderRadius: 180,
                border: "2px solid #343537",
              }}
              src={data?.user?.image}
              alt=""
            />
          </div>
          <div style={{ marginTop: 50 }}>
            <h3
              style={{
                lineHeight: "normal",
                margin: 0,
                fontSize: 20,
                textTransform: "uppercase",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                fontWeight: 400,
                color: "#2f3847",
              }}
            >
              Contact
            </h3>
            <div style={{ marginTop: 16 }}>
              <div>
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Phone
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 0",
                  }}
                >
                  {data?.resume?.mobile}
                </p>
              </div>
              <div style={{ marginTop: 16 }}>
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Email
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 0",
                  }}
                >
                  {data?.resume?.emailId}
                </p>
              </div>
              <div style={{ marginTop: 16 }}>
                <p
                  style={{
                    fontWeight: 400,
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 15,
                    margin: 0,
                  }}
                >
                  Address
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 12,
                    margin: "6px 0 0",
                    lineHeight: "170%",
                  }}
                >
                  {data?.resume?.address}, {data?.resume?.city},{" "}
                  {data?.resume?.pincode}
                </p>
              </div>
            </div>
          </div>
          {data.resume?.educations?.length ? (
            <div
              style={{
                marginTop: 25,
                paddingTop: 25,
                borderTop: "1px solid #2f3847",
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 20,
                  textTransform: "uppercase",
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontWeight: 400,
                  color: "#2f3847",
                }}
              >
                Education
              </h3>
              <div style={{ marginTop: 16 }}>
                {data.resume?.educations?.map(
                  (education: any, index: number) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 16 }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontFamily:
                            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                          color: "#2f3847",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.educationType.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontFamily:
                            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                          color: "#2f3847",
                          fontSize: 15,
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {education.schoolName.toLowerCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#2f3847",
                          fontSize: 12,
                          margin: "6px 0 12px",
                        }}
                      >
                        <span>Percentage:</span> {education.gradingValue}{" "}
                        {education.gradingSystems === "Percentage"
                          ? "%"
                          : "CGPA"}{" "}
                        | <span>Year:</span> {education.passedYear}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {data.resume?.skills?.length ? (
            <div
              style={{
                marginTop: 25,
                paddingTop: 25,
                borderTop: "1px solid #2f3847",
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 20,
                  textTransform: "uppercase",
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontWeight: 400,
                  color: "#2f3847",
                }}
              >
                Expertise
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.skills?.map((skill, index) => (
                    <li key={index}>{skill.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.hobbies?.length ? (
            <div
              style={{
                marginTop: 25,
                paddingTop: 25,
                borderTop: "1px solid #2f3847",
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 20,
                  textTransform: "uppercase",
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontWeight: 400,
                  color: "#2f3847",
                }}
              >
                Hobbies
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 12px",
                    listStyleType: "disc",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.hobbies?.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {data.resume?.languageKnown?.length ? (
            <div
              style={{
                marginTop: 25,
                paddingTop: 25,
                borderTop: "1px solid #2f3847",
              }}
            >
              <h3
                style={{
                  lineHeight: "normal",
                  margin: 0,
                  fontSize: 20,
                  textTransform: "uppercase",
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontWeight: 400,
                  color: "#2f3847",
                }}
              >
                Language
              </h3>
              <div style={{ marginTop: 16 }}>
                <ul
                  style={{
                    padding: "0 8px 0 9",
                    listStyleType: "none",
                    lineHeight: "260%",
                    margin: 0,
                    fontWeight: "normal",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                    color: "#2f3847",
                    fontSize: 13,
                  }}
                >
                  {data.resume?.languageKnown.map((language, index) => (
                    <li key={index}>{language}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </td>
        <td
          style={{
            width: "70%" /* padding: 30, */,
            verticalAlign: "top",
            borderRight: "1px solid #f1f1f1",
            borderBottom: "1px solid #f1f1f1",
          }}
        >
          <div style={{ padding: "30px 30px", backgroundColor: "#343537" }}>
            <h1
              style={{
                lineHeight: "normal",
                margin: 0,
                fontSize: 46,
                fontFamily: "Georgia, 'Times New Roman', Times, serif",
                fontWeight: 600,
                color: "#e3d5c8",
              }}
            >
              {data.resume?.firstName} {data.resume?.lastName}
            </h1>
            <p
              style={{
                margin: "10px 0 0",
                fontSize: 24,
                color: "#e3d5c8",
                fontFamily:
                  "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
              }}
            >
              {data?.resume?.job_type === "0" ? "Fresher" : "Experience"}
            </p>
          </div>
          <div style={{ padding: 30 }}>
            {data.resume?.objective?.length ? (
              <div>
                <h3
                  style={{
                    lineHeight: "normal",
                    margin: 0,
                    paddingBottom: 8,
                    fontSize: 20,
                    borderBottom: "2px solid #e8e8e8",
                    textTransform: "uppercase",
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    fontWeight: 400,
                    color: "#2f3847",
                  }}
                >
                  About Me
                </h3>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontSize: 14,
                    lineHeight: "170%",
                    color: "#707070",
                    fontFamily:
                      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                  }}
                >
                  {data.resume?.objective}
                </p>
              </div>
            ) : null}
            {data.resume?.experiences?.length ? (
              <div style={{ marginTop: 40 }}>
                <h3
                  style={{
                    lineHeight: "normal",
                    margin: 0,
                    paddingBottom: 8,
                    fontSize: 20,
                    borderBottom: "2px solid #e8e8e8",
                    textTransform: "uppercase",
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    fontWeight: 400,
                    color: "#2f3847",
                  }}
                >
                  Work Experience
                </h3>
                <div style={{ marginTop: 16 }}>
                  {data.resume?.experiences?.map((experience, index) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontFamily:
                            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                          color: "#2f3847",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {experience.position}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "6px 0 12px",
                        }}
                      >
                        {index === 0 ? (
                          <>
                            {`${experience.companyName} | ${experience.startDate} - `}
                            <strong style={{ fontWeight: "bold" }}>
                              {experience.endDate !== null
                                ? experience.endDate
                                : "Present"}
                            </strong>
                          </>
                        ) : (
                          <>{`${experience.companyName} | ${
                            experience.startDate
                          } - ${
                            experience.endDate !== null
                              ? experience.endDate
                              : "Present"
                          }`}</>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {data.resume?.projects?.length ? (
              <div style={{ marginTop: 40 }}>
                <h3
                  style={{
                    lineHeight: "normal",
                    margin: 0,
                    paddingBottom: 8,
                    fontSize: 20,
                    borderBottom: "2px solid #e8e8e8",
                    textTransform: "uppercase",
                    fontFamily:
                      "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    fontWeight: 400,
                    color: "#2f3847",
                  }}
                >
                  Projects
                </h3>
                <div style={{ marginTop: 16 }}>
                  {data.resume?.projects?.map((project, index) => (
                    <div
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : 30 }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontFamily:
                            "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                          color: "#2f3847",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {project?.projectName}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "8px 0 12px",
                        }}
                      >
                        <span style={{ fontWeight: 500, color: "#2f3847" }}>
                          Technology:
                        </span>
                        {project?.technology} |
                        <span style={{ fontWeight: 500, color: "#2f3847" }}>
                          Team Size:
                        </span>
                        {project?.teamSize} |
                        <span style={{ fontWeight: 500, color: "#2f3847" }}>
                          Year:
                        </span>
                        {project?.year}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontFamily:
                            "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                          color: "#707070",
                          fontSize: 14,
                          margin: "6px 0 0",
                          lineHeight: "170%",
                        }}
                      >
                        {project?.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

export default Resume2;
