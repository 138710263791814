import handleToast, { success } from "../util/Toast";
import { BASE_URL } from "../constant";
import { client } from "./client";

export const login = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/login`, data)
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      onSuccess(data);
    })
    .catch((err) => {
      console.log(err);
      onFailure(err);
    });
};
export const register = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/auth`, data)
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const socialLogin = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/social-login`, data)
    .then(({ data }: any) => {
      localStorage.setItem("token", data.token);
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};
export const saveInterviewDetail = (data: any) => {
  client
    .post(`${BASE_URL}/save-interview-result`, data)
    .then(({ data }) => {
      // onSuccess(data);
    })
    .catch((error) => {
      // onFailure(error)
    });
};

export const forgotPassword = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/password/email`, data)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
};
export const changePassword = (data: any, onSuccess: any, onFailue: any) => {
  client
    .post(`${BASE_URL}/password/reset`, data)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error);
    });
};
export const getEmailFromToken = (
  token: any,
  onSuccess: any,
  onFailue: any
) => {
  client
    .get(`${BASE_URL}/get-email?token=${token}`)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error);
    });
};
export const UserToken = () => localStorage.getItem("token");

export const config = () => ({
  headers: {
    Authorization: `Bearer ${UserToken()}`,
  },
});

export const sendEmailOtp = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/send-email-otp`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
      handleToast(data.message, success);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};
export const verifyEmailOtp = (onSuccess: any, params: any = {}) => {
  client
    .post(`${BASE_URL}/verify-email-otp`, params, config())
    .then(({ data }) => {
      onSuccess(data);
      handleToast(data.message, success);
    })
    .catch((err) => {
      handleToast(err.message);
    });
};
export const updatePersonalInfo = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .post(`${BASE_URL}/update-profile`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const addPhone = (onSuccess: any, onFailure: any, params: any = {}) => {
  client
    .post(`${BASE_URL}/add-phone`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const sendMobileOtp = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/send-mobile-otp`, {
      ...config(),
    })
    .then(({ data }) => {
      handleToast(data.message, success);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};
export const resendMobileOtp = () =>
  // onSuccess: any,
  // onFailure: any,
  // params: any = {}
  {
    client
      .get(`${BASE_URL}/resend-mobile-otp`, {
        ...config(),
      })
      .then(({ data }) => {
        handleToast(data.message, success);
        // onSuccess(data);
      })
      .catch((err) => {
        // onFailure(err);
        handleToast(err.message);
      });
  };
export const resendEmailOtp = () =>
  // onSuccess: any,
  // onFailure: any,
  // params: any = {}
  {
    client
      .get(`${BASE_URL}/resend-email-otp`, {
        ...config(),
      })
      .then(({ data }) => {
        handleToast(data.message, success);
        // onSuccess(data);
      })
      .catch((err) => {
        // onFailure(err);
        handleToast(err.message);
      });
  };
export const verifyMobileOtp = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .post(`${BASE_URL}/verify-mobile-otp`, params, config())
    .then(({ data }) => {
      onSuccess(data);
      handleToast(data.message.success);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};

export const getProfile = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/profile`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getUserMe = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/me`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
      // handleToast(error.message);
    });
};

export const createUser = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/user`, data, config())
    .then(({ data }) => {
      // localStorage.setItem("token", data.data.token);
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.data.message);
    });
};

export const saveJob = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/job/save-job?id=${data.id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const jobApply = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/job/apply-job?id=${data.id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchMockInterviews = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/interview`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
};

export const fetchRecommendedJobs = (page: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job?page=${page}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchAppliedJobs = (page: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job/applied-jobs?page=${page}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchSavedJobs = (page: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job/save-for-later?page=${page}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const attemptMockInterview = (slug: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/interview/attempt?slug=${slug}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const getJobDetail = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job/detail?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};
export const getSubscription = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/subscription`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const buySubscription = (
  params: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/subscription`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getKyc = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/kyc`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const sendKycOTP = (params: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/kyc/send-otp`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const verifyKycOTP = (params: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/kyc/verify-otp`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const fetchEvents = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/notification`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const referFriend = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/refer`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.data.message);
    });
};

export const fetchReferralInfo = (additionalQuery: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/refer${additionalQuery}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchSkills = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/profile/skill`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchHobbies = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/profile/hobbies`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const searchJob = (body: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job/search`, { ...config(), params: body })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const jobAdvanceSearch = (body: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/job/advance-search?keyword=${body.keyword}`, {
      ...config(),
      params: body.data,
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveAboutMe = (params: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/profile/about`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveSkills = (params: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/profile/skill`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveHobbies = (params: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/profile/hobbies`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveJobInfo = (params: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/profile/job`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const fetchActions = (data: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/option`, { ...config(), params: data })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message);
    });
};
export const getProject = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/project?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message);
    });
};

export const savePersonalDetails = (
  data: any,
  onSuccess: any,
  onFailue: any
) => {
  client
    .post(`${BASE_URL}/profile`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error);
    });
};

export const deleteResume = (onSuccess: any) => {
  client
    .delete(`${BASE_URL}/user-resume`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveResume = (data: any, onSuccess: any) => {
  client
    .patch(`${BASE_URL}/user-resume`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveProfileImage = (data: any, onSuccess: any) => {
  client
    .patch(`${BASE_URL}/profile/profile-image`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveCoverImage = (data: any, onSuccess: any) => {
  client
    .patch(`${BASE_URL}/profile/cover-image`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const deleteProject = (id: any, onSuccess: any) => {
  client
    .delete(`${BASE_URL}/project?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};

export const saveExperience = (data: any, onFailue: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/experience`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error.message);
    });
};
export const saveProject = (data: any, onSuccess: any, onFailue: any) => {
  client
    .post(`${BASE_URL}/project`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error);
      handleToast(error.message);
    });
};

export const fetchResumeDetails = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/resume/preview`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message);
    });
};

export const downloadResume = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/resume/download/Resume${id}`, {
      ...config(),
      responseType: "arraybuffer",
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message || error.statusText);
    });
};

export const checkSaveEducation = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/education/check-user-education/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message || error.statusText);
    });
};
export const getEducation = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/education?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message || error.statusText);
    });
};
export const getExperience = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/experience?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      handleToast(error.message || error.statusText);
    });
};

export const deleteExperience = (id: any, onSuccess: any) => {
  client
    .delete(`${BASE_URL}/experience?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};
export const deleteEducation = (id: any, onSuccess: any) => {
  client
    .delete(`${BASE_URL}/education?id=${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      handleToast(error.message);
    });
};
export const saveEducation = (data: any, onSuccess: any, onFailue: any) => {
  client
    .post(`${BASE_URL}/education`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailue(error);
      handleToast(error.message);
    });
};

export const checkSubscription = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/subscription/user-ubscription`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      console.log(error);
    });
};
export const interviewReport = (slug: any, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/interview/report?slug=${slug}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error: any) => {
      onFailure(error);
    });
};
