import React, { ReactNode, useContext} from "react";
import { Box, Container, Grid } from "@mui/material";
import { Back, Header } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../Icons";
import { DropDown } from "../../Pages/Profile/Dropdown";
import { CONTAINER_MAX_WIDTH } from "../../constant";
import { Sidebar } from "../Sidebar";
import ReferToFriends from "../../Shared/ReferToFriends";
import Wallet from "../../Shared/Wallet";
import { AppContext } from "../../App";

interface ISecondaryLayout {
  children: ReactNode;
  back?: boolean;
  rightPanel?: ReactNode;
  leftPanel?: ReactNode;
  currentActive: number;
}

export const SecondaryLayout: React.FC<ISecondaryLayout> = ({
  children,
  back = false,
  rightPanel,
  currentActive,
  leftPanel,
}) => {
  const navigate = useNavigate();
  const {state} = useContext<any>(AppContext);

  return (
    <>
      <Header
        maxWidth={CONTAINER_MAX_WIDTH}
        logo={<Logo style={{ cursor: 'pointer' }} />}
      >
        <DropDown />
      </Header>
      <Box py={2.5}>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          {back && <Box mb={2.5}><Back onClick={() => navigate(-1)} /></Box>}
          <Grid alignItems='flex-start' columnSpacing={2} container>
            <Grid
              item
              position={leftPanel ? 'sticky' : 'static'}
              top={leftPanel ? 87 : 0}
              md={3}
            >
              <Box
                display='flex'
                flexDirection='column'
                rowGap={1.5}
              >
                {leftPanel ? leftPanel : (
                  <>
                    <Sidebar currentActive={currentActive} />

                    {/* {hideAdditionalInfo && (
                      <Card disableHeader>
                        <Box
                          display='flex'
                          alignItems='center'
                          columnGap={3}
                        >
                          <Box
                            borderRadius={100}
                            width={44}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height={44}
                            sx={{
                              background: '#E0FAF1'
                            }}
                          >
                            <VideoIcon />
                          </Box>
                          <Box>
                            <Text>
                              Videos
                            </Text>
                            <Text variant="h2">
                              255
                            </Text>
                          </Box>
                        </Box>




                        <Box
                          mt={3.5}
                          display='flex'
                          alignItems='center'
                          columnGap={3}
                        >
                          <Box
                            borderRadius={100}
                            width={44}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height={44}
                            sx={{
                              background: '#E0FAF1'
                            }}
                          >
                            <LikeIcon />
                          </Box>
                          <Box>
                            <Text>
                              Like
                            </Text>
                            <Text variant="h2">
                              22,00,872
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          mt={3.5}
                          display='flex'
                          alignItems='center'
                          columnGap={3}
                        >
                          <Box
                            borderRadius={100}
                            width={44}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height={44}
                            sx={{
                              background: '#E0FAF1'
                            }}
                          >
                            <RatingIcon />
                          </Box>
                          <Box>
                            <Text>
                              Ratings
                            </Text>
                            <Text variant="h2">
                              4.74
                            </Text>
                          </Box>
                        </Box>
                      </Card>
                    )} */}

                    <Wallet walletAmount={state?.user?.wallet?.balance ?? 0} />

                    <ReferToFriends />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={rightPanel ? 6 : 9}>{children}</Grid>
            {rightPanel && (
              <Grid
                item md={3}
                position='sticky' top={87}
              >{rightPanel}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  )
}