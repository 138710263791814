import { Button, DefaultInfoCard, Chips, Card, Text, useSmartPhone } from "@lipihipi/ui-components";
import { SkillsIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box } from "@mui/material";
import { SKILLS } from "../../../RouteConstant";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


const SkillsDetails = ({skills}:any) => {
  const navigate = useNavigate();
  const isSmartPhone = useSmartPhone();

  if (skills === '') {
    return (
    <DefaultInfoCard icon={<SkillsIcon />}>
      <Text>
        This is where your are best at!
      </Text>
      <Text sx={{
        color: '#292929'
      }}>
        <Text sx={{ fontSize: '20px', fontWeight: '400' }} component="span">Add Skills</Text>
      </Text>
      <Button onClick={() => navigate(SKILLS)} color="primary" sx={{ mt: 3, mx: 'auto' }} variant="outlined">
        Add Now
      </Button>
    </DefaultInfoCard>
  )
    }
    return (
      <Card
        action={{
          icon: null,
          text: isSmartPhone ? 'Add' : "Add Skill",
        }}
        onClick={() => navigate(SKILLS)}
        heading="Skill Set"
      >
        <Box
          display='flex'
          flexWrap='wrap'
          gap={1.5}
        >
          {skills?.split(', ')?.map((el:any) => (
            <Chips label={el} />
          ))}
        </Box>
      </Card>
    )

};

export default SkillsDetails;